.ads-countdown.mobile{
  width:90px;
  height: 20px;
  font-size: 9px;
  line-height: 10px;
  left:15px;
  .ads-countdown-ring {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
  .ads-countdown-text{margin: auto auto auto 25px;}

}




.ads-countdown{
  width:184px;
  height: 37px;
  position: absolute;
  top:11.23%;
  left: 12%;
  display: flex;
  z-index:105;
  color: #FFFFFF;
  background-color: transparent;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21.33px;
  line-height: 25px;

  /* identical to box height */
  .ads-countdown-ring{
    vertical-align: middle;
    width: 35px;
    height: 35px;
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    .ads-countdown-remaining{
      position: static;
      display: flex;
      margin: auto;

    }
    .ads-countdown-progress-ring{

      position: absolute;

    }
  }
  .ads-countdown-text{margin: auto 53px;}

}




.ads-countdown.tablet{
  width:fit-content;
  height:fit-content;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  left:25px;
  display: flex;
  align-items: center;
  .ads-countdown-ring {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ads-countdown-text{margin: auto auto auto 40px;}
}
