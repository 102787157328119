
.error-wrapper{
  margin:auto;
  width:100%;
  height: 100%;
  top: 0;
  left:0;
  bottom: 0;
  position: absolute;
  z-index:100;
  padding: 15px;
  display: flex;
  background: #131E2D;

  .logo{
    position: absolute;
    top:15px;
    right:15px;
    width:38.77px;
    height: 15px;
  }
  .error-detail-wrapper{
    width:100%;
    margin:auto;
  }
  .error-image{
    margin:auto;
    text-align: center;
    img{
      width:100%;
      height: 100%;
      max-width:345px;
      max-height: 248px;
    }
  }
  .error-explanation{
    margin:20px auto auto auto;
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 21.33px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    //span{
    //  font-weight: bold;
    //}
  }
  .error-button-group{
    display: flex;
    margin:20px auto auto auto;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    p{
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.025em;

    }
    .multiple-button{display: flex}
    button {
      margin: auto 7.5px;;

      width:auto;
      height: 30px;
      background-color:#334B6D;
      border:none;
      box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 15px;
      flex: none;
      order: 0;
      align-self: flex-start;
      flex-grow: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.025em;
      color:#FFFFFF;
    }

    div{
      div{margin: auto;
        justify-content: center;
        align-items: center;
        display: flex;}}
  }
  .error-code{
    position: absolute;
    bottom:30px;
    left:15px;
    p{
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      margin-bottom:3px;
      line-height: 12px;
      letter-spacing: 0.025em;

      color: rgba(255,255,255,0.5);
      span{
        strong{
        color:rgba(255,255,255,0.8);
        }
      }

    }

  }
  .error-code-description{
    margin-bottom:20px;
  }

  .error-code-description.maintenance{
    font-weight: 700;
  }

}


.error-wrapper.desktop{
  width:100%;
  height: 100%;
  top: 0;
  left:0;
  bottom: 0;
  position: absolute;
  display: flex;
  z-index:100;
  background: #0a2244;
  .logo{
    position: absolute;
    top:25px;
    left:12%;
    width:126px;
    height: 40px;
  }
  .error-detail-wrapper{
    width:540px;
    margin:auto;
  }

  .error-code-description{
    margin-bottom:30px;
  }
  .error-image{
    img{
      width:350px;
      height: 292px;
      max-height: 292px;
      max-width: 350px;
    }
  }
  .error-explanation{
    margin:30px auto auto auto;
    font-size: 28.4px;
    line-height: 33px;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    //span{
    //  font-weight: bold;
    //}
  }
  .error-button-group{
    margin:30px auto auto auto;
    p{
      font-style: normal;
      font-weight: 300;
      font-size: 21.33px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.025em;

    }
    button {
      width: auto;
      height: 40px;
      padding: 11px 20px;
      margin: auto;
      }
  }
  .error-code{
    position: absolute;
    bottom:50px;
    left:12%;
    line-height: 18px;
    letter-spacing: 0.025em;
    p{
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      margin-bottom:3px;
      letter-spacing: 0.025em;

      color: rgba(255,255,255,0.5);
      span{
        strong{
        color:rgba(255,255,255,0.8);
        }
      }
    }
  }


}


@media only screen and (max-width: 992px) and (orientation:landscape){
  /* CSS apply on width between 501px and 600px */
  .error-wrapper{
    .error-image{
      margin: 15px auto auto auto;
      img{
        width: 100%;
        height: 100%;
        max-width: 193.5px;
        max-height: 139.5px;
      }
    }
    .error-code{
      bottom:15px;
    }
  }
}
