
.channel-bar {
  position: absolute;
  bottom: 50px;
  width: 76vw;
  margin: 0 12vw;
  height: 70px;

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev::after {
    display: none;
  }


  .channel-bar-wrapper {
    display: flex;
    width: 82vw;
    height: 70px;
    margin: auto -3vw;
  }

    .channel-bar-blur-left, .channel-bar-blur-right{
      position: absolute;
      width: 5px;
      height: 70px;
      top: 0;
      background: rgba(51, 75, 109, 0.8);
      z-index: 5;

    }
    .channel-bar-blur-left{
      box-shadow: 0 30px 100px 120px rgb(51 75 109 / 78%);
      left: 0;
    }

    .channel-bar-blur-right{
      right:0;
      box-shadow:  0 -30px 100px 120px rgb(51 75 109 / 78%);
    }

    .channels {
      position: relative;
      margin: auto;
      display: flex;
      width: 76vw;
      height: 70px;
      border-radius: 100px;
      z-index: 99;
      cursor: pointer;
      background: rgba(51, 75, 109, 0.75);


      .channelWrapper {
        margin: 0;
        padding: 0;
        border-radius: 100px;
        background: rgba(51, 75, 109, 0.75);

      }

      .channel-slider{
        width: 70px;
        height: 100%;


      .channel-button {
        justify-content: center;
        width: 70px;
        height:70px;
        border: none;
        border-radius: 100%;
        background-color: transparent;
        transition: background-color 100ms ease-in-out;
        text-align: center;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin:auto;
        padding: 0;

        .channel-icon {
          width: 70px;
          height: 70px;
          background-color: transparent;
          z-index: -10;
          margin: auto;
          border-radius: 100%;

          pointer-events: none;
        }

        .channel-icon.focus {
          background-color: #FFFFFF;
          z-index: 99;
        }


      }

      .channel-button:hover {
        background-color: #8B9CBB;
        transition: background-color 100ms ease-in-out;
        z-index: 99;
      }

      }


    }


  }

#next-arrow::after,  #prev-arrow::after  {
  //display: none;
  opacity: 0;
}


#prev-arrow{

  margin: -22.5px 0 auto -42px;
  left:25px;
  animation-name: unset;
  //opacity: 0;
  transition:opacity 300ms ease-in-out, left 200ms ease-in-out;

  img{

    width: 30px;
    height: 30px;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
}

#next-arrow{
  margin: -22.5px -42px auto auto;
  right:25px;
  animation-name: unset;
  //opacity: 0;
  transition: opacity 300ms ease-in-out, right 200ms ease-in-out;

  img{

    width: 30px;
    height: 30px;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

}


#prev-arrow.active, #next-arrow.active{
  //opacity:1;
  transition: opacity 200ms ease-in-out;

}
#prev-arrow.active{
  left:10px;
  transition: left 200ms ease-in-out,opacity 200ms ease-in-out;
  img{
    opacity: 0.75;
    transition: opacity 200ms ease-in-out;
  }
}


#next-arrow.active{
  right:10px;
  transition:right 200ms ease-in-out,opacity 200ms  ease-in-out;
  img{
    opacity: 0.75;
    transition: opacity 200ms ease-in-out,
  }
}




//
//@keyframes slide-next {
//  0% {margin-right: -45px;}
//
//  25%{margin-right: -46px;}
//
//  50% {margin-right: -46px;}
//
//  75%{margin-right: -47px;}
//
//  100% {margin-right: -48px;}
//}
//
//@keyframes slide-next-back {
//  100% {margin-right: -35px;}
//
//  75%{margin-right: -36px;}
//
//  50% {margin-right: -37.5px;}
//
//  25%{margin-right: -40px;}
//
//  0% {margin-right: -42.5px;}
//}
//
//
//
//
//@keyframes slide-prev {
//  0% {margin-left: -38px;}
//
//  25%{margin-left: -39px;}
//
//  50% {margin-left: -40px;}
//
//  75%{margin-left: -41px;}
//
//  100% {margin-left: -42px;}
//}
//
//@keyframes slide-prev-back {
//  100% {margin-left: -35px;}
//
//  75%{margin-left: -36px;}
//
//  50% {margin-left: -37.5px;}
//
//  25%{margin-left: -38px;}
//
//  0% {margin-left: -38px;}
//}
