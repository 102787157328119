
.on-boarding-wrapper.mobile.landscape{
  padding:0;
  align-items: center;
  .on-boarding-group{
    margin:auto;
    align-items: center;
    justify-content: center;
    .on-boarding-slides{
      width: 100%;
      height: auto;
      margin: auto;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      .on-boarding-image, .on-boarding-explanation{
        width:345px;
        min-width:35%;
        height: 217px;
        margin:0 10px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;

      }
      .on-boarding-explanation.ready{
        justify-content: unset;
      }
    }
  }

}

.on-boarding-wrapper.tablet{
  padding: 0;
  align-items: center;
  .on-boarding-group{
    width:auto;
    height: auto;
    margin:auto;
    .on-boarding-slides{
      width:500px;
      height: fit-content;
      .on-boarding-image{
        margin-bottom:20px;
      }
      .on-boarding-explanation{

          h3{
            font-weight: 700;
            font-size: 28.43px;
            line-height: 32.67px;
            margin-bottom:15px;
          }
        p{
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          margin-bottom:25px;
        }
      }
    }
    //.on-boarding-back{
    //  left:45px;
    //  bottom:45px;
    //}
  }
}

.on-boarding-wrapper{
  position: absolute;
  background-color:#0a2244;
  width: 100%;
  height: 100%;
  padding: 80px 15px 0 ;
  z-index: 1000;
  display: flex;
  justify-content: center;




  .logo{
    position: absolute;
    width:38.77px;
    height: 15px;
    top:15px;
    right:15px;
  }

  .on-boarding-group {
    display: flex;
    width: 100vw;
    height: 100vh;
    /* position: relative; */
    top: 0;
    left: 0;
    bottom: 0;
    padding:0;
    justify-content: center;

  }


  .on-boarding-back{
    position: absolute;
    top: auto;
    bottom: 30px;
    left:15px;
    height: 25px;
    background: rgba(80, 80, 80, 0.5);
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

  }

  button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;

    position: static;
    width: auto;
    height: 30px;
    left: 0px;
    top: 10px;
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.5px;

    color: #FFFFFF;
    border:none;

    background: #334B6D;
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
    border-radius: 100px;

  }

  button:disabled{
    background: rgba(51, 75, 109, 0.75);
    opacity: 0.5;
  }

  .on-boarding-slides{
    width:100%;
    height: 25%;
    .on-boarding-image{
      height: 100%;
      img{width:100%; height: 100%}
    }
    .on-boarding-buttons{
      display: flex;
      .lang-button{
        margin-right: 15px;
      }
    }
    .on-boarding-explanation{

      h3{
        font-style: normal;
        font-weight: 500;
        font-size: 21.33px;
        line-height: 25px;
        margin: 10px 0;
      }
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        white-space: pre-line;

      }


      .on-boarding-back{
        position: relative;
        top:auto;
        left:0;
        margin-top:60px;
        height: 30px;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;

      }
    }

  }
}

.on-boarding-wrapper.desktop{
  padding:0;
  justify-content: center;
  align-items: center;
  width:100vw;
  height: 100vh;

  .logo{
    position: absolute;
    width:126px;
    height: 40px;
    top:25px;
    left:12%;
  }

  button{
    height: 40px;

  }

  .on-boarding-group{

    display: flex;
    width: 100vw;
    height: 100vh;
    position: relative;
    top:0;
    left: 0;
    padding: 0;
    justify-content: center;
  }

.on-boarding-slides{
  display:flex;
  width:100% ;
  height: 100%;
  //margin:36.32% 11.73%;
  justify-content: center;
  align-items: center;
  .on-boarding-image{
    width:540px;
    height: 352.8px;
    order:2;
  }
  .on-boarding-explanation{
    width: 535px;
    height: 352.8px;
    padding-top: 6%;
    position: relative;
    margin: auto 30px auto;
    .on-boarding-buttons{
      display: flex;
      .lang-button{
        margin-right: 15px;
      }
    }
    .on-boarding-content{
      h3{
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
      }
      p{
        //font-weight: 300;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;
      }

      margin-bottom: 20px;

    }

  }
  .on-boarding-explanation.ready{
    padding-top:3%;
  }

}
  .on-boarding-back{
    position: relative;
    top:auto;
    left:0;
    margin-top:60px;
    height: 30px;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;

  }



}


@media screen and (orientation: landscape) {
  .on-boarding-wrapper.mobile{
    padding:0;
    align-items: center;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    .on-boarding-group{
      margin:auto;
      align-items: center;
      justify-content: center;
      .on-boarding-slides{
        width: 100%;
        height: auto;
        margin: auto;
        padding: 0 15px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        .on-boarding-image, .on-boarding-explanation{
          width:345px;
          min-width:35%;
          height: 217px;
          margin:0 10px;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;

        }
        .on-boarding-explanation.ready{
          justify-content: unset;
        }
      }
    }

  }

}

@media screen and (orientation: portrait){
  .on-boarding-wrapper{
    .on-boarding-back{
      position: absolute!important;
      top: auto;
      bottom: 30px!important;
      left:15px!important;
      height: 25px!important;
      background: rgba(80, 80, 80, 0.5);
      font-family: Ubuntu;
      font-style: normal;
      font-weight: 700!important;
      font-size: 12px!important;
      line-height: 14px!important;

    }
  }
  .on-boarding-wrapper.tablet{
    .on-boarding-slides{
      .on-boarding-explanation{
        .on-boarding-back {
          position: relative!important;
          top: auto!important;
          left: 0!important;
          margin-top: 60px!important;
          height: 30px!important;
          font-weight: 700!important;
          font-size: 12px!important;
          line-height: 14px!important;
        }
      }
    }

  }



}






