.rotate-wrapper{
  width:-webkit-fill-available;
  min-width:100vw;
  height: -webkit-fill-available;
  min-height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  bottom:0;
  display: flex;
  background: rgba(3, 12, 26, 0.85);
  backdrop-filter: blur(10px);
  z-index: 100000;

   .warning-wrapper.landscape{
     justify-content: center;
     width: calc(100% - 30px);
     height: 33%;
     margin: auto;
     background: linear-gradient(180deg,#152e52,#05162e);
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    align-items: center;
     display: flex;
     flex-direction: column;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
     padding:30px 15px;
     transform: scale(0.95);
     -webkit-transition:all 250ms ease-in-out;
     transition:  all 250ms ease-in-out;
    .warning-item.landscape{
      margin:auto ;
      display: flex;
      text-align: center;
      justify-content: center;


      h2{
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18.38px;
        text-align: center;
        color: #FFFFFF;
      }

      p{
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #FFFFFF;
      }
      button{
        width: auto;
        height: 30px;
        background: #202C3C;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        border: none;
        padding: 6px 15px;
      }
    }
  }

  .warning-wrapper.landscape.isLoaded, .warning-wrapper.portrait.isLoaded{
    transform:scale(1);
    -webkit-transition:all 250ms ease-in-out;
    transition:  all 250ms ease-in-out;
  }
  .warning-wrapper.portrait{
    //position: absolute;
    justify-content: center;
    width: calc(100% - 90px);
    height: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg,#152e52,#05162e);
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    //padding-top:3%;
    font-weight: bold;
    font-size: 16px;
    padding:30px 15px;
    line-height: 18px;
    transform: scale(0.95);
    -webkit-transition:all 250ms ease-in-out;
    transition:  all 250ms ease-in-out;
    .warning-item.portrait{
      margin:auto ;
      display: flex;
      text-align: center;
      justify-content: center;

      h2{
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18.38px;
        text-align: center;
        color: #FFFFFF;
      }

      p{
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #FFFFFF;
      }
      button{
        //width: 74px;
        height: 30px;
        background: #334B6D;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        border: none;
        padding: 6px 15px;
      }
    }
  }


  //.scale-transition-enter {
  //  transform: scale(0.95);
  //  -webkit-transition:all 250ms ease-in-out;
  //  transition:  all 250ms ease-in-out;
  //}
  //.scale-transition-enter-active {
  //  transform: scale(1);
  //  -webkit-transition:all 250ms ease-in-out;
  //  transition:  all 250ms ease-in-out;
  //}
  //
  //.scale-transition-exit {
  //  transform: scale(1);
  //  //-webkit-transition:all 1000ms ease-in-out;
  //  //transition:  all 1000ms ease-in-out;
  //}
  //.scale-transition-exit-active {
  //  transform: scale(0.95);
  //  -webkit-transition:all 250ms ease-in-out;
  //  transition:  all 250ms ease-in-out;
  //}

}
