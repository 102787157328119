
.loading-wrapper{

  position: absolute;
  background-color:#0a2244;
  width: 100%;
  height: 100%;
  padding: 33px 165px;
  z-index: 1000;
  display: flex;
  justify-content: center;


  .splash-wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    padding: 0;
    justify-content: center;
  }

  .web-spinner{
    position: absolute;
    left:165.39px;
    top:33px;
    opacity: 1;
    #lottie-spinner{
      width:60px;
      height: 60px;

    }
  }
  .web-spinner.hide{
    opacity:0;
    //#lottie-spinner{
    //  opacity:0;
    //
    //}

  }


  .splash-logo{
    display: flex;
    justify-content: center;
    #logo{
      margin:auto;
      width:540px;
      //height: 135px;
    }
  }
  .second-sequence{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Ubuntu", sans-serif;
    width:100%;
    padding:0 12%;
    img{
      width:540px;
      height: auto;
      margin:auto;

    }
    .third-sequence-explaination{
      width:50%;
      height:auto;
      max-width:540px;
      margin:auto 15px auto auto;
      h2{
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
      }

      p{
        height: 54px;
        top: calc(50% - 54px/2 + 24px);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;

        color: #FFFFFF;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 16px 0px;

      }

    }
    .second-sequence-image{
      width:50%;
      max-width: 540px;
      height:326px;
      margin: auto;
      display: flex;
      margin: auto auto auto 15px;
      img{
        width:540px;
        height: auto;
        max-width:100%;

      }
    }

  }
}


.loading-wrapper.mobile.tablet{
  .second-sequence{
    width:100%;
    padding:0 45px;
    margin:auto;
    img{
      width:100%;
      max-width: 500px;
      height: auto;
      max-height: 291px;
    }

    .third-sequence-explaination{
      width:500px;
      height:auto;
      //margin:30px 15px 15px 15px;

      h2{
        font-style: normal;
        font-weight: 500;
        font-size: 28.43px;
        line-height: 32.67px;
        color: #FFFFFF;
      }
      p{
        width: 500px;
        height: 54px;
        left: calc(50% - 500px/2);
        top: calc(50% - 72px/2 + 21.5px);

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;

        color: #FFFFFF;

        //opacity: 0.75;

        /* Inside Auto Layout */

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 10px 0px;
      }

    }
    .second-sequence-image{
      width:500px;
      height: auto;
      margin:0 15px;
      img{
        width:500px;
        height: auto;
        margin:auto;
      }

    }
  }
}

.loading-wrapper.mobile{
  height: -webkit-fill-available;
  .web-spinner{
    left:15px;
    top:10px;
    opacity: 1;
    #lottie-spinner{
      width: 58px;
      height: 58px;
    }
  }
  .web-spinner.hide{
    opacity:0;
  }
  .splash-logo{
    #logo{
      margin:auto;
      width:220px;
      //height: 61.04px;
    }
  }
  .second-sequence{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    img{
      width:100%;
      max-width:345px;
      margin:auto;
      height: auto;


    }
    .third-sequence-explaination{
      width:345px;
      height:107px;
      margin:30px 15px 15px 15px;

      h2{
        font-style: normal;
        font-weight: 500;
        font-size: 21.33px;
        line-height: 25px;
        color: #FFFFFF;
      }
      p{
        width: 345px;
        height: 72px;
        left: calc(50% - 345px/2);
        top: calc(50% - 72px/2 + 17.5px);

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.025em;

        color: #FFFFFF;

        //opacity: 0.75;

        /* Inside Auto Layout */

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 10px 0px;
      }

    }
    .second-sequence-image{
      width:345px;
      height: 201px;
      margin:0 15px 0 15px;
      img{
        width:345px;
        height: 201px;
        margin:auto;
      }

    }

  }
}

.loading-wrapper.mobile.landscape{
  .second-sequence{
    flex-direction: row;
  }
}

.splash-transition{
  justify-content: center;
  margin: auto;
  align-items: center;
}

.splash-transition-enter {
  opacity: 0.001;
}
.splash-transition-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.splash-transition-enter-done{
  opacity: 1;
  //transition: opacity 200ms ease-in;
}


.splash-transition-exit {
  opacity: 1;
  //transition: opacity 200ms ease-in;
}
.splash-transition-exit-active {
  opacity: 0;
  //transition: opacity 200ms;
}
.splash-transition-exit-done {
  opacity: 0;
}


.splash-transition-appear {
  opacity: 0.001;
}
.splash-transition-appear-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}




@media screen and (orientation: landscape) {
      .loading-wrapper.mobile{
        width: 100vw;
        height: 100vh;
        height: -webkit-fill-available;
      }
}
