
@mixin homeInfoFontSize($fontFamily, $fontStyle, $fontWeight:normal, $fontSize: '', $lineHeight: '') {
  font-family: $fontFamily;
  font-style: $fontStyle;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}


.home-info.mobile{
  width:100%;
  bottom:1px;
  padding:0 5%;
  z-index:2;
.home-info-control-bar{
  height: 25px;
  align-items: center;
  .target-wrapper{
    display: flex;
    height:40px;
    justify-content: center;
    align-items: center;
    width: 40px;
    margin-right: 6px;

    .control-button:hover{
      border:none!important;
      background-color:#334B6D!important;
    }
    .control-button:active{
      background-color:#8B9CBB!important;
    }


  }
  .target-wrapper.volume{
    position: relative;
    margin-left: auto;

    .volume-div{
      .control-button:active, .control-button:hover{
        background-color: transparent!important;
      }


    }

    }
  }



  .info-wrapper.scrolling{
    opacity: 0.5;
    transition: opacity 150ms ease-in-out;
    -webkit-transition: opacity 150ms ease-in-out;
  }
 .info-wrapper{
   transition: opacity 150ms ease-in-out;
   -webkit-transition: opacity 150ms ease-in-out;
 }

}


.home-info.mobile.portrait{
  .target-wrapper{
    width:fit-content;
    margin-right:10px;
  }
  button{
    width:48px;
    height: 30px;
  }

  .home-info-content-title{
    margin:12px 0 0 0 ;

    p{
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .home-info-control-bar{
    height: 30px;

    .volume-div{
      height:30px;
      .control-button.volume{
        height: 30px;
      }
    }
  }

  .home-info-progress-bar{
    margin:20px auto 24.5px;
  }

  .home-info-progress-bar.ads{
    margin:5px auto 5.5px;
  }
}

.home-info.desktop{
  width:540px;
  height: 264px;
  bottom:37%;
  margin-left:12%;

  .info-wrapper.scrolling,   .timeline.scrolling{
    opacity: 0.5;
    transition: opacity 150ms ease-in-out;
    -webkit-transition: opacity 150ms ease-in-out;
  }

  .info-wrapper, .timeline{
    transition: opacity 150ms ease-in-out;
    -webkit-transition: opacity 150ms ease-in-out;
  }



  .home-info-channel-title {
    @include homeInfoFontSize('Ubuntu',normal,500,28.433px,33px);
    height: 33px;
  }
  .home-info-content-title{
    @include homeInfoFontSize('Ubuntu',normal,normal,50.52px,58px);
    width: 820px;
    margin-top: 40px;
    p{
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

  }

  .home-info-channel-tags {
    /* Auto Layout */
    padding: 0;
    width: 100%;
    height: 24px;
    left: 0;
    margin-top: 8px;
    margin-bottom: 32px;

    .home-info-channel-tag-item {
      display: inline;
      height:28px;
      left: 0;
      right: 0;
      bottom: 0;
      margin-right: 16px;
      font-style: normal;
      font-family:Ubuntu;
      font-weight: 400;
      font-size:16px;
      line-height:18px;
      color: #ffffff;

      div {
        margin-right:5px;
        width: 8px;
        height: 8px;
        position: relative;
        display: inline-block;
        line-height: 200px;
        bottom: 1px;

        .bullet {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 100%;
        }

        .overlay {
          z-index: 9;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .home-info-progress-bar{
    display: block;


  }
  .timeline{
    @include homeInfoFontSize('Ubuntu',normal,400,21.33px,24.51px);
    margin-bottom: 24px;
  }

  .home-info-control-bar{
    width:120%;
    z-index:98;
    .control-button{
      width: 40px;
      height: 30px;
      transition: background-color 100ms ease-in-out;
      display: flex;
      margin-right:25px;

      .control-icon{
        width:20px;
        height: 20px;
        margin:auto;

      }
    }
    .control-button-right{
      right:8%;
      position: absolute;
      .volume-div{
        position: relative;
        padding: 0;
        //margin-left: 60px;
        text-align: center;
        background-color: #FB5B77;
        transition: background-color 100ms ease-in-out;
        border-radius: 100px;
        width: 50px;
        height: 30px;
        text-align: center;
        transition: 200ms;
        .control-button.volume{
          width: 50px;
          height: 30px;
          background-color: transparent;
          margin:0;
          display: flex;
          .control-icon{
            width:20px;
            height: 20px;
            order:5;
            margin:auto;

          }


        }
      }
      .volume-div.unmute{
        background-color:#334B6D;
      }


      .volume-div:hover{
        display: flex;
        background-color:#8B9CBB!important;
        transition: background-color 100ms ease-in-out;
        width: 160px;
        z-index: 99;
        transition: 200ms;
        control-button.volume{
          margin-right: 0!important;
          padding: 0;
          background-color: transparent!important;
        }



        .volume-input{
          display: block;
          margin:auto auto auto 0!important;

        }
      }


    }
    .control-button-right.inactive{
      left:0;
    }
  }



}





#home-info{
  .home-info-transition-enter {
    opacity: 0;
    -webkit-transition:all 250ms ease-in-out;
    transition:  all 250ms ease-in-out;
  }
  .home-info-transition-enter-active {
    opacity: 1;

  }

  .home-info-transition-exit {
    opacity: 1;
    //-webkit-transition:all 1000ms ease-in-out;
    //transition:  all 1000ms ease-in-out;
  }
  .home-info-transition-exit-active {
    opacity: 0;
    -webkit-transition:all 250ms ease-in-out;
    transition:  all 250ms ease-in-out;
  }

}

.home-info.mobile.tablet{
  padding: 0 25px;
}

.home-info {
  position: absolute;
  //transition: 1s;
  //transition-timing-function: ease-in-out;

  .home-info-transition {
    justify-content: center;
    margin: auto;
    align-items: center;
  }


  .home-info-transition-enter {
    opacity: 0;

  }
  .home-info-transition-enter-active {
    opacity: 1;
    -webkit-transition:  all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
  }



  .home-info-transition-exit {
    opacity: 1;
  }
  .home-info-transition-exit-active {
    opacity: 0;
    -webkit-transition:all 250ms ease-in-out;
    transition:  all 250ms ease-in-out;
  }


  .home-info-channel-title {
    transition: 300ms;
    transition-timing-function: ease-in;
    height: 12px;
    @include homeInfoFontSize('Ubuntu',normal,500,16px,12px);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 5px 0px;
  }

  .home-info-content-title {
    top: calc(50% - 22px/2 - 18.5px);
    @include homeInfoFontSize('Ubuntu',normal,700,21.33px,21px);
    display: flex;
    align-items: flex-end;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 5px 0 0 0;

    p{
      margin:5px 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

    }
  }
  .home-info-content-title.tablet{

    margin:15px 0 15px 0!important;
    @include homeInfoFontSize('Ubuntu',normal,700,28px,21.33px);
  p{

    padding: 0;
    margin:0;
    overflow: visible!important;
  }
  }

  .timeline{
    height: 14px;
    left: 0%;
    right: 91.04%;
    bottom: 35px;
    margin-bottom:2.5%;
    @include homeInfoFontSize('Ubuntu',normal,500,12px,14px);
  }

  .timeline.tablet{
    @include homeInfoFontSize('Ubuntu',normal,500,16px,18.38px);
  }
  .home-info-playback-date {
    font-weight: 300;
    font-size: toRem(32);
    line-height: rescaleVertical(48px);
    color: #ffffff;
  }

  .home-info-ad {
    font-weight: 300;
    font-size: toRem(32px);
    line-height: toRem(37px);
  }

  .home-info-control-bar {

    display: flex;

    .target-wrapper{
      display: flex;
      height:40px;
      justify-content: center;
      align-items: center;
      width: fit-content;
      //margin-right: 6px;
    }

    .target-wrapper.volume{
      width:fit-content;
      order:2;
      position: absolute;
      margin-left:60px;

    }

    .control-button{
      width:36px;
      height: 25px;
      margin:auto;
      border:none;
      border-radius: 100px;
      background-color: #334B6D;
      transition: background-color 100ms ease-in-out;
      padding: 4.5px  10px;
      vertical-align:middle ;
      justify-content: space-between;
      display: flex;
      color:#FFFFFF;

      .control-icon{
        width:16px;
        height: 16px;
        order:5;
        margin:auto;

      }

    }
    .control-button:disabled, .control-button[disabled]{
      opacity: 0.5;
      cursor: pointer;

    }

    .volume-div {
      width:46px;
      height: 25px;
      background-color: #FB5B77;
      padding: 0;
      margin:auto;
      border:none;
      border-radius: 100px;


      .control-button{

        height: 25px;
        border:none;
        border-radius: 100px;
        background-color: transparent;
        //padding: 4.5px 15px;;
        vertical-align:middle ;
        margin:auto;
        display: flex;
        justify-content: space-between;
        color:#FFFFFF;
        .control-icon{
          margin:auto;

        }

      }

      input[type="range"] {
        -webkit-appearance: none;
        display: none;
        margin-right: 15px;
        width: 100px;
        height: 4px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        background-image: linear-gradient(#ffff, #ffff);
        background-size: 0 100%;
        background-repeat: no-repeat;
      }


      /* Input Thumb */
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: #FFFFFF;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background .3s ease-in-out;
      }

      input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: #FFFFFF;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background .3s ease-in-out;
      }

      input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: #FFFFFF;
        cursor: ew-resize;
        transition: background .3s ease-in-out;
      }

      input[type=range]::-webkit-slider-runnable-track  {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }

      input[type=range]::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }

      input[type="range"]::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }
      .control-button:hover{
        border:none!important;
        background-color:transparent!important;
      }
      .control-button.active{
        background-color:transparent!important;
      }
    }
    .volume-div.first{
      order: 1;
    }

    .volume-div.last{
      order:2;
    }

    .volume-div.unmute{
      background-color:#334B6D;
    }
    .control-button:hover{
      border:none!important;
      background-color:#8B9CBB!important;
    }
    .control-button.active{
      background-color:#657BA4!important;
    }

    .control-button.passive:hover{
      border:none!important;
      background-color:#334B6D!important;
      opacity: 0.5;
      cursor: default;
    }



    .control-button-left{

      display: flex;
      left:0;

    }
    .control-button-right{

      display: flex;
      position: absolute;
      right:3.5%;
      z-index:2;

      //#fullScreen{
      //  margin-right: 25px;
      //}
      .target-wrapper.last{
        order:2;
      }
      .target-wrapper.first{
        order:1;
      }



    }

  }
  .home-info-control-bar.tablet{
    .control-button-right{
      right:15px;
    }
  }
  .home-info-control-bar.tablet>*{
    button{
      width:40px;
      height: 30px;
      border-radius: 100px;
    }
  }

}

:export {
  horizontalScale: rescaleHorizontal(1px) / 1px;
  verticalScale: rescaleVertical(1px) / 1px;
}
