@import 'mixins';

@mixin animated($duration: 250ms) {
  @include transition(all $duration linear 0ms);
}

@mixin one-line {
  overflow: hidden;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
