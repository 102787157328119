
@import "~bootstrap/scss/bootstrap";


.App {
  background-color:transparent;
  color:white;
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  width: 100vw;
  font-family: "Ubuntu", sans-serif;
  min-height:100vh;
  min-height: -webkit-fill-available;
  -webkit-overflow-scrolling: none;
  z-index: 99;
  button:focus{
    outline: none;
  }
  button:hover{
    background-color:  #8B9CBB;
    transition: all 100ms ease-in;
    box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.3);

  }


  button:focus-visible{
    outline: none;
  }

  div:focus-visible{
    outline: none;
  }


  p,div,button,img{
    -moz-user-select: none!important;
    -webkit-user-select: none!important;
    -ms-user-select: none!important;
    user-select: none!important;
  }

  @media (hover: hover) {
    button{
      background: #334b6d;;
    }
  }

  button{
    outline: none;
    transition: all 150ms ease-in;
  }
  .fade-transition {
    justify-content: center;
    margin: auto;
    align-items: center;
  }


  .fade-transition-enter {
    opacity: 0.001;
  }
  .fade-transition-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
  .fade-transition-enter-done{
    opacity: 1;
    //transition: opacity 200ms ease-in;
  }


  .fade-transition-exit {
    opacity: 1;
    //transition: opacity 200ms ease-in;
  }
  .fade-transition-exit-active {
    opacity: 0;
    //transition: opacity 200ms;
  }
  .fade-transition-exit-done {
    opacity: 0;
  }


  .fade-transition-appear {
    opacity: 0.001;
  }
  .fade-transition-appear-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }


  ::selection {
    color: none;
    background: none;
  }
  /* For Mozilla Firefox */
  ::-moz-selection {
    color: none;
    background: none;
  }




  .faded-rewind-enter{
    background: #334B6D!important;
    transition: all 75ms ease-in-out;
    .control-icon.rewind{
      transform: rotate(0);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }


  }

  .faded-rewind-enter-active{
    background-color: #657ba4 !important;
    transition: all 75ms ease-in-out;
    .control-icon.rewind{
      transform: rotate(-45deg);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }
  .faded-rewind-enter-done{
    background-color: #657ba4 !important;
    transition: all 75ms ease-in-out;
    .control-icon.rewind {
      transform: rotate(-45deg);
    }
  }
  .faded-rewind-exit{
    background-color: #657ba4 !important;
    transition: all 75ms ease-in-out;
    .control-icon.rewind {
      transform: rotate(-45deg);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }
  .faded-rewind-exit-active{
    background: #334B6D !important;
    transition: all 75ms ease-in-out;
    .control-icon.rewind {
      transform: rotate(0);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }
  .faded-rewind-exit-done{
    background: #334B6D !important;
    transition: all 75ms ease-in-out;
    .control-icon.rewind {
      transform: rotate(0);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }

  .faded-forward-enter{
    background: #334B6D !important;
    transition: all 75ms ease-in-out;
    .control-icon.forward {
      transform: rotate(0);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }

  }

  .faded-forward-enter-active{
    background-color: #657ba4 !important;
    transition: all 75ms ease-in-out;
    .control-icon.forward {
      transform: rotate(45deg);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }
  .faded-forward-enter-done{
    background-color: #657ba4 !important;
    transition: all 75ms ease-in-out;
    .control-icon.forward {
      transform: rotate(45deg);
    }
  }
  .faded-forward-exit{
    background-color: #657ba4 !important;
    transition: all 75ms ease-in-out;
    .control-icon.forward {
      transform: rotate(45deg);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }
  .faded-forward-exit-active{
    background: #334B6D !important;
    transition: all 75ms ease-in-out;
    .control-icon.forward {
      transform: rotate(0);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }
  .faded-forward-exit-done{
    background: #334B6D !important;
    transition: all 75ms ease-in-out;
    .control-icon.forward {
      transform: rotate(0);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }


  .faded-play-enter{
    background: #334B6D !important;
    transition: all 75ms ease-in-out;
    .control-icon.play-pause {
      transform: scale(1);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }

  .faded-play-enter-active{
    background-color: #657ba4 !important;
    transition: all 75ms ease-in-out;
    .control-icon.play-pause {
      transform: scale(0.8);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }
  .faded-play-enter-done{
    background-color: #657ba4 !important;
    transition: all 75ms ease-in-out;
    .control-icon.play-pause {
      transform: scale(0.8);
    }
  }
  .faded-play-exit{
    background-color: #657ba4 !important;
    transition: all 75ms ease-in-out;
    .control-icon.play-pause {
      transform: scale(0.8);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }
  .faded-play-exit-active{
    background: #334B6D !important;
    transition: all 75ms ease-in-out;
    .control-icon.play-pause {
      transform: scale(1);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }
  .faded-play-exit-done{
    background: #334B6D !important;
    transition: all 75ms ease-in-out;
    .control-icon.play-pause {
      transform: scale(1);
      transition: all 75ms ease-in-out;
      -webkit-transition: all 75ms ease-in-out;
    }
  }

}



