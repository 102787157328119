.loading-wrapper {

  position: absolute;
  background-color: #0a2244;
  width: 100vw;
  height: 100vh;
  padding: 33px 165px;
  z-index: 1000;
  display: flex;
  justify-content: center;

  .splash-logo {
    display: flex;
    justify-content: center;

    img {
      margin: auto;
      width: 600px;
      height: 206px;
    }
  }
}

.loading-wrapper.mobile {

  .splash-logo {
    img {
      margin: auto;
      width: 178px;
      height: 61.04px;
    }
  }
}



.beta-gate-wrapper{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-image: url('../../assets/images/beta_desktop.png');
 background-size: 100% 100%;
  .beta{
    display: flex;
    width:100%;
    height: 100%;
    background:  linear-gradient(117.39deg, rgba(5, 19, 39, 0.65) 0%, rgba(9, 31, 62, 0.9) 51.55%, rgba(5, 19, 39, 0.65) 100%);;
    .beta-header{
      position: absolute;
      top: 0;
      left:0;
      width:100%;
      padding:50px 165px 0 165px;
      img{
        width:176.41px;
        height: 35px;
      }
    }
    .beta-content{
      position:relative ;

      margin:auto;
      width: 535px;
      height: 297px;
      justify-content: center;
      text-align: center;
      .beta-content-part{
        margin-bottom: 50px;
        display: block;
      }
      h3{
        font-style: normal;
        font-weight: bold;
        font-size: 28.43px;
        line-height: 33px;
      }
      h2{
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 21.33px;
        line-height: 25px;
      }
      p{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;
      }
      a{
        color:#FB5B77;
        text-decoration: none;
      }

      input{
        background: transparent;
        width: 180px;
        height: 38px;
        border: 2px solid #B5C3DB;
        border-radius: 5px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 15px;
        color:rgba(255, 255, 255, 1);
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;


      }

      input:focus{
        border: 2px solid #B5C3DB;
        border-radius: 5px;
        outline: none!important;
      }
      textarea:focus{
        border: 2px solid #B5C3DB;
        border-radius: 5px;
        outline: none!important;
      }
      input.wrong-code{
        border-color: rgba(248, 88, 88, 1);
      }
      p.error{
        color:rgba(248, 88, 88, 1);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.025em;
        margin-top:5px;

      }

      button{
        padding: 11px 20px  ;
       //width: 113px;
        height: 40px;
        background: #334B6D;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 15px;
        border: none;
        color:inherit;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;
      }
      button:hover{
        background: #8B9CBB;
        box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.3);
      }

    }
    .beta-footer{
      position: absolute;
      display: flex;
      width:100%;
      height:auto ;
      bottom:0;

      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      .web-site-hint{
        margin: auto;
        justify-content: center;
        padding-bottom: 53px;
        a{
          color:#FB5B77;
          text-decoration: none;
        }
      }
    }
  }
}


.beta-gate-wrapper.mobile{
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-image: url('../../assets/images/beta_mobile.png');
  .beta {
    .beta-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 15px;

      img {
        width: 100.81px;
        height: 20px;
      }
    }
    .beta-content{
      width: 100%;
      padding: 15px;
      .beta-content-part{
        margin-bottom: 35px;
        z-index: 2;
        position: relative;
        a{
          color:#FB5B77;
          text-decoration: none;
        }
      }
      h2{
        font-size: 21.33px;
        line-height: 25px;
      }
      h3{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.025em;
      }
      p{
        font-size: 12px;
        line-height: 14px;
      }

      input{
        background: transparent;
        width: 180px;
        height: 34px;
        border: 2px solid #B5C3DB;
        border-radius: 5px;
        flex: none;
        order: 0;
        flex-grow: 0;
        color:rgba(255, 255, 255, 1);
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 14px;
        letter-spacing: 0.025em;


      }
      input:focus{
        border: 3px solid #B5C3DB;
      }
      input.wrong-code{
        border-color: rgba(248, 88, 88, 1);
      }
      p{
        color:rgba(248, 88, 88, 1);
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.025em;
      }

      button{
        //width: 103px;
        height: 30px;
        background: #334B6D;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 15px;
        border: none;
        color:inherit;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;
        font-style: normal;
        font-weight: bold;
        padding: 6px 15px;

      }
      button:hover{
        background: #8B9CBB;
        box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.3);
      }

    }
    .beta-footer{
      position: absolute;
      bottom: 53px;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      height: auto;
      .web-site-hint{
        margin: auto;
        justify-content: center;
        padding-bottom: 0;
        a{
          color:#FB5B77;
          text-decoration: none;
        }
      }
    }
  }
}

.mobile {
  .beta-gate-wrapper {

  }
}
