
.menu-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 14px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all .2s ease-in-out;
}
  .menu-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 14px;
    transition: all .2s ease-in-out;
  }
    .menu-btn-burger {
      width: 22px;
      height: 2px;
      background: #fff;
      margin:2px auto;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
      transition: all .2s ease-in-out;
    }


    .menu-btn-burger::before, .menu-btn-burger::after {
      content: '';
      position: absolute;
      width: 22px;
      height: 2px;
      margin:auto -11px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
      transition: all .2s ease-in-out;
    }

    .menu-btn-burger::before {
      transform:translateY(-6px);

    }

    .menu-btn-burger::after {
      transform: translateY(6px);
    }

.menu-btn.collapsed .menu-btn-burger {
  transform: translateX(-2px);
  background: transparent;
  box-shadow: none;
  transition: all .2s ease-in-out;
}
.menu-btn.collapsed .menu-btn-burger::before {
  transform: rotate(45deg) translate(2px, -2px);
  transition: all .2s ease-in-out;
}
.menu-btn.collapsed .menu-btn-burger::after {
  transform: rotate(-45deg) translate(2px, 2px);
  transition: all .2s ease-in-out;
}





