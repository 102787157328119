.player-controller-wrapper {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;

  .ads-countdown{
    top:11.9%;
  }
  button:hover{
    background-color:  #8B9CBB !important;
    transition: background-color 150ms ease-in-out;
  }

  .home-transition-enter {
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 0.001;
    -webkit-transition: background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition: background 250ms ease-in-out, opacity 250ms ease-in-out;

    .controller-buttons{
      transform: translateY(50px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;

    }
    .controller-header{
      transform: translateY(-50px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
  }


  .home-transition-enter-active {
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 1;
    -webkit-transition:background 250ms ease-in-out,  opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .controller-buttons{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .controller-header{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }

  }




  .home-transition-enter-done{
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 1;
    .controller-buttons{
      transform: translateY(0);
    }
    .controller-header{
      transform: translateY(0);
    }
  }




  .home-transition-exit{
    width:100%;
    height: 100%;
    opacity: 1;
    background: transparent;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .controller-buttons{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .controller-header{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
  }





  .home-transition-exit-active {
    width:100%;
    height: 100%;
    opacity: 0;
    background: transparent;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .controller-buttons{
      transform: translateY(50px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .controller-header{
      transform: translateY(-50px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
  }




  .home-transition-exit-done {
    width:100%;
    height: 100%;
    opacity: 0;
    background: transparent;
    .controller-buttons{
      transform: translateY(50px);
    }
    .controller-header{
      transform: translateY(-50px);
    }
  }





  .home-transition-mobile-enter {
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 0.001;
    -webkit-transition: background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition: background 250ms ease-in-out, opacity 250ms ease-in-out;

    .controller-buttons{
      transform: translateY(25px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;

    }
    .controller-header{
      transform: translateY(-25px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
  }


  .home-transition-mobile-enter-active {
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 1;
    -webkit-transition:background 250ms ease-in-out,  opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .controller-buttons{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .controller-header{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }

  }




  .home-transition-mobile-enter-done{
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 1;
    .controller-buttons{
      transform: translateY(0);
    }
    .controller-header{
      transform: translateY(0);
    }
  }




  .home-transition-mobile-exit{
    width:100%;
    height: 100%;
    opacity: 1;
    background: transparent;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .controller-buttons{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .controller-header{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
  }





  .home-transition-mobile-exit-active {
    width:100%;
    height: 100%;
    opacity: 0;
    background: transparent;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .controller-buttons{
      transform: translateY(25px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .controller-header{
      transform: translateY(-25px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
  }




  .home-transition-mobile-exit-done {
    width:100%;
    height: 100%;
    opacity: 0;
    background: transparent;
    .controller-buttons{
      transform: translateY(25px);
    }
    .controller-header{
      transform: translateY(-25px);
    }
  }








  .ad-part{
    display: flex;
    justify-content: space-between;
    width:100vw;
    min-width: -webkit-fill-available;
    padding-left: 9%;
    padding-right: 9%;
    left: 0!important;
    height: calc(100vh - 120px);
    padding-top:calc(100vh - 180px);
    position: fixed !important;
    /* padding-top: 10px; */
    bottom: 0 !important;
    top: auto !important;
    z-index: 1000;
    .ad-label.mobile{
      display: flex;
      height: auto;
      width: auto;
      white-space: nowrap;
      margin-right: 16px;
      padding: 3px 0;
      overflow: hidden;
      strong{
        font-weight: bolder;
        overflow: hidden;
        white-space: break-spaces;
        height: 16px;
        margin-right: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      strong.long-text{
        margin-right:0;
      }
    }

    .ads-countdown.mobile{
      inset:unset!important;
        top:0!important;
        position: relative!important;
        right:0!important;
      width:auto;
      //min-width:90px;

    }
  }
  .ad-part.tablet{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    position: fixed !important;
    /* padding-top: 10px; */
    bottom: 35px !important;
    padding-left: 0;
    left: 45px!important;
    top: auto !important;
    margin: auto;
    z-index: 1000;

    .ads-countdown.tablet{
      inset:unset!important;
      bottom:70px;
      top:auto;
      display: flex;
      width: auto;
      height: fit-content;
      right:45px!important;
      left:auto!important;
      font-weight: 400;
      font-size: 21.33px;
      line-height: 25px;



      .ads-countdown-ring{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:auto;
        height: fit-content;
      }
     .ads-countdown-text{
        margin:0 0 0 40px!important;
      }
    }
  }

  .ad-part.tablet.inactive{
    display: flex;
    justify-content: space-between;
    min-width:100%;
    min-width: -webkit-fill-available;
    padding-left: 45px;
    padding-right: 45px;
    left: 0!important;
    height: 100vh;
    padding-top:calc(100vh - 65px);
    position: fixed !important;
    /* padding-top: 10px; */
    bottom: 0 !important;
    top: auto !important;
    z-index: 1000;
    background: linear-gradient(180deg, rgba(10, 34, 68, 0) 0%, rgba(10, 34, 68, 0) 76.16%, rgba(10, 34, 68, 0.25) 88.31%, rgba(10, 34, 68, 0.5) 100%);
    .ad-label.inActive{
      left:45px!important;
    }
    .ads-countdown.tablet{
      inset:unset!important;
      bottom:70px!important;
      top:auto;
      display: flex;
      width: auto;
      height: fit-content;
      right:45px!important;
      left:auto!important;
      font-weight: 400;
      font-size: 21.33px;
      line-height: 25px;



      .ads-countdown-ring{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:auto;
        height: fit-content;
      }
      .ads-countdown-text{
        margin:0 0 0 40px!important;
      }
    }
  }

  .ad-part.inactive{
    //width:100vw;
    //min-width: -webkit-fill-available;
    //padding-left: 9%;
    //padding-right: 9%;
    //left: 0!important;
    //height: 100vh;
    padding-top:calc(100vh - 180px);
    //position: fixed !important;
    ///* padding-top: 10px; */
    //bottom: 0 !important;
    //top: auto !important;
    //z-index: 1000;
    background: linear-gradient(180deg, rgba(10, 34, 68, 0) 0%, rgba(10, 34, 68, 0) 76.16%, rgba(10, 34, 68, 0.25) 88.31%, rgba(10, 34, 68, 0.5) 100%);
    .ads-countdown.mobile{
      inset:unset!important;
      top:0!important;
      position: relative!important;
      right:0!important;
      width:auto;
      //min-width:90px;
      left:auto!important

    }
  }


  #fullScreen{
    margin:auto 0;
  }
  .loading-view {
    position: relative;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: rgba(10, 34, 68, 0.5);

    img {
      vertical-align: center;
      margin: auto;
      width: 8.6rem;
      height: 8.6rem;
    }
  }

  .loading-view.mobile {
    img {
      width: 58px;
      height: 58px;
    }
  }


  .controller-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 12%;
    background: rgba(10, 34, 68, 0.5);
    z-index: 100;

    .controller-header {
      display: flex;
      justify-content: space-between;
      top: 0;
      width: 100%;
      height: 90px;
      padding: 2.45% 0;

      .desktop-back{
        height: 40px;
        margin:auto 25px auto 0;
      }

      .buttons-group {
        display: flex;

        .target-wrapper{
          display:flex;
          align-items:center;
          width:fit-content;
          min-width: 40px;
          height:40px;
          margin-top:-7.5px;
        }

        button {
          height: 25px;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
          border-radius: 100px;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: auto 15px auto auto;
        }


        .volume-div {
          width: 46px;
          height: 25px;
          background-color: #FB5B77;
          padding: 0;
          margin-right: 10px;
          border: none;
          border-radius: 100px;


          .control-button {

            height: 25px;
            border: none;
            border-radius: 100px;
            background-color: transparent;
            padding: inherit;
            vertical-align: middle;
            justify-content: space-between;
            color: #FFFFFF;

            .control-icon {
              margin: -3px auto auto auto;

            }

          }

          input[type="range"] {
            -webkit-appearance: none;
            display: none;
            margin-right: 15px;
            width: 100px;
            height: 4px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            background-image: linear-gradient(#ffff, #ffff);
            background-size: 0 100%;
            background-repeat: no-repeat;
          }

          /* Input Thumb */
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: ew-resize;
            box-shadow: 0 0 2px 0 #555;
            transition: background .3s ease-in-out;
          }

          input[type="range"]::-moz-range-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: ew-resize;
            box-shadow: 0 0 2px 0 #555;
            transition: background .3s ease-in-out;
          }

          input[type="range"]::-ms-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: ew-resize;
            transition: background .3s ease-in-out;
          }

          input[type=range]::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          input[type=range]::-moz-range-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          input[type="range"]::-ms-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          //.control-button:hover {
          //  border: none !important;
          //  background-color:  #8B9CBB !important;
          //  transition: background-color 150ms ease-in-out;
          //}

          .control-button.active {
            background-color: transparent !important;
          }
        }

        .volume-div.unmute {
          background-color: #334B6D;
        }


        .control-button.active {
          background-color: #657BA4 !important;
        }

        .volume-div {
          padding: 0;
          text-align: center;
          margin-top: 0;
          background-color: #FB5B77;
          border-radius: 100px;
          width: 46px;
          height: 25px;
          text-align: center;
          transition: 200ms;

          .control-button.volume {
            width: 46px;
            height: 25px;
            background-color: transparent;
            margin: 0;

            .control-icon {
              width: 16px;
              height: 16px;
              order: 5;
              margin: auto;

            }


          }
        }

        .volume-div.unmute {
          background-color: #334B6D;
        }


        .volume-div:hover {
          display: flex;
          background-color:  #8B9CBB !important;
          width: 160px;
          z-index: 99;
          transition: background-color 150ms ease-in-out;

          control-button.volume {
            margin-right: 0 !important;
            padding: 0;
            background-color: transparent !important;
          }


          .volume-input {
            display: block;
            margin: auto auto auto 0 !important;

          }
        }

        .volume-div.apple:hover{
          width: 46px;
        }
      }

      .logo {
        width:126px;
        height: 25px;
        order:2;
        margin:auto 0 auto auto;
      }

      .logo.spinning{
        width:60px;
        height: 60px;
        margin-top: -10px;
        div{
          width:60px;
          height: 60px;
        }
      }

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background: #334B6D;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0 25px 0 0;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.5px;
        border: none;

        color: #FFFFFF;

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;

        }
      }
    }


    .controller-buttons {
      position: absolute;
      bottom: 4.88vh;
      width: 76%;

      .ad-label {
        margin: auto;
        justify-content: center;
      }

      .buttons-group {
        display: flex;
        //justify-content: space-between;
        margin-top: 1.125rem;

        .volume-div {
          position: absolute;
          left:0;
          padding: 0;
          text-align: center;
          margin-top: 0;
          background-color: #FB5B77;
          border-radius: 100px;
          width: 50px;
          height: 30px;
          text-align: center;
          transition: 200ms;

          .control-button.volume {
            width: auto;
            height: 30px;
            background-color: transparent;
            margin: 0;
            padding:5px 15px;
            display: flex;

            .control-icon {
              width: 20px;
              height: 20px;
              order: 5;
              margin: auto;

            }


          }
        }

        .volume-div.unmute {
          background-color: #334B6D;
        }


        .volume-div:hover {
          display: flex;
          background-color:  #8B9CBB !important;
          transition: background-color 150ms ease-in-out;
          width: 160px;
          z-index: 99;
          transition: 200ms;

          control-button.volume {
            margin-right: 0 !important;
            padding: 0;
            background-color: transparent !important;
          }


          .volume-input {
            display: block;
            margin: auto auto auto 0 !important;

          }
        }


          .control-button {
            width: 40px;
            height: 30px;
            margin:auto 12.5px;
            border: none;
            border-radius: 100px;
            background-color: #334B6D;
            padding: inherit;
            vertical-align: middle;
            justify-content: space-between;
            color: #FFFFFF;

            .control-icon {
              width: 20px;
              height: 20px;
              order: 5;
              margin-top: -4px;

            }

          }



        .volume-div {
          width: 50px;
          height: 30px;
          background-color: #FB5B77;
          padding: 0;
          margin-right: 10px;
          border: none;
          border-radius: 100px;


          .control-button {
            height: 30px;
            border: none;
            border-radius: 100px;
            background-color: transparent;
            padding: inherit;
            vertical-align: middle;
            justify-content: space-between;
            color: #FFFFFF;

            .control-icon {
              margin-top: 15px;

            }

          }

          input[type="range"] {
            -webkit-appearance: none;
            display: none;
            margin-right: 15px;
            width: 100px;
            height: 4px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            background-image: linear-gradient(#ffff, #ffff);
            background-size: 0 100%;
            background-repeat: no-repeat;
          }

          /* Input Thumb */
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: ew-resize;
            box-shadow: 0 0 2px 0 #555;
            transition: background .3s ease-in-out;
          }

          input[type="range"]::-moz-range-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: ew-resize;
            box-shadow: 0 0 2px 0 #555;
            transition: background .3s ease-in-out;
          }

          input[type="range"]::-ms-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: ew-resize;
            transition: background .3s ease-in-out;
          }

          input[type=range]::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          input[type=range]::-moz-range-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          input[type="range"]::-ms-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          .control-button:hover {
            border: none !important;
            background-color:  #8B9CBB !important;
            transition: background-color 150ms ease-in-out;
          }

          .control-button.active {
            background-color: transparent !important;
          }
        }

        .volume-div.unmute {
          background-color: #334B6D;
        }
        .control-button-left{
          margin:auto;
        }
        .control-button-right{
          position: absolute;
          right:0;
        }

        .control-button:hover {
          border: none !important;
          background-color:  #8B9CBB !important;
          transition: background-color 150ms ease-in-out;
        }

        .control-button.active {
          background-color: #657BA4 !important;
        }


      }


    }


    .content-title {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.78rem;
      line-height: 1.3125rem;
      display: flex;
      align-items: flex-end;
      text-align: center;

      p {
        margin-bottom: 0;
      }

      color: #FFFFFF;
    }

    .content-title.corner {
      left: 0;
      font-weight: bold;
      font-size: 21.33px;
      line-height: 21px;
      display: flex;
      margin-bottom: 10%;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 1rem !important;
      }
    }

  }

  .controller-wrapper.hide{
    display: none;
  }
  .controller-wrapper{
    display: block;
  }

  .controller-wrapper.mobile, .controller-wrapper.tablet {
    width: 100%;
    height: 100%;
    position: fixed;
    padding: 0 9%;
    top: 0;
    left: 0;
    bottom: 0;



    .control-button:hover {
      border: none !important;
      background-color: #334B6D!important;
    }

    .control-button:active {
      background-color: #657BA4 !important;
      transition: background 150ms ease-in-out;

    }

    .controller-header {
      padding-top: 35.5px;
      height: auto;


      .buttons-group {
        button {
          height: 25px;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
          border-radius: 100px;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0 15px 0 0;
          padding: 4.5px 10px;
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;

          img {
            width:16px;
            height:16px;
            margin-right: 5px;

          }
        }
        .volume-div:hover{
          background-color: #FB5B77!important;
          .control-button:hover{
            background-color: #FB5B77!important;
          }
        }
        .volume-div.unmute:hover{
          background-color: #334B6D!important;
          .control-button:hover{
            background-color: #334B6D!important;
          }
        }
      }

      .buttons-group.tablet{

        button{
          height: 30px;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          img{
            width:20px;
            height: 20px;
          }
        }
        .volume-div{
          height:30px;
          button{
            height:30px;
            img{
              width:20px;
              height: 20px;
            }
          }
        }
      }


    }

    .button-group {
      display: flex;
      justify-content: center;
      position: relative;
      top:calc(47.55% - 90.5px);

      button {
        padding: 8px 18px;
        width: 60px;
        height: 40px;
        background: #334B6D;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        flex: none;
        order: 0;
        flex-grow: 0;
        display: flex;
        margin: 0px 15px;
        border: none;
        transition: all 150ms ease-in-out;
        -webkit-transition: all 150ms ease-in-out;
        img {
          width: 25px;
          height: 25px;
          margin:auto;
        }

      }

      button:focus{
        background: #334B6D;
      }
      //button:active{
      //  background: #334B6D;
      //  transition: all 100ms ease-in;
      //}

      .control-spinner{
        width:60px;
        height: 40px;
        display: flex;
        img{
          width:40px;
          height: 40px;
          margin:auto;
        }
      }

    }


    .controller-buttons {
      width: 82%;
      /* height: 70px; */
      bottom: 31px;
      position: absolute;

      .content-title {
        justify-content: left;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 21.33px;
        line-height: 21px;
        text-align: left;

        p {
          margin-bottom: 0;
        }
      }


    }

    .controller-buttons.ad-plays{
      bottom:70px!important;
    }

  }

  .controller-wrapper.tablet{
    padding:0 45px;
    .controller-buttons{
      width:calc(100% - 90px);
      bottom:45px;
      .content-title{
        margin-bottom:15px;
      }
      .progressbar-wrapper{
        .time{
          font-weight: 500;
          font-size: 16px;
          line-height: 18.38px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

    }
    .button-group.tablet{
      .control-button, .control-spinner{
        width:80px;
        height:55px;
        margin:0 25px;
        transition: all 150ms ease-in-out;
        -webkit-transition: all 150ms ease-in-out;
        .control-icon{
          width:35px;
          height:35px;
          transition: all 150ms ease-in-out;
          -webkit-transition: all 150ms ease-in-out;
        }

      }
    }
  }

  .controller-wrapper.mobile.hide{
    display: none;
  }


}



