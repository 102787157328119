.promotion-page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1501;
  backdrop-filter: blur(10px);
  background: rgba(3, 12, 26, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;



  .promoted-wrapper {
    min-width: 77vh;
    min-height: 77vh;
    max-width: 800px;
    max-height: 800px;
    border-radius: 15px;

    .content-detail-wrapper {
      position: relative;
      min-width: 77vh;
      min-height: 77vh;
      max-width: 800px;
      max-height: 800px;
      border-radius: 15px;

      .content-background {
        border-radius: 15px;
        width:100%;

        .content-info-wrapper {
          display: flex;
          justify-content: center;
          background: linear-gradient(1deg, #0e2345, rgba(2, 6, 48, 0.4) 100%);
          max-width: none;
          //align-items: center;
          border-radius: 15px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          //background: #0a2244;
              .channel-page-channel-tags {
                position: absolute;
                top: calc(17.58vh + 101px);
                width: 100%;
                max-width:54vh;
                margin: auto;
                padding-inline-start: 0;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                justify-content: center;
                justify-content: center;
                text-align: center;
                visibility: visible;
                opacity: 1;
                transition: all 0.15s;

                .channel-page-channel-tag-item {
                  display: inline;
                  height: 24px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 18px;
                  margin: 0 8px;
                  white-space: nowrap;

                  div {
                    margin: 0 8px;
                    width: 4px;
                    height: 24px;
                    position: relative;
                    display: inline-block;
                    line-height: 200px;

                    .bullet {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 8px;
                      height: 8px;
                      border-radius: 100%;
                      margin: auto 4px auto auto;
                      top: 16px;
                    }

                    .overlay {
                      z-index: 3;
                      background-color: rgba(255, 255, 255, 0.5);
                    }
                  }
                }
              }
        }
      }

      .channels-logo {
        display: flex;
        margin: auto;
        width: calc(100% - 60px);
        justify-content: center;
        align-items: center;
        /* background: linear-gradient(360deg, #0A2244 1.47%, rgba(10, 34, 68, 0.4) 100%); */

        .promoted-slider-channel {
          width: 31.25vh;
          height: 17.58vh;
          background-size: cover;
          //margin: auto -10.05vh;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
          border-radius: 5px;
        }
      }

      .promotion-info {
        height: 54.43%;
        padding-left: 30px;
        padding-right: 160px;

        .promotion-channels-button-group{
          display: flex;
          .promotion-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 11px 20px;
            height: 40px;
            left: 0px;
            top: 0px;
            border: none;
            color: white;
            background: #334B6D;
            box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
            border-radius: 100px;

            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.5px;
            margin-right:20px;
          }

          .promotion-button:hover {
            background-color: #8B9CBB;
            transition: all 100ms ease-in;
            box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.3);
          }
        }

        .dots {
          display: flex;
          margin-top: 5px;
          .promoted-dots {
            width: 10px;
            height: 10px;
            background: #748CAB;
            border-radius: 100px;
            margin: 0px 5px 25px 0;
            padding-bottom: unset;
            transition:width 150ms ease-in-out;
            transition:width 150ms ease-in-out;
            -webkit-transition:width 150ms ease-in-out;
          }

          .promoted-dots.active-dot{
            width:40px;
            transition:width 150ms ease-in-out;
            -webkit-transition:width 150ms ease-in-out;

          }
        }


        h3 {
          font-family: 'Ubuntu';
          font-style: normal;
          font-weight: 700;
          font-size: 28.43px;
          line-height: 33px;
          margin-bottom: 25px;
        }
        h3.one-channel-new{
          font-size: 21.33px;
          line-height: 25px;
          margin: 10px auto 25px auto;
        }

        p {
          font-family: 'Ubuntu';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.025em;
          margin-bottom: 25px;
        }

        .promotion-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 11px 20px;
          height: 40px;
          left: 0px;
          top: 0px;
          border: none;
          color: white;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
          border-radius: 100px;

          /* Inside auto layout */

          flex: none;
          order: 0;
          flex-grow: 0;
          font-family: 'Ubuntu';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.5px;
        }

        .promotion-button:hover {
          background-color: #8B9CBB;
          transition: all 100ms ease-in;
          box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.3);
        }

        .promotion-direction {
          position: absolute;
          top: auto;
          bottom: 30px;
          display: flex;
          padding-bottom: unset;

          .promotion-direct-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6px 10px;
            height: 30px;
            left: 0px;
            top: 0px;
            color: white;
            border: none;

            background: #27364B;
            box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
            border-radius: 100px;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 0px 20px 0 0;

          }


          .promotion-direct-button:hover {
            background-color: #8B9CBB;
            transition: all 100ms ease-in;
            box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.3);
          }
        }

        .promotion-direction.channels{
          flex-direction: row-reverse;
        }

      }
      :nth-child(5){
        padding-bottom: unset;
      }
    }


  }

  .promoted-wrapper.mobile{
    width:100%;
    height: 100%;
    min-height: unset;
    max-height: unset;
  min-width: unset;
    max-width: unset;
    border-radius: unset;
    button:hover, .promotion-button:hover{
      border: none !important;
      background-color: #334B6D!important;
    }
    .promotion-direct-button:hover{
      border:none!important;
      background: #27364B!important;
    }

    button:active,promotion-button:active ,.promotion-direct-button:active{
      background-color: #657ba4 !important;
      transition: all 150ms ease-in-out;

    }
    .content-detail-wrapper{
      border-radius: unset;
      min-height: unset;
      max-height: unset;
      min-width: unset;
      max-width: unset;
      height: 100%;
      .content-background{
        border-radius: unset;
        height: 36.94%;
        .content-info-wrapper{
          border-radius: unset;
          height: 100%;
          .channel-page-channel-tags {
            top:190px;
            bottom:auto;
            padding: 0 26.5px;
            .channel-page-channel-tag-item {
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              div{
                .bullet{
                  width:6px;
                  height:6px;
                }
              }
            }
          }
          .channels-logo{
            .promoted-slider-channel{
              width:160px;
              height: 90px;
              margin: auto -60px;
            }
          }

          .promoted-slider{
            top:80px;
          }
        }
      }
      .promotion-info{
        height: auto;
        padding: 1px 15px;
        .dots{
          .promoted-dots{
            margin: 0px 5px 5px 0;
          }
        }
        h3{
          font-style: normal;
          font-weight: 500;
          font-size: 21.33px;
          line-height: 25px;
          margin:5px 0;
        }
        h3.one-channel-new{
          font-size: 16px;
          line-height: 18.38px;
          margin:10px 0;
        }
        p{
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.025em;
          margin:15px 0;
        }
        .promotion-button{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px 15px;
          height: 30px;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
          border-radius: 100px;
          margin-right:15px;
          //font-style: normal;
          //font-weight: 700;
          //font-size: 16px;
          //line-height: 18px;
          //letter-spacing: 0.5px;
        }

        .promotion-direction{
          flex-flow:row wrap;
          row-gap:10px;
          //:last-of-type(){
          //  margin-bottom: 10px;
          //}
          .promotion-direct-button{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5.5px 10px;;
            height: 25px;
            background: #27364B;
            box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
            border-radius: 100px;
            margin-right:15px;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
           letter-spacing: 0.5px;

          }
        }
      }
    }
  }

  .promoted-wrapper.tablet{
    width:100%;
    height: 100%;
    border-radius: unset;
    .content-detail-wrapper{
      border-radius: unset;
      .content-background{
        border-radius: unset;
        height: 50%;
        .content-info-wrapper{
          border-radius: unset;
          height: 50%;
          .promoted-slider{
            width:auto;
          }
          .channel-page-channel-tags {
            top:391px;

          }
          .channels-logo{
            .promoted-slider-channel{
              width:320px;
              height: 180px;
              margin: auto -120px;
            }
          }

          .promoted-slider{
            top:168px;
            .promoted-slider-swiper,.promoted-slider-channel-slide{
              width:320px;
              height: 180px;

            }
          }
        }
      }
      .promotion-info{
        height: auto;
        padding: 0 30px;
        max-width: 600px;
        .dots{
          .promoted-dots{
            margin: 0px 5px 5px 0;
          }
        }
        h3{
          font-weight: 500;
          font-size: 21.33px;
          line-height: 25px;
          margin:25px 0;
        }
        h3.one-channel-new{
          font-size: 21.33px;
          line-height: 25px;
          margin: 10px auto;
        }
        p{

          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.025em;
          margin:25px 0;
        }
        .promotion-button{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px 15px;
          height: 40px;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
          border-radius: 100px;
          margin-right:15px;
          //font-style: normal;
          //font-weight: 700;
          //font-size: 16px;
          //line-height: 18px;
          //letter-spacing: 0.5px;
        }

        .promotion-direction{
          .promotion-direct-button{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5.5px 15px;;
            height: 30px;
            background: #27364B;
            box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
            border-radius: 100px;
            margin-right:15px;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.5px;

          }
        }
      }
    }
  }



}



.promoted-transition-enter {
  opacity: 0.001;
  transition: opacity 150ms ease-in;
  -webkit-transition: opacity 150ms ease-in;
}
.promoted-transition-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
  -webkit-transition: opacity 150ms ease-in;
}
.promoted-transition-enter-done{
  opacity: 1;
  //transition: opacity 200ms ease-in;
}


.promoted-transition-exit {
  opacity: 1;
  transition: opacity 150ms ease-in;
  -webkit-transition: opacity 150ms ease-in;
}
.promoted-transition-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in;
  -webkit-transition: opacity 150ms ease-in;
}
.promoted-transition-exit-done {
  opacity: 0;
}


@media screen and (max-height:772px){
.promotion-page{
  .promoted-wrapper.desktop{
    overflow: scroll;
    min-height: 788px;
    .content-detail-wrapper{
      min-height: 788px;
      .content-background{
        min-height:394px;
        .content-info-wrapper{
          min-height:395px;
         .promoted-slider{
           top:40px;
         }
          .channel-page-channel-tags{
            max-width:90vh
          }
        }
      }
      .promotion-info{
        overflow: scroll;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        -webkit-overflow-scrolling: touch;
        min-height:394px;

        .promotion-direction.channels{
          flex-direction: unset;
          position: relative;
          top:auto;
          bottom:auto;
          margin-top:30px;
          margin-bottom:30px;
        }
      }
      .promotion-info::-webkit-scrollbar{
        display: none;
      }
    }
  }

  .promoted-wrapper.mobile{
    .content-detail-wrapper{
      .content-background{
        .content-info-wrapper{
          .promoted-slider{
            top:60px;
          }
          .channel-page-channel-tags{
            top:160px;
            max-width: unset;
            padding: 0 15px;
          }
        }
      }
    }
  }
}
}
