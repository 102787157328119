.home-landscape-wrapper{
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: -webkit-fill-available;


  .home-transition-enter{
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 0.001;
    -webkit-transition: background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition: background 250ms ease-in-out, opacity 250ms ease-in-out;

    .controller-buttons{
      transform: translateY(25px);
      transform: translateY(25px);
      -webkit-transform: translateY(25px);
      -moz-transform: translateY(25px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;

    }
    .controller-header{
      transform: translateY(-25px);
      -webkit-transform: translateY(-25px);
      -moz-transform: translateY(-25px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }


  }

  .landscape-program-guide.landscape-epg-transition-enter{
    opacity: 0.001;
    -webkit-transition: background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition: background 250ms ease-in-out, opacity 250ms ease-in-out;
    .browse-channel{
      transform: translateY(-25px);
      -webkit-transform: translateY(-25px);
      -moz-transform: translateY(-25px);
      -webkit-transition: all 250ms ease-in-out!important;
      transition: all 250ms ease-in-out!important;
    }
    .program-guide-arrow, .program-guide-view.mobile{
      transform:  translateX(25px);
      -webkit-transform:  translateX(25px);
      -moz-transform:  translateX(25px);
      -webkit-transition: all 250ms ease-in-out!important;
      transition: all 250ms ease-in-out!important;
    }

  }



  .home-transition-enter-active{
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 1;
    -webkit-transition:background 250ms ease-in-out,  opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .controller-buttons{
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform:  translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .controller-header{
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform:  translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
  }

  .landscape-program-guide.landscape-epg-transition-enter-active{
    opacity: 1;
    -webkit-transition:background 250ms ease-in-out,  opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .browse-channel{
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform:  translateX(0);
      -webkit-transition: all 250ms ease-in-out!important;
      transition: all 250ms ease-in-out!important;
    }
    .program-guide-arrow, .program-guide-view.mobile{
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform:  translateY(0);
      -webkit-transition: all 250ms ease-in-out!important;
      transition: all 250ms ease-in-out!important;
    }

  }



  .home-transition-enter-done{
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 1;
    .controller-buttons{
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform:  translateY(0);
    }
    .controller-header{
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform:  translateY(0);
    }
  }

  .landscape-program-guide.landscape-epg-transition-enter-done{
    opacity: 1;
    .browse-channel{
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform:  translateY(0);
    }

    .program-guide-arrow, .program-guide-view.mobile{
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform:  translateX(0);
    }
  }


  .home-transition-exit{
    width:100%;
    height: 100%;
    opacity: 1;
    background: transparent;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .controller-buttons{
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform:  translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .controller-header{
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform:  translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
  }

  .landscape-program-guide.landscape-epg-transition-exit{
    opacity: 1;
    background: transparent;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .browse-channel{
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform:  translateY(0);
      -webkit-transition: all 250ms ease-in-out!important;
      transition: all 250ms ease-in-out!important;
    }

    .program-guide-arrow, .program-guide-view.mobile{
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform:  translateX(0);
      -webkit-transition: all 250ms ease-in-out!important;
      transition: all 250ms ease-in-out!important;
    }

  }

  .home-transition-exit-active{
    width:100%;
    height: 100%;
    opacity: 0;
    background: transparent;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .controller-buttons{

      transform:translateY(25px);
      -webkit-transform:translateY(25px);
      -moz-transform: translateY(25px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .controller-header{
      transform:translateY(-25px);
      -webkit-transform: translateY(-25px);
      -moz-transform: translateY(-25px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }

  }

  .landscape-program-guide.landscape-epg-transition-exit-active{
    opacity: 0;
    -webkit-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out;
    .browse-channel{
      transform:translateY(-25px);
      -webkit-transform: translateY(-25px);
      -moz-transform: translateY(-25px);
      -webkit-transition: all 250ms ease-in-out!important;
      transition: all 250ms ease-in-out!important;
    }
    .program-guide-arrow, .program-guide-view.mobile{
      transform: translateX(25px);
      -webkit-transform:  translateX(25px);
      -moz-transform:  translateX(25px);

      -webkit-transition: all 250ms ease-in-out!important;
      transition: all 250ms ease-in-out!important;
    }

  }


  .home-transition-exit-done {
    width:100%;
    height: 100%;
    opacity: 0;
    background: transparent;
    .controller-buttons{
      transform: translateY(25px);
      -webkit-transform: translateY(25px);
    -moz-transform: translateY(25px);
    }
    .controller-header{
      transform: translateY(-25px);
      -webkit-transform: translateY(-25px);
      -moz-transform: translateY(-25px);
    }
  }


  .landscape-program-guide.landscape-epg-transition-exit-done{
    opacity: 0;
    .browse-channel{
      transform: translateY(-25px);
      -webkit-transform: translateY(-25px);
      -moz-transform: translateY(-25px);
    }
    .program-guide-arrow, .program-guide-view.mobile{
      transform: translateX(25px);
      -webkit-transform:  translateX(25px);
      -moz-transform:  translateX(25px);
    }
  }




  .ad-part{
    display: flex;
    justify-content: space-between;
    width: 82%;
    height: auto;
    position: fixed !important;
    /* padding-top: 10px; */
    bottom: 40px!important;
    left: 9% !important;
    top: auto !important;
    z-index: 1000;


    .ads-countdown.mobile{
      top:0!important;
      position: relative!important;
      right:0!important;
      left:auto!important;
    }
  }

  .ad-part.inactive{
    display: flex;
    justify-content: space-between;
    min-width:100vh;
    min-width: -webkit-fill-available;
    padding-left: 9%;
    padding-right: 9%;
    left: 0!important;
    height: 100vh;
    padding-top:calc(100vh - 60px);
    position: fixed !important;
    /* padding-top: 10px; */
    bottom: 0 !important;
    top: auto !important;
    z-index: 1000;
    background: linear-gradient(180deg, rgba(10, 34, 68, 0) 0%, rgba(10, 34, 68, 0) 76.16%, rgba(10, 34, 68, 0.25) 88.31%, rgba(10, 34, 68, 0.5) 100%);
  }


  .loading-view {
    position: fixed;
    z-index: 100;
    width: 100%;
    min-height: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    background: rgba(10, 34, 68, 0.5);

    img {
      vertical-align: center;
      margin: auto;
      width: 58px;
      height: 58px;
    }
  }

  .controller-wrapper{
    width: 100%;
    height: 100%;
    position: fixed;
    display: block;
    padding: 0 9%;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(10, 34, 68, 0.5);
    z-index: 100;

    .controller-header {
      padding-top: 22.5px;
      display: flex;
      justify-content: space-between;
      top: 0;
      width: 100%;
      height:auto;

      .button-group {
        display: flex;

        margin:0;

        .target-wrapper{
          display:flex;
          align-items:center;
          width:fit-content;
          height:40px;
        }

        button {
          height: 25px;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
          border-radius: 100px;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0 15px 0 0;
          display: flex;

        }

        .volume-div {
          width: 46px;
          height: 25px;
          background-color: #FB5B77;
          padding: 0;
          margin-right: 10px;
          border: none;
          border-radius: 100px;


          .control-button {

            height: 25px;
            border: none;
            padding: 4.5px 15px;
            border-radius: 100px;
            background-color: transparent;
            display: flex;
            vertical-align: middle;
            justify-content: space-between;
            color: #FFFFFF;

            .control-icon {
              margin:auto;

            }



          }



          input[type="range"] {
            -webkit-appearance: none;
            display: none;
            margin-right: 15px;
            width: 100px;
            height: 4px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            background-image: linear-gradient(#ffff, #ffff);
            background-size: 0 100%;
            background-repeat: no-repeat;
          }

          /* Input Thumb */
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: ew-resize;
            box-shadow: 0 0 2px 0 #555;
            transition: background .3s ease-in-out;
          }

          input[type="range"]::-moz-range-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: ew-resize;
            box-shadow: 0 0 2px 0 #555;
            transition: background .3s ease-in-out;
          }

          input[type="range"]::-ms-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: ew-resize;
            transition: background .3s ease-in-out;
          }

          input[type=range]::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          input[type=range]::-moz-range-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          input[type="range"]::-ms-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          .control-button:hover {
            border: none !important;
            background-color: transparent !important;
          }

          .control-button.active {
            background-color: transparent !important;
          }
        }

        .volume-div.unmute {
          background-color: #334B6D;
        }

        .control-button:hover {
          border: none !important;
          background-color: #334B6D!important;
        }

        .control-button:active{
          background-color: #657ba4 !important;
          transition: all 150ms ease-in-out;

        }

        .volume-div {
          padding: 0;
          text-align: center;
          margin-top: 0;
          background-color: #FB5B77;
          border-radius: 100px;
          width: 46px;
          height: 25px;
          text-align: center;
          transition: 200ms;

          .control-button.volume {
            width: 50px;
            height: 25px;
            background-color: transparent;
            margin: 0;

            .control-icon {
              width: 16px;
              height: 16px;
              order: 5;
              margin: auto 3px auto auto;

            }


          }
        }

        .volume-div.unmute {
          background-color: #334B6D;
        }


        .volume-div:hover {
          display: flex;
          //background-color: #657BA4 !important;
          width: 160px;
          z-index: 99;
          transition: 200ms;

          control-button.volume {
            margin-right: 0 !important;
            padding: 0;
            background-color: transparent !important;
          }


          .volume-input {
            display: block;
            margin: auto auto auto 0 !important;

          }
        }
        .volume-div.apple:hover{
          width: 46px;
        }
      }

      .button-group {
        display: flex;
        justify-content: center;
        position: relative;
        top: 0;
        margin:0;
        height: auto;
        width:auto;
        button {
          display: flex;
          height: 25px;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
          border-radius: 100px;
          flex: none;
          order: 0;
          flex-grow: 0;
          width: auto;
          margin: 0 15px 0 0;
          padding: 4.5px 10px;
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
          color:#FFFFFF;

          img {
            width: 16px;
            height: 16px;
            margin: auto 5px auto auto;
          }
        }
      }

    }

    .button-group {

      display: flex;
      justify-content: center;
      position: relative;
      top: calc(50% - 85px);
      width: 300px;
      height: auto;
      margin: auto;
      box-sizing: border-box;

      button, .control-button{
        padding: 8px 18px;
        width: 60px;
        height: 40px;
        background: #334B6D;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        flex: none;
        display: flex;
        order: 0;
        flex-grow: 0;
        margin: 0px 15px;
        border: none;
        transition: background 150ms ease-in-out;

        img {
          width: 25px;
          height: 25px;
          margin: auto;
        }
      }


      .buffering{
        height: 40px;
        width:60px;
        margin: 0px 15px;
        .spinner{
          width:40px!important;
          height: 40px!important;
        }
      }

      .control-button{
        visibility: visible;
      }
      .control-button.hide{
        visibility: hidden;
      }

      .control-button:active{
        background-color: #657ba4 !important;
        transition: all 150ms ease-in-out;
      }

      button:disabled,button[disabled]{
        opacity: 0.5;
        cursor: pointer;

      }

    }


    .controller-buttons {

      .info-wrapper.scrolling{
        opacity: 0.5;
        transition: opacity 150ms ease-in-out;
        -webkit-transition: opacity 150ms ease-in-out;
      }

      .info-wrapper{
        transition: opacity 150ms ease-in-out;
        -webkit-transition: opacity 150ms ease-in-out;
      }



      width: 82%;
      /* height: 70px; */
      bottom: 40px;
      position: absolute;
      font-family: "Ubuntu", sans-serif;

      .content-title, .channel-title {
        justify-content: left;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 21.33px;
        line-height: 21px;
        text-align: left;


        p {
          margin-bottom: 10px;
        }
      }
      .channel-title{

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 12px;
      }


    }

    .controller-buttons.ad-plays{
      bottom: 60px;
    }





    .timeline{
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      align-items: center;
      justify-content: center;
      .start{
        margin: 0 5px 0 0;
      }
      .end{margin: 0 0 0 5px}


      wrapper{
        margin-bottom:0;
      }

    }



  }

  .controller-wrapper.hide{
    display: none;
  }

  .program-guide-arrow{
    position: absolute;
    width:20px;
    display: flex;
    height: 40px;
    left:10px;
    margin:auto;
    top:calc(50% - 40px);
    bottom:calc(50% - 40px);
    justify-content: center;
    z-index: 2000;
    //transition: left 250ms ease-in-out;
    .arrow{
      width:40px;
      height: 40px;
      margin:auto;
      transform: scaleX(0.5);
      -webkit-transform:scaleX(0.5);
      -moz-transform: scaleX(0.5);

    }
  }

  //.program-guide-arrow.open{
  //  left:-30px;
  //  transition: left 250ms ease-in-out;
  //
  //}

  .landscape-program-guide{
    position: absolute;
    width: 100%;
    height: 100%;
    left: calc(100% - 45px);
    z-index: 150;
    display: flex;
    top: 0;
    background: transparent;
    transition: all 250ms ease-in-out;

    button {

      width:fit-content;
      height:40px;
      align-items:center;
      display: flex;
      background-color:  #334B6D;
      transition: all 0.2s ease-in-out;
      border:none;

    }

    .target-wrapper{
      background-color: transparent!important;
    }

    .control-button.browse-channel{
      width:fit-content;
      position: absolute;
      margin-top: 22.5px;
      right: 102.5%;
      transition: all 0.2s ease-in-out;
      box-shadow: none;
      font-family: 'Ubuntu', sans-serif;
      background-color: transparent!important;
      .target-wrapper{
        display: flex;
        height: 25px;
        background: #334B6D!important;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        border:none;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0;
        padding: 4.5px 10px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color:#FFFFFF;
      }

    }

    .control-button.browse-channel:focus {
        background:transparent;
      border:none;
    }

    .control-button.browse-channel.open{
      right: calc(100% - 15px);
      //transition: all 500ms ease-in-out;
    }

    .landscape-program-guide-mask{
      background: linear-gradient(180deg, rgba(10, 34, 68, 0.8), rgba(10, 34, 68, 0));
      transform: matrix(1, 0, 0, -1, 0, 0);
      position: absolute;
      bottom:0;
      width: 100%;
      height: 10px;
      z-index:10;
      left:0;
    }

    .program-guide-view{
      top:0;
      bottom:0;
      height: 100%;
      width:100%;
      padding-bottom: 0;
      background: transparent;
      opacity: 0.4;

      #programGuide{
        height: 100%;
        background: transparent;
        padding-top: 20px;
        padding-bottom: 20px;


      }

    }

    .arrow-transition-enter{
      transform: rotate(0);
      left:10px;
      -webkit-transition: all 500ms ease-in-out;
      transition: all 500ms ease-in-out;
    }
    .arrow-transition-enter-active{
      transform: rotate(-180deg);
      left:-15px;
      -webkit-transition: all 500ms ease-in-out;
      transition: all 500ms ease-in-out;
    }
    .arrow-transition-enter-done{
      left:-15px;
      transform: rotate(-180deg);

    }

    .arrow-transition-exit{
      transform: rotate(180deg);
      left:-15px;
      -webkit-transition: all 500ms ease-in-out;
      transition: all 500ms ease-in-out;
    }
    .arrow-transition-exit-active{
      transform: rotate(0deg);
      left:10px;;
      -webkit-transition: all 500ms ease-in-out ;
      transition: all 500ms ease-in-out;
    }
    .arrow-transition-exit-done{
      left:10px;;
      transform: rotate(0);

    }

  }

  .detailed-item-wrapper{
    position: fixed!important;
    width:100vw!important;
    height: 100vh!important;
    left:0%!important;
    z-index: 3000!important;
  }

  .landscape-program-guide.visible{
    display: flex;
  }
  .landscape-program-guide.hidden{
    display: none;
  }

  .landscape-program-guide.extended{
    left:56%;
    transition: all 250ms ease-in-out;
    z-index:2000;
    .program-guide-view{opacity: 1;}
  }



  //.ads-countdown{
  //  position: absolute;
  //  top:auto!important;
  //  bottom:20px;
  //  right:9%!important;
  //  left:auto!important;
  //  margin-top:10px;
  //}
  //
  .ad-label.mobile{
    display: flex;
    height: auto;
    width: auto;
    white-space: nowrap;
    margin-right: 16px;
    padding: 3px 0;
    overflow: hidden;
    strong{
      font-weight: bolder;
      overflow: hidden;
      white-space: break-spaces;
      height: 16px;
      margin-right: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    strong.long-text{
      margin-right:0;
    }
  }

  .ads-countdown{
    width:auto;
    //min-width:90px;
  }


  .ad-background{
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
    z-index: 10;
    background: linear-gradient(180deg, rgba(10, 34, 68, 0) 0%, rgba(10, 34, 68, 0) 76.16%, rgba(10, 34, 68, 0.25) 88.31%, rgba(10, 34, 68, 0.5) 100%);

  }

  //.control-button:hover {
  //  border: none !important;
  //  background-color: transparent!important;
  //}
  //
  .control-button:active{
    background-color: #657ba4 !important;
    transition: all 150ms ease-in-out;
  }

  .control-button.browse-channel:active{

      background-color: transparent!important;

  }

  .target-wrapper:active {
    background-color: transparent!important;
    button{
      background-color: #657ba4 !important;
      transition: background .3s ease-in-out;
    }
  }

  .target-wrapper.browse-channel:active {
    background-color: #657ba4 !important;
    transition: background .3s ease-in-out;
  }
}


.home-landscape-wrapper.tablet{
  .ad-part{
    left:0!important;
    padding:0 45px!important;
    bottom: 45px!important;

    .ad-label.inActive{
      left:45px!important;
    }
    .ads-countdown.tablet{
      inset: unset!important;
      right:60px!important;
      //bottom:45px;
    }
  }
  .ad-part.inactive{
    left:0!important;
    bottom:0!important;
    .ads-countdown.tablet{
      inset: unset!important;
      right:60px!important;
      bottom:65px!important;
    }
  }


  .controller-wrapper{
    padding:0 45px;
    .controller-header{
      .button-group{
        button{
          height: 30px;
          padding: 5px 10px;
          display: flex;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgb(0 0 0 / 15%);
          border-radius: 100px;
          flex: none;
          order: 0;
          flex-grow: 0;
          width: auto;
          margin: 0 15px 0 0;
          font-size: 16px;
          line-height: 18.38px;
          font-weight: 500;
          img{
            width:20px;
            height: 20px;
          }
        }
        .volume-div{
          height: 30px;
          .control-button{
            height:30px;
            img{
              width:25px;
              height: 25px;
            }
          }
        }
      }
    }
    .button-group{
      button,.control-button{
        width:80px;
        height: 55px;
        margin:0 25px ;
        img{
          width: 35px;
          height: 35px;
        }
      }
      .buffering{
        height: 55px;
        width:80px;
        margin: 0px 25px;
        .spinner{
          width:55px!important;
          height: 55px!important;
        }
      }
    }
    .controller-buttons{
      width:calc(100% - 90px);
      bottom: 45px;
      .info-wrapper{
        margin-bottom: 18px;
        .channel-title{
          margin-bottom:15px;
        }
        .start{
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
        }
      }
      .controller-buttons.ad-plays{

      }

    }

    .controller-buttons.ad-plays{
      margin-bottom:30px;
    }
  }
  .landscape-program-guide{
    left: calc(100% - 45px);
    .control-button.browse-channel{
      right:99%;
      .target-wrapper.browse-channel{
        height: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.5px;
        padding:6px 10px;
      }
    }
    .program-guide-arrow{
      left:0;
    }
  }
  .landscape-program-guide.extended{
    left:56%;
    transition: all 250ms ease-in-out;
    z-index:2000;
    .program-guide-view{opacity: 1;}
    .program-guide-arrow{
      left:-30px;
    }
  }

}


@media screen and (orientation: landscape){
  .home-landscape-wrapper.tablet{
    .program-guide-view{
      .detailed-item-wrapper{
        .selected-item-detail-page.tablet {
          bottom: 0;
          #detail-header{
            .target-wrapper{
              button{
                height: 25px;
              }
            }
          }
        }
      }
    }
  }
}


@keyframes fadedInRewind{
  0% {transform:rotate(0deg);
  -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  25% {transform:rotate(-17.5deg);
    -webkit-transform: rotate(-17.5deg);
    -moz-transform: rotate(-17.5deg);}
  75% {transform:rotate(-33.75deg);
    -webkit-transform: rotate(-33.75deg);
    -moz-transform: rotate(-33.75deg);}
  100% {transform:rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
  }
}


@keyframes fadedOutRewind{
  0% {transform:rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);}
  25% {transform:rotate(-33.75deg);
    -webkit-transform: rotate(-33.75deg);
    -moz-transform: rotate(-33.75deg);}
  75% {transform:rotate(-17.5deg);
    -webkit-transform: rotate(-17.5deg);
    -moz-transform: rotate(-17.5deg);}
  100% {transform:rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);}
}

@keyframes fadedInForward{
  0% {transform:rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);}
  25% {transform:rotate(17.5deg);
    -webkit-transform: rotate(17.5deg);
    -moz-transform: rotate(17.5deg);}
  75% {transform:rotate(33.75deg);
    -webkit-transform: rotate(33.75deg);
    -moz-transform: rotate(33.75deg);}
  100% {transform:rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);}
}


@keyframes fadedOutForward{
  0% {transform:rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);}
  25% {transform:rotate(33.75deg);
    -webkit-transform: rotate(33.75deg);
    -moz-transform: rotate(33.75deg);}
  75% {transform:rotate(17.5deg);
    -webkit-transform: rotate(17.5deg);
    -moz-transform: rotate(17.5deg);}
  100% {transform:rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);}
}




@keyframes fadedOutPlay{
  0% {transform:scale(0.8);
  -webkit-transform:scale(0.8) ;
    -moz-transform:scale(0.8) ;
  }
  25% {
    transform:scale(0.85);
    -webkit-transform:scale(0.85) ;
    -moz-transform:scale(0.85) ;

  }
  75% {
    transform:scale(0.95);
    -webkit-transform:scale(0.95) ;
    -moz-transform:scale(0.95) ;
  }
  100% {
    transform:scale(1);
    -webkit-transform:scale(1);
    -moz-transform:scale(1);
  }
}




@keyframes fadedInPlay{
  0% {    transform:scale(1);
    -webkit-transform:scale(1);
    -moz-transform:scale(1);}
  25% {    transform:scale(0.95);
    -webkit-transform:scale(0.95) ;
    -moz-transform:scale(0.95) ;}
  75% {  transform:scale(0.85);
    -webkit-transform:scale(0.85) ;
    -moz-transform:scale(0.85) ;}
  100% {transform:scale(0.8);
    -webkit-transform:scale(0.8) ;
    -moz-transform:scale(0.8) ;}
}

