

.program-guide-view{

  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: 0;
  bottom: 0;
  background-color: #0a2244;
  .program-guide-header{
    padding:0;
    width:auto;
    height: auto;
    margin:0;
  }

  .spinner-wrapper{
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    .spinner{
      margin: auto;
    }
  }

  .spinner-wrapper.loaded{
    opacity: 1;
  }

  .program-guide-wrapper.desktop{
    background-color: transparent;
    width:100vw;
    height: 90vh;
    top:9.5%;
    padding-left: 7.5%;
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);

  }
  .detailed-item-wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    z-index: 200;
    backdrop-filter: blur(10px);
    background: rgba(3, 12, 26, 0.85);
    .selected-item-detail-page{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 4500;
      overflow-y: scroll;
      background-color: rgba(10, 34, 68, 1);

    }
    .selected-item-detail-page.desktop{
      border-radius: 15px;
      width: 73.15vh;
      height: 73.15vh;
      margin:11.425vh calc((100vw - 73.5vh)/2);
      box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.5);
      .content-detail-wrapper.desktop{
        height: 100%;
      }
    }
    .selected-item-detail-page.tablet{
      bottom:60px;
    }


    .selected-item-detail-page::-webkit-scrollbar{
      display: none;
    }
  }

}

.program-guide-view.mobile.tablet{
  top: calc(56.25vw + 15px)
}

.program-guide-view.mobile.loaded{
  .spinner{
    opacity: 1;

  }
}

.program-guide-view.mobile{
  position: fixed;
  top: 56.25vw;
  height: calc(100% - 56.25vw - 70px);
  overflow: hidden;
  bottom: 70px;

.spinner{
  opacity: 0;
  margin-top:100px;
}

.program-guide-wrapper{
  height: 100%;
  width: 100%;
  padding-left: 25px;
  padding-bottom:0;
  position: relative;
  background-color: #0a2244;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    scroll-behavior: smooth;
  }

  .program-guide-wrapper::-webkit-scrollbar{
    display: none;
  }

}



.program-guide-view.desktop{
  z-index: 1000;
}

.program-guide-view.detailed{
  z-index:5002;
}
