/* App Size */
$app-width: 1280px !default;
$app-height: 720px !default;
$animated: true !default;

//noinspection ALL
:export {
  constAppWidth: $app-width;
  constAppHeight: $app-height;
}
