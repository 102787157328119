.more-detail-item-wrapper{
  position: fixed;
  z-index: 5004;
  height: 100vh;
  width:100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: 0; /* for Firefox */

  .more-detail-item{
    width:calc(100% - 30px);
    min-height: 320px;
    background: #0a2244;
    border-radius: 20px;
    position: relative;
    .target-wrapper.close{
      position: absolute;
      width:40px;
      opacity: 1;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index:10000;
      right:15px;
      top:0px;
      left:auto;
      button{
        border:none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4.5px 10px;
        height: 25px;
        background: #2C3A4B;
        box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2);
        border-radius: 100px;
        img{
          width:13.35px;
          height:13.35px;
          margin:auto;
          color:white;

        }
      }
    }

    .target-wrapper.close.desktop{
      left:60px;
      right:auto;
      top:15px;

      button{
        color:white;
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.5px;
        height: 30px;
        img{ margin-right:10px;
        width:20px;
          height: 20px;
        }
      }

    }
    // position: absolute;


    .more-detail-background{
      height: 187px;
      background-size: cover;
      background-position: center;
      border-radius: 25px;

        .content-info-wrapper{
          position: relative;
          background: linear-gradient(0deg, #0a2244 0%, rgba(32, 44, 60, 0.4) 100%);
          height:100%;
          padding:15px;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          .content-detail-title.more-detail{
            position:absolute;
            bottom:0;
            .content-detail-collection-title{
              font-family: 'Ubuntu';
              font-style: normal;
              font-weight: 700;
              font-size: 21.33px;
              line-height: 21px;
              display: flex;
              align-items: flex-end;
              color: #FFFFFF;
              margin-bottom:10px;
            }
            .content-detail-collection-title.subtitle{
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
            }
            .content-detail-button-group{
              padding:7.5px 0;
              .target-wrapper{
                button{
                  background: #586C85;
                  img{
                    width:12px;
                    height: 16px;
                    margin-right:7px;
                  }
                }
              }
            }

          }

        }


    }
    .content-more-info{
      padding:15px 15px;
      width:100%;
      .content-description.more-detail{
        padding:15px 0;
        p.mobile{
          overflow: unset;
          display: block;
          -webkit-line-clamp:unset;
        }
      }
    }
  }


}

.more-detail-item-wrapper::-webkit-scrollbar{
  display: none;
}

.content-item{
  width: 100%;
  height: 100%;
  position: absolute;
}

.loading-item{
  overflow-y: hidden;
}

.content-detail-wrapper{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  z-index:2500;
  position: fixed;
  height: 100%;
  width: 100%;
  @media (min-width: 1024px) {
    position: fixed;
    width: 73.15vh;
    height: 73vh !important;
  }
  @media (min-width: 1025px) {
    border-radius: 15px;
  }
  overflow-y: scroll;
  background: #0e2345;
  .content-header{
    width: 100%;
    position: fixed;
    height: 45px;
    align-items: center;
    top: 0;
    display: flex;
    padding: 0 15px;
    justify-content: space-between;
    z-index: 20000;
    .target-wrapper{
      width: fit-content;
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin:auto 0;
    }
    button{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4.5px 10px;
      //width: 70px;
      height: 25px;
      background: #334B6D;
      transition: background-color 150ms ease-in-out;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
      border-radius: 100px;
      border:none;
      flex: none;
      order: 0;
      flex-grow: 0;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.5px;
      color: #FFFFFF;
      img{
        width:20px;
        height:20px ;
        margin-right: 5px;
      }
      .back.mobile{
        width:16px;
        height:16px ;
      }

    }

    .logo{
      width:38.77px;
      height: 15px;
    }
  }
  .content-background-item.tablet {
    background-size: cover;
    .content-info-wrapper{
      .content-detail-title {
        position: absolute;
        padding: 0 30px;

      }

      .content-info-progress{
        padding:0 30px;
        font-size: 16px;
        line-height: 18px;

        .progress.tablet{
          width:464px;
          height:5px;
        }
        .progress.tablet>*{
          height:5px;
        }
      }

    }




  }

  .content-background-item.tablet.portrait{
    height: 41.17%;
    .content-info-wrapper{
      height: 41.25vh;
       .content-detail-title{
        position: absolute;
        padding: 0 30px;
         font-size: 28.43px;

      }
    }
  }

  .content-background-item.tablet.landscape{
    height: 54.58%;
    .content-info-wrapper{
      height: 54.58%;
      .content-detail-title{
        position: absolute;
        padding: 0 30px;
      }
    }
  }


  .content-more-info.tablet, .content-more-info.tablet.collection {
    width:620px;
    padding: 2px 3.79% 0 30px;
    .content-description{
      font-size: 16px;
      line-height: 18px;
    }

    .target-wrapper{
      #more-description{
        height: 30px;
      }
    }

  }

  .content-background-item{
    height: 33.912vh;
    width:100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .content-info-wrapper{
      position: absolute;
      width:100%;
      height: 33.992vh;

     background: linear-gradient(0deg, #0a2244 0%, rgba(19, 30, 45, 0.4) 100%);

      .content-detail-collection-title{
        margin:10px 0;
      }


      //.blur{
      //  position: absolute;
      //  bottom: 0;
      //  width:100%;
      //  height: 10%;
      //  background: linear-gradient(180deg, rgba(10, 34, 68, 0.85), rgba(10, 34, 68, 0));
      //  transform: matrix(1, 0, 0, -1, 0, 0);
      //}
      .content-detail-title{
        position: absolute;
        padding: 0 15px;
        bottom:24px;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 21.33px;
        line-height: 25px;
        /* identical to box height, or 100% */

        display: flex;
        align-items: flex-end;

        color: #FFFFFF;
      }
      .season-amount{
        margin-bottom:15px;
        visibility: visible;
        transition: visibility 0.2s, opacity 0.2s linear;
        opacity: 1;
      }
      .season-amount.scrolled{
        visibility: hidden;
        margin-bottom: -15px;
        height: 0;
        transition: visibility 0.5s, opacity 0.5s, height 0.2s, margin-bottom 0.2s linear;
        opacity: 0;
      }
      .content-detail-title.collection{
        display: block!important;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        width:100%;
        //height: 58.25%;
        //top:22.42%;
        background: linear-gradient(0, #0a2244 27%, rgba(19, 33, 45, 0.4) 100%);
        .content-detail-button-group{
          padding:0px;
          visibility: visible;
          transition: visibility 0.2s, opacity 0.2s linear;
          opacity: 1;
        }

        .content-detail-button-group.scrolled{
          visibility: hidden;
          transition: visibility 0.2s, opacity 0.2s, height 0.2s linear;
          opacity: 0;
          height: 0;
        }

        .content-detail-collection-title{
          font-weight: bold;
          font-size: 21.33px;
          line-height: 21px;
          margin-bottom:15px;
          position: sticky;
          z-index: 100;
        }

      }
      .content-info-progress{
        position: absolute;
        bottom:0;
        width: 100%;
        padding:0 15px;
        display: flex;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        .content-info-interval{
          margin-right: 1.35vw;
          min-width: 75px;
        }
        .progress{
          width:73vw;
          height: 3px;
          background-color: rgba(255, 255, 255, 0.25);
          margin: auto 0;
          border-radius: 100px;
          .progressed{
            background-color: #FFFFFF;
            height: 3px;
          }
        }
      }
    }
  }

  .content-background-item.collection{
    height: 58.25vh;
    -webkit-transition:  all 250ms ease-in-out;
    transition: all 250ms ease-in-out;

    display: flex;
    justify-content: center;
    position: sticky;
    top: -44vh;
    z-index: 10;
    @media (max-height: 800px) {
      top: -42vh;
    }
    @media (max-height: 700px) {
      top: -39vh;
    }
    .background-fade-effect{
      position: absolute;
      height: 100%;
      width: 100%;
      background: #ffffff00;
      visibility: visible;
    }
    .background-fade-effect.scrolled{
      position: absolute;
      visibility: visible;
      height: 100%;
      width: 100%;
      background: #0e2345;
      transition: background 1s, visibility 1s linear;
      z-index: 10;
    }
    .background-fade-effect.bg{
      background: #0e2345;
      transition: background 0.1s, visibility 0.1s linear;
    }
    .watermark{
      position: sticky;
      width:72px;
      height: 30px;
      top:10px;
      z-index: 999;
      visibility: visible;
      transition: visibility 0.2s, opacity 0.2s linear;
      opacity: 1;
    }
    .watermark.scrolled{
      visibility: hidden;
      transition: visibility 0.2s, opacity 0.2s linear;
      opacity: 0;
    }
  .content-info-wrapper{
    height: 58.25vh;
    background: linear-gradient(0deg, #0a2244 40%, rgba(19, 30, 45, 0.4) 100%);

    .content-detail-button-group{
      padding:0 0 ;
      .target-wrapper{
        button{
          img{
            width:13.5px;
            height: 18px;
            margin-right:10px;
          }
        }
      }
    }
    .content-more-info.collection{
      padding-left:0;
      .line{
        margin:10px auto;
      }
      .content-description{
        color: #D9D9D9;
        visibility: visible;
        transition: none;
        opacity: 1;
      }
      .content-description.scrolled{
        color: #D9D9D9;
        visibility: hidden;
        transition: none;
        opacity: 0;
        height: 0;
      }
      .content-detail-button-group{
        width:100%;
        position: sticky;
        z-index: 999;
        .button-group{
          .swiper-season-button{
            min-width: 90px;
            width: max-content;
            .target-wrapper{
              button{
                font-size: 16px;
                line-height: 18px;
                letter-spacing: 0.5px;
                height: 30px;
              }
            };
          }
        }
      }
    }
    .content-detail-title.collection{
      background: linear-gradient(0, #0a2244 0, rgba(19, 33, 45, 0) 100%);
      bottom: 0;
      box-shadow: none;
      .line{
        position: sticky;
        z-index: 99;
        height: 2px;
        margin:10px auto 15px auto;
        background-color:
          rgba(255, 255, 255, 0.1)
      }
      .line.scrolled{
        margin:10px auto 4px auto;
        @media (max-width: 600px) {
          margin:5px auto 4px auto;
        }
        @media (min-width: 1365px) {
          margin:15px auto 4px auto;
        }
      }
    }
    .content-detail-title.collection.scrolled{
      box-shadow: 0 6px 5px -1px rgba(10,34,68,0.83);

    }
  }
    .content-info-wrapper.tablet{
      height: 33.992vh;
    }
  }
  .content-background-item.collection.tablet{
    height: 33.992vh;
  }
  @media (min-width: 768px) {
    .content-background-item.collection.scrolled{
      height: 18vh;
      position:fixed;
      -webkit-transition:  all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
      z-index:2;
      .content-info-wrapper{
        background: #0a2244;
        height: 100%;
      }
    }
  };

  @media (max-width: 767px) {
    .content-background-item.collection.scrolled-mobile{
      background-image: unset !important;
      height: 18vh;
      position:fixed;
      -webkit-transition:  all 0.2s linear;
      transition: all 0.2s linear;
      z-index:2;
      .content-info-wrapper{
        background: #0a2244;
        height: 100%;
      }
      @media (max-width: 767px) {
        .content-detail-title{
          margin-top: 50px;
          background: #0a2244;
          position: absolute;
        }
      }
    }
  }
  .content-background-item.collection.playlist{
      top: -27vh;
      z-index: 10;
      @media (max-height: 700px) {
        top: -25vh;
      }
      @media (max-width: 1024px) and (min-width: 425px) {
        top: -27vh;
      }
      height: 36.94vh;
      .content-info-wrapper{
        height: 36.94vh;
        -webkit-transition: height 0.2s linear;
        transition: height 0.2s linear;
      }
  }

  .content-background-item.collection.playlist.tablet{
    height: 30.94vh;
    .content-info-wrapper.tablet{
      height: 30.94vh;
    }
  }

  .content-detail-button-group.tablet{
    padding:7.5px 30px;
    margin:15px auto;
    button{
      height:40px;

    }
  }
  .content-detail-button-group{
    display: flex;
    //margin-top: 2.67vw;
    flex-wrap:wrap;
    padding:7.5px 15px;

    .target-wrapper{
      display: flex;
      align-items:center;
      width:fit-content;
      min-width: 40px;
      height: 40px;
    }
    button{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px  15px;
      height: 30px;
      color:#FFFFFF;
      border:none;
      background: #334B6D;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
      border-radius: 100px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin:auto 15px auto auto;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size:18.38px;
      line-height: 18px;
      letter-spacing: 0.025em;
      transition: background 150ms ease-in-out;
    }
    button:hover{
      background:  #8B9CBB;
      transition: background 150ms ease-in-out;
    }

  }
  .content-detail-button-group.season::-webkit-scrollbar{
    display: none;
  }

  .content-detail-button-group.season {
    flex-wrap: unset;
    width: 74vh;
    overflow-x: scroll;
    padding: 0 30px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: 0; /* for Firefox */
    .swiper{
      margin-left: 0!important;



    .swiper-season-button {
      width: 120px;
      height: 50px;

      .target-wrapper {
        min-width: max-content;
        margin: 10px 0 0 0;
        padding-bottom: unset !important;
        justify-content: normal;

        button {
          margin: auto 10px auto 0;
          background: #2C3A4B !important;
          box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2);

        }

        button.active {
          border: 1.5px solid #607ABF;
        }

        content-detail-button-group:nth-child(5) {
          padding-bottom: unset !important;
        }
      }
    }
  }

  }
  .content-more-info{
    padding:0 15px;
    .target-wrapper{
      width: fit-content;
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top:10px;
      margin-bottom: -7.5px;
    }
    button{
      display:flex  ;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4.5px 10px;
      height: 25px;
      color:#FFFFFF;
      border:none;
      background: #27364B;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
      border-radius: 100px;
      flex: none;
      order: 0;
      flex-grow: 0;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.5px;
      transition: background-color 150ms ease-in-out;
      img{
        width:16px;
        height:16px ;
        margin-right: 5px;

      }
    }
    .content-description{
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      height: auto;
      transition: height 0.5s;
      /* or 129% */

      letter-spacing: 0.5px;

      color: #FFFFFF;
      transition:all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        transition:all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;
        margin:0;
      }
      p.collection{
        -webkit-line-clamp: 3;
      }


      p.mobile{
        -webkit-line-clamp: 9;
      }
    }

    .content-description.more-detail{
      color:#D9D9D9;
      p.duration{
       color:white;
        margin-top: -20px;
        margin-bottom: 22px;
      }
    }

    .content-description.masked{
      mask:linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 76.01%, rgba(255, 255, 255, 0) 100%);
    }
  }
  .content-more-info.collection{
    padding:2px 3.79% 0 3.79%;

  }

  .content-related{
    width:100%;
    background: #0a2244;
    @media (max-width: 435px) {
      margin-bottom: 12vh;
    }
      //padding:0 15px 90px ;
    p{
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: unset;
      color: #FFFFFF;
      //text-transform: uppercase;
      margin-bottom: 0;
      margin-left: 15px;
    }

    .item-subtitle{
      p{
        margin-left:0;
      }
    }
    .related-slider-title{
      letter-spacing: 1px;
      margin:46px auto 0 15px;
      text-transform: uppercase;

    }
    .on-demand-wrapper{
      margin-top:0;
      .items{
        .swiper-wrapper{
          swiper-slide{
            padding:2px;
          }
        }
      }
    }
  }
  .content-related.tablet{
    padding:40px 0  90px 30px;
    p{
      font-size: 21.33px;
      line-height: 25px;
    }
    .related-slider-title{
      letter-spacing: 1px;
      margin-left:0;
    }

    .on-demand-wrapper.desktop{
      .slider-wrapper{
        .swiper{
          padding:0;
          height: auto;
          //.swiper-slide{
          //  padding:2px;
          //}
        }
      }
    }
  }

  .content-related.collection.tablet{
    padding:15px 0 3% 30px;
  }

  @media (max-width: 768px) {
    .content-related.collection {
      margin-top: 0;
      padding: 0 15px;

      .on-demand-wrapper {
        .items {
          height: 116px;
        }
      }
    }
    .content-related.collection.scrolled {
      margin-top: 22vh;
      transition: margin-top 200ms ease-in-out;
      -webkit-transition: margin-top 200ms ease-in-out;
    }
    .content-related.collection.playList {
      margin-top: 0;
    }
    .content-related.collection.scrolled.playList {
      margin-top: -20vh;
      transition: margin-top 200ms ease-in-out;
      -webkit-transition: margin-top 200ms ease-in-out;
    }
  }
  .playlist-details-wrapper{
    min-height: 100vh;
    margin-top:0;
    :first-child{
      margin-top:0;
    }
  }
  @media (min-width: 768px) {
    .playlist-details-wrapper.scrolled {
      margin-top: 19vh;
      transition: margin-top 200ms ease-in-out;
      -webkit-transition: margin-top 200ms ease-in-out;
    }
  }

  @media (max-width: 767px) {
    .playlist-details-wrapper.mobile-scrolled{
      margin-top:22vh;
    }
  }

  .content-detail-wrapper.loading{
    display: flex;
    width: 100vw;
    height:100vh;
    min-height: 73.15vh;
    justify-content: center;
    align-items: center;
    padding:0;
    background: #0a2244;

    .spinner{
      margin: calc(50vh - 30px) auto;
      height: auto;
    }
  }

}
.content-detail-wrapper::-webkit-scrollbar {
  display: none;
}
.content-detail-wrapper.single-item{
  background: #0a2244;
}
.content-detail-wrapper.more-item{
  background: none;
}
.content-detail-wrapper.desktop.single-item{
  min-height: 73vh;
}
.loading-more{
  margin-bottom: 35px;
  border-radius: 25px;
  z-index: 20000;
}
.content-detail-wrapper.desktop{

  .content-detail-wrapper.loading{
    display: flex;
    width: 73.15vh;
    height: 73.15vh;
    min-height: 73.15vh;
    justify-content: center;
    align-items: center;
    padding:0;

    .spinner{
      margin: calc(38.5vh - 30px) auto;
      height: auto;
    }
  }
  .more-detail-item-wrapper.desktop{
    .more-detail-item{
      width:73.15vh;
      height: 73.15vh;
      margin-bottom: 35px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;

      .more-detail-background{
        height: 40%;
        .content-info-wrapper{
          .content-detail-title.more-detail{
            padding:0 15px;
            .content-detail-collection-title{
              font-size:28.43px;
              line-height: 28.43px;
              margin-bottom:15px;
            }
            .content-detail-collection-title.subtitle{
              font-size:21.33px;
              line-height: 21.33px;
            }
            .content-detail-button-group{
              .target-wrapper{
                button{
                  img{
                    width:15px;
                    height: 20px;
                    margin-right:12.5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .content-detail-button-group{
    margin-top: 0;
    padding:0 30px;
    button{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 11px 20px;
      width: auto;
      height: 40px;
      background: #334B6D;
      transition: background-color 150ms ease-in-out;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
      border-radius: 100px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 15px 0 0;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.025em;
    }

    .watch-button:nth-child(1){
      margin-left: 0;
    }
  }
  .content-header{
    width: 73.15vh;
    height: 90px;
    display: flex;
    position: fixed;
    top: 11.425vh;
    padding:0;
    justify-content: space-between;
    z-index: 20;


    .target-wrapper{
      margin:auto auto auto 30px;
      @media (min-width: 1365px) {
        margin: 30px;
      }
      @media (max-width: 1365px) and (min-width: 1279px){
        margin: 35px auto auto 30px;
      }
    }

    button{
      height: 30px;
      width: auto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      padding: 5px 10px;
      letter-spacing: 0.5px;
      img{
        margin-right:10px;
      }

    }
  }
  .content-background-item{
    position: relative;
    height:35.15vh;
    max-width:73.15vh;
    -webkit-transition: height 0.2s linear,background 0.2s linear;
    transition:height 0.2s linear,background 0.2s linear;

    .background-fade-effect{
      position: absolute;
      height: 100%;
      width: 100%;
      background: #ffffff00;
      visibility: visible;
    }
    .background-fade-effect.scrolled{
      position: absolute;
      height: 100%;
      visibility: visible;
      width: 100%;
      background: #0e2345;
      transition: background 0.2s, visibility 0.2s linear;
      z-index: 10;
    }
    .background-fade-effect.bg{
      background-color: #0e2345;
      transition: background 0.2s, visibility 0.2s linear;
    }
    .watermark{
      position: sticky;
      margin: 0;
      width:106px;
      height: 44px;
      top:23px;
      margin-top: 20px;
      visibility: visible;
      transition: visibility 0.2s, opacity 0.2s linear;
      opacity: 1;
    }
    .watermark.scrolled{
      visibility: hidden;
      transition: visibility 0.2s, opacity 0.2s linear;
      opacity: 0;
    }
  }

  .content-background-item.collection{
    position: sticky;
    top: -27vh;
    z-index: 10;
    @media (max-height: 1079px) and (min-height: 899px) {
      top: -25vh;
    }
    @media (max-height: 899px) and (min-height: 800px) {
      top: -23vh;
    }
    @media (max-height: 799px) {
      top: -21.5vh;
    }
    @media (min-height: 1599px) {
      top: -35vh;
    }
    @media (min-width: 1365px) and  (max-width: 1367px){
      top: -20vh;
    }
    @media (max-width: 1601px) and (min-width: 1599px){
      top: -23vh;
    }
    @media (max-width: 1281px) and (min-width: 1279px){
      top: -18.5vh;
    }
    @media (max-width: 1599px) and (min-width: 1499px){
      top: -18.5vh;
    }
  }

  .content-background-item.collection.playlist{
    position: sticky;
    top: -19vh;
    z-index: 10;
    @media (max-height: 899px) {
      top: -15vh;
    }
    @media (max-height: 1049px) and (min-height: 899px) {
      top: -17vh;
    }
    @media (min-width: 1900px) {
      top: -17vh;
    }
    @media (max-width: 1900px) and (min-width: 1400px){
      top: -14.5vh;
    }
    @media (max-width: 1400px) and (min-width: 1300px){
      top: -13vh;
    }
    @media (max-width: 1300px) and (min-width: 1200px){
      top: -12vh;
    }
  }

  .content-background-item.collection.scrolled{
    height: 17vh;
    width: 73.15vh;
    display: flex;
    top: 11.425vh;
    border-radius: 25px;
    padding: 0;
    justify-content: center;
    background-image: unset!important;
    transition: height 0.2s linear !important;

    @media (max-height: 799px) {
      height: 23vh;
    }

    .content-info-wrapper{
      border-radius: 20px 20px 0 0;

    }
  }

  .content-background-item.hide{
    display: none;
  }

  .content-background-item.collection.playlist{
    height:30.95vh ;
    justify-content: center;

    .content-info-wrapper.desktop{
      height: 30.95vh;
      -webkit-transition:  all 0.2s linear;
      transition: all 0.2s linear;
    }
  }

  .content-background-item.collection.playlist.scrolled{
    height: 13.28vh;
    justify-content: center;
    background-image: unset!important;

    .content-info-wrapper.desktop{
      height: 13.28vh;
      -webkit-transition:  all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }

  }




  .content-background-item.collection{
    .content-info-wrapper.desktop {
      height:46.38vh;
    }
  }


  .content-info-wrapper{
    height:35.15vh;
    max-width: 73.15vh;
    .content-detail-title{
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 28.43px;
      line-height: 33px;
      /* identical to box height, or 75% */

      display: flex;
      align-items: flex-end;
      padding:15px 30px;
      color: #FFFFFF;
    }
    .content-detail-title.collection{
      display: block!important;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 21.32px;
      line-height: 16px;
      bottom:0;
      padding:0 30px;
      width: 100%;
      .line{
        padding-bottom:1px!important;
      }
      .content-detail-collection-title{
        font-weight: bold;
        font-size: 28.43px;
        line-height: 28px;
        margin-bottom: 16px;
        position: relative;
        z-index: 999;
        @media (min-height: 1079px) {
          margin-bottom: 25px;
        }
        @media (min-width: 1365px) and  (max-width: 1367px){
          margin-bottom: 20px;
        }
        @media (max-width: 1601px) and (min-width: 1599px){
          margin-bottom: 25px;
        }
        @media (max-width: 1281px) and (min-width: 1279px){
          margin-bottom: 25px;
        }
        @media (max-width: 1599px) and (min-width: 1499px){
          margin-bottom: 23px;
        }
      }
      .season-amount{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 500;
        font-size: 21.33px;
        line-height: 25px;
        margin-bottom: 15px;
        color: #D9D9D9;
        visibility: visible;
        transition: visibility 0.2s, opacity 0.2s linear;
        opacity: 1;
      }
      .season-amount.scrolled{
        visibility: hidden;
        margin-bottom: -15px;
        height: 0;
        transition: visibility 0.5s, opacity 0.5s, height 0.2s, margin-bottom 0.2s linear;
        opacity: 0;
      }

      .content-detail-button-group{
        padding:0;
        visibility: visible;
        transition: visibility 0.2s, opacity 0.2s linear;
        opacity: 1;
        .target-wrapper{
          button{
            background-color: #586c85;
            img{
              width:15px;
              height: 20px;
              margin-right:12.5px;
            }
          }
        }
      }

      .content-detail-button-group.scrolled{
        visibility: hidden;
        transition: visibility 0.2s, opacity 0.2s, height 0.2s linear;
        opacity: 0;
        height: 0;
      }

      .content-more-info{
        padding:10px 0 0 0  ;
        .content-description{
          p.collection{
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            /* or 112% */

            letter-spacing: 0.025em;

            /* Core/Grey */

            color: #D9D9D9;
          }
        }

          .content-detail-button-group.season{
            .button-group{
              .swiper-wrapper {
                .swiper-season-button {
                  width: auto !important;

                  .target-wrapper {
                    button {
                      padding: 11px 20px;
                      height: 40px ;
                      //margin-right: 10px !important;

                    }
                  }
                }
              }
            }
          }


      }


    }
    .content-info-progress{
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #FFFFFF;
      padding:2% 30px;
      .content-info-interval{
        min-width: 100px;
        margin-right:0.75%;
      }
      .progress{
        width: 100%;
      }
    }
  }


  .content-detail-title.more-detail{
    display: block;
    .content-detail-collection-title{
      margin:15px auto;
    }
    .content-detail-button-group{
      padding: 0;
    }
  }
  .content-info{
    padding:1.895% 3.79%;
    .content-description{
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      /* or 112% */
      height: auto;

      letter-spacing: 0.025em;
      transition:all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;
      p {

        -webkit-line-clamp: 7;
        transition:all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;


      }
      p.collection{
        -webkit-line-clamp: 3;

      }
    }

    img{
      width:20px;
      height: 20px;
    }
  }

  .content-more-info{
    padding: 15px  30px;

    .content-description{
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;

    }
  }
  .content-more-info{
    padding:15px 30px 0 30px;
    .target-wrapper.more{
      margin-bottom:-5px;
        #more-description{
          height: 30px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 5px 10px;
          font-size: 16px;
          font-weight: 700;
          line-height: 18px;
          background:#27364B;;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
          border-radius: 100px;
          img{
            height: 20px;
            width: 20px;
            margin-right:10px;
          }
        }
      }
    }

  .content-related{
    padding:4.5% 0 3% 3.79%;
    p{
      font-size: 21.33px;
      line-height: 24.48px;
      letter-spacing: unset;

    }
    .related-slider-title{
      letter-spacing: 1px;
      margin-left:0;
    }
    .on-demand-wrapper.desktop{
      .slider-row{
        .items{
          width:100%;
          margin-right:0;

        }
      }
    }
  }

  .content-related{
    padding:45px 0 0 0%;
    .on-demand-wrapper.desktop{
      margin-top:0;
      .slider-wrapper{
        .swiper,.items {
          padding: 0 0 0 30px;
          height: auto;
          .swiper-wrapper{
            margin:5px auto;
            .swiper-slide{
              height: fit-content;
              margin-right: 15px!important;
            }
          }
        }
      }

    }
    .related-slider-title{
      margin:5px auto 0 30px;
    }
  }
  .content-related.collection.playList{
    margin-top:0;
  }

  .content-related.collection{
    padding:0%;
     margin-top: 12vh;
    transition: none;
    .playlist-details-wrapper{
      padding: 0;
      margin: auto 30px;
      min-height: 0;
      transition:margin-top 200ms ease-in-out;
      -webkit-transition:margin-top 200ms ease-in-out;
      :first-child{
        margin-top: unset;
      }
    }

    .playlist-details-wrapper.scrolled{
      margin:38vh 30px auto 30px;
      transition:margin-top 200ms ease-in-out;
      -webkit-transition:margin-top 200ms ease-in-out;
    }
    .on-demand-wrapper.desktop{
      .slider-wrapper{
        .swiper,.items {
          .swiper-wrapper{
            margin:5px auto 0;
          }
        }
      }

    }
  }
  .content-related.collection.scrolled{
    margin-top: -8vh;
    transition: margin-top 0.2s linear;
    @media (max-height: 799px) {
      transition: margin-top 0.2s linear;
      margin-top: 40vh;
    }
  }
  .content-related.collection.scrolled-playlist{
    margin-top: -8vh;
    transition: margin-top 0.2s linear;
  }
  //.content-related.collection.playlist{
  //  margin-top: 150px;
  //}

  button:hover{
    background:  #8B9CBB;
    transition: background 150ms ease-in-out;
  }

  :nth-child(5){
    padding-bottom:30px;
  }



  .content-background-item.collection.scrolled{
    margin-top: -1px;
    -webkit-transition:  all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    .content-info-wrapper.desktop {
      height:100%;
      top: 28px;
      -webkit-transition: top 0.4s linear;
      transition: top 0.4s linear;
    }
    .content-detail-title{
      margin-top: 85px;
      background: #0a2244;
      -webkit-transition:  height 1s linear;
      transition: height 1s linear;
    }
  }
}






.detail-info-transition-enter {
  opacity: 0;
  -webkit-transition:all 250ms ease-in-out;
  transition:  all 250ms ease-in-out;
}
.detail-info-transition-enter-active {
  opacity: 1;
  -webkit-transition:all 250ms ease-in-out;
  transition:  all 250ms ease-in-out;
}

.detail-info-transition-exit {
  opacity: 1;
  //-webkit-transition:all 1000ms ease-in-out;
  //transition:  all 1000ms ease-in-out;
}
.detail-info-transition-exit-active {
  opacity: 0;
  -webkit-transition:all 250ms ease-in-out;
  transition:  all 250ms ease-in-out;
}
