@import '../../assets/styles/globals';

#playerView {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 98;
  .spinner {
    position: absolute;
    top: rescaleVertical(205px);
    left: rescaleHorizontal(555px);
  }

  .error-msg {
    position: relative;
    left: 0;
    top: 0;
    width: rescaleHorizontal(800px);
    height: 100%;

    margin: rescaleVertical(450px) auto 0;

    font-size: toRem(28);
    font-weight: 300;
    color: #ffffff;

    text-align: center;

    opacity: 0.8;
    z-index: 999;
  }

  .viewWrapper {
    @include animated;

    .content {
      position: relative;

      .wrapper-slider {
        position: absolute;
        top: rescaleVertical(8px * 10);
        left: rescaleHorizontal(8px * 3);

        .selectors {
          margin-bottom: rescaleVertical(8 * 4px);
        }
      }
    }
  }
}

@media screen and (orientation: portrait) {
  #playerView{
    width:100vw;
    height: 56.25vw;
  }

}

:export {
  horizontalScale: rescaleHorizontal(1px) / 1px;
  verticalScale: rescaleVertical(1px) / 1px;
}
