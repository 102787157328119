
.playlist-detail-wrapper{
  width:100%;
 margin:15px auto 31px auto;
  padding-bottom: 0!important;
  // height:146px;
  .playlist-item-info{
    display: flex;
    width:100%;
    min-height: 85px;
    .playlist-item-image{
      display: flex;
      width:146px;
      height: 85px;
      background-size: cover;
      justify-content: center;
      align-items: center;
      position: absolute;
      img{
        width:30px;
        height: 40px;
      }
      .playlist-item-filter{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
      }
    }
    .playlist-item-info-detail{
        display: block;
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 500;
        font-size: 21.33px;
        line-height: 25px;
        color: #FFFFFF;
        mix-blend-mode: normal;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      margin-left: 155px;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;


      .info-detail-duration{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        margin: 5px auto 8px auto;

        /* Core/Grey */

        color: #D9D9D9;

      }
      button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 10px;
        gap: 5px;
        width: 62px;
        height: 30px;
        background: #2C3A4B;
        box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2);
        border-radius: 100px;
        flex: none;
        order: 2;
        border:none;
        flex-grow: 0;
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: #FFFFFF;
      }
    }
  }
.playlist-item-description{
  margin-top:10px;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* or 112% */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    letter-spacing: 0.025em;
    // text-transform: lowercase;
    /* Core/Grey */
  margin-left:0;
    color: #D9D9D9;

}
}

.playlist-detail-wrapper.mobile{
  margin:0 auto 31px;

  .playlist-item-info{
    min-height: 70px;
    .playlist-item-image{
      width:120px;
      height:70px;
      margin-right:10px;
      img{
        width:22.5px;
        height: 30px;
      }

    }
    .playlist-item-info-detail{
      margin-left:130px;
      font-size:16px;
      line-height:16px;
      .info-detail-duration{
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
      button.content-more-info{
        height: 25px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .playlist-item-description{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

}
