
.tab-bar-wrapper.hidden{
  display: none;}

.tab-bar-wrapper{
  position: fixed;
  height:70px;
  bottom:0;
  left: 0;
  width:100%;
  background-color: transparent;
  z-index: 5003;
  display: block;
  .tab-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    position: absolute;
    width: 100%;
    height: 70px;
    left: 0px;
    bottom: 0px;
    background:  linear-gradient(180deg,rgba(21,46,82,.95),#05162e);
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 30px 30px 0px 0px;
    .section{
      color:white;
      height:70px;
      padding-top: 10px;
      opacity: 0.5;
      text-align: center;
      border:none;
      width: 50%;
      background-color: transparent;
      p{
        text-align: center;
        justify-content: center;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
      }
      img{
        width:1.5rem;
        height: 1.5rem;
        margin-bottom: 13px;
      }
    }

  }


}

.tab-bar-wrapper.tablet{
  z-index:50000;

}

