

    .progressbar-wrapper{
      width: 100%;
      display: flex;
      height: 1.5625rem;
      margin:auto;

      input[type="range"] {
        -webkit-appearance: none;
        margin:auto 5px;
        width: 100%;
        height: 4px;
        cursor:pointer;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 100px;
        background-image: linear-gradient(#ffff, #ffff);
        background-size: 0 100%;
        background-repeat: no-repeat;}

      /* Input Thumb */
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 40px;
        width: 40px;
        border-radius: 40px;
        border: none;
        cursor: grabbing;
        box-shadow: none;
        background: none; /* necessary for iOS Safari */
        background-image:radial-gradient(#ffff 5px, transparent 6px, transparent);
        //transition: background .3s ease-in-out;
      }

      input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height:12px;
       width:12px;
        border-radius: 40px;
        border: none;
        box-shadow: none;
        cursor: grabbing;
        background: none; /* necessary for iOS Safari */
        background-image:radial-gradient(#ffff 5px, transparent 6px, transparent);
        //transition: background .3s ease-in-out;
      }

      input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        height: 40px;
        width: 40px;
        border-radius: 40px;
        border: none;
        cursor: grabbing;
        box-shadow: none;
        background: none; /* necessary for iOS Safari */
        background-image:radial-gradient(#ffff 5px, transparent 6px, transparent);
        //transition: background .3s ease-in-out;
      }


      input[type=range]::-webkit-slider-runnable-track  {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
        box-shadow: none;
        margin-left:-20px;
        margin-right:-20px;
      }

      input[type=range]::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        margin-left:-20px;
        box-shadow: none;
        margin-right:-20px;
        background: transparent;
      }

      input[type="range"]::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        box-shadow:none;
        margin-left:-20px;
        margin-right:-20px;
        background: transparent;
      }


}


.progressbar-wrapper.mobile{
  margin:7.5px 0 9px 0;
  input[type="range"] {height: 3px;}


  .time{
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: auto;
  }
}

