@import '../../assets/styles/globals';


#lazyScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0a2244;
  z-index: 10000;

  .spinner {
    top: auto;
    margin: auto;
    height: 100vh;
    width: 100vw;
    border-radius: 50%;
    z-index: 10000000;
  }
}

