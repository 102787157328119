

.js-tilt-glare{
  border-radius: 5px;
}

.channel-page{
  position: fixed;
  z-index:3500;
  background: #0a2244;
  width: 100%;
  height: 100%;
  top:0;
  left:0;

  .channel-page-header{
    width:100%;
    height: 17rem;
    position: relative;
    background: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 3;
    transition: height 0.3s;
    @media (max-width: 435px) {
      transition: height 0.5s;
    }
    .blur-down{
      position: absolute;
      width: 100%;
      height: 12%;
      bottom:-2px;
      //background: linear-gradient(180deg, rgba(10, 34, 68, 1), rgba(10, 34, 68, 0));
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
    .channel-page-mobile-header{
      display: flex;
      padding: 15px;
      position: fixed;

      width:100%;

      .target-wrapper{
        width:fit-content;
        min-width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:-7.5px 0;
      }

      button{
        -webkit-flex-direction: row;
        flex-direction: row;
        padding: 4.5px 10px;
        height: 25px;
        background: #334b6d;
        transition: background-color .15s ease-in-out;
        box-shadow: 0 5px 15px -5px rgb(0 0 0 / 15%);
        border-radius: 100px;
        border: none;
        -webkit-flex: none;
        flex: none;
        -webkit-order: 0;
        order: 0;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        font-family: "Ubuntu",sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: .5px;
        color: #fff;
        img{
          width:16px;
          height: 16px ;
          margin-right:5px;

        }

      }
      .logo{
        position: absolute;
        right:15px;
        width:38.77px;
        height:15px;
        margin-top: -6px;
      }
    }
    .channel-header-background{
      position: fixed;
      display:block;
      width:100%;
      height: 17rem;
      top:0;
      background:  linear-gradient(0deg, #0a2244 0%, rgba(19, 30, 45, 0) 100%);
      transition: none;
      @media (max-width: 435px) {
        transition: height 0.5s;
      }
      .watermark{
        display: flex;
        position: relative;
        width: 100%;
        top:25%;

        img{
          width:144px;
          height: 60px;
          margin:auto;
        }
      }
      .channel-tags-wrapper{
        position: absolute;
        display: flex;
        bottom:60px;
        width: 85vw;
        margin:0 7.5%;
        height: 30%;
        z-index:1;

      .channel-tags {

        width: 85vw;
        position: relative;
        justify-content: center;
        text-align: center;
        display: flex;
        font-family: 'Ubuntu', sans-serif;
        margin: auto;



        .channel-page-channel-tags {
          width: 100%;
          margin: auto;
          padding-inline-start: 0;
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          justify-content: center;
          justify-content: center;
          text-align: center;
          visibility: visible;
          opacity: 1;
          transition: all 0.15s;
          .channel-page-channel-tag-item {
            display: inline;
            height: 24px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            margin: 0 8px;
            white-space: nowrap;

            div {
              margin: 0 8px;
              width: 4px;
              height: 24px;
              position: relative;
              display: inline-block;
              line-height: 200px;

              .bullet {
                position: absolute;
                top: 0;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                margin: auto 4px auto auto;
                top: 16px;
              }

              .overlay {
                z-index: 3;
                background-color: rgba(255, 255, 255, 0.5);
              }
            }
          }
        }
        .channel-page-channel-tags.minimizedHeader{
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.01s;
        }
      }}

      .channel-tags-wrapper.minimizedHeader{
        z-index:-1;
      }

              button{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 4.5px 15px 4.5px 5px;
                width: 70px;
                height: 25px;
                background: #334B6D;
                box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
                border-radius: 100px;
                border:none;
                flex: none;
                order: 0;
                flex-grow: 0;
                font-family: 'Ubuntu', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.025em;
                color: #FFFFFF;
                img{
                  width:16px;
                  height: 16px ;
                }

              }
              button:hover{
                background:  #8B9CBB;
                transition: background 150ms ease-in-out;
              }
              .channel-tab-bar{
                width: 100%;
                display: flex;
                position: absolute;
                bottom:-5px;
                padding: 15px;
                overflow-x: scroll;
                -ms-overflow-style: none; /* for Internet Explorer, Edge */
                scrollbar-width: none; /* for Firefox */
                align-items: center;

        .target-wrapper{
          display: flex;
          height:40px;
          justify-content: center;
          align-items: center;
          min-width:fit-content;
          margin: 0 7.5px;
        }

        .tab{
          width: auto;
          height: 30px;

          text-align: center;
          padding: 6px 15px;
          font-family: 'Ubuntu', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.5px;

          color: #FFFFFF;

        }
        .tab.active{
          background-color: #657BA4;
        }


      }

      .channel-tab-bar.less-tabs{
        justify-content: center;
      }

      .channel-tab-bar::-webkit-scrollbar{
        display: none;
      }



    }

    .detailed-item-wrapper{
      .selected-item-detail-page{
        width:100%;
        height: 100%;
        scrollbar-width: none;


      }
    }

}
  .channel-page-header.mobile.minimizedHeader{
    height: 180px;
    transition: height 0.5s;
    .channel-header-background.mobile{
      height: 180px;
      transition: height 0.5s;
    }
  }
  .channel-page-header.desktop.minimizedHeader{
    height: 35vh;
    transition: height 0.2s;
    @media (min-width: 1600px) {
      height: 33vh;
    }
    .channel-header-background.desktop{
      height: 35vh;
      transition: height 100s;
      @media (min-width: 1600px) {
        height: 33vh;
      }
    }
  }
  .tab-content{
    top:0;
    width: 100%;
    height: 70%;
    text-align: center;
    justify-content: center;
    position: relative;
    bottom: 0;
    padding-bottom: 120px;
    background: #0a2244;
    overflow-y: scroll;
    scroll-behavior: smooth;
    transition: all 0.3s;
    @media (max-width: 435px) {
      height: 80%;
    }
    @media (min-width: 1600px) {
      height: 80%;
    }

    .spinner{
      width:60px;
      height: 60px;
      margin:auto;
    }
  .channel-content-wrapper{
    width:100%;
    padding: 15px;
    justify-content: center;
    text-align: center;
    position: relative;
    display: grid;
    grid-column-gap:15px;
    grid-row-gap: 15px;
    grid-template-columns: 165px 165px;

    .content-wrapper{
      width:165px;
      height: 93px;
      border-radius: 5px;
      margin:0;
      padding: 0;
      .content-div{
        position: absolute;
        width:165px;
        height: 93px;
        border-radius: 5px;
        text-align: end;
        background-size: cover;
        img{
          width: 35px;
          height: 14px;
          border:none;
          margin:5px;
          position: absolute;
          top: 0;
          right: 0;
        }
        .filter{
          position: relative;
          height: 100%;
          width:100%;
          background:  linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.3) 64.96%, rgba(0, 0, 0, 0.3) 100%);;
        }
      }
      .content-title{
        width:100%;
        position: absolute;
        padding: 0 5px;
        bottom:-18px;
        top:auto;
        margin:0;
        text-align: left;
        height: auto;

        p{
          font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        width: 100%;
        text-align: left;
        color: #FFFFFF;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;

      }
        .content-subtitle{
          display: flex;
          align-items: center;

          p{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 0;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
          }
          img{
            width: 13px;
            height: 13px;
            position: relative;
            right: 0;
            top: 0;
            margin: 0;
            margin-right: 5px;
          }
        }
      }
    }
  }
    .back-to-top{
      margin:10px 0 25px 0;
      text-align: left;
      width:fit-content;
      min-width:40px;
      height: 40px;
      display: flex;
      align-items: center;

      padding-left: 15px;
      button{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 6px 15px;
        color: white;
        position: static;
        height: 30px;
        background: #535668;
        box-shadow: 0px 5px 15px -5px rgb(0 0 0 / 15%);
        border: none;
        border-radius: 100px;
        flex: none;
        order: 1;
        flex-grow: 0;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;
        display: flex;
        img {
          width: 18px;
          height: 18px;
          margin-right:10px;

        }

      }
    }
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }
  .tab-content.spinnerGrid{
    height: 100%;
    display: flex;
  }
  .tab-content::-webkit-scrollbar{
    display: none;
  }

}



.channel-page.desktop{
    position: fixed;
  .channel-page-header{
    height: 50%;
    background-position: center;

    .channel-header-background{
      position: absolute;
      display:block;
      width:100%;
      height:100%;
      top:0;
      background:  linear-gradient(0deg, #0a2244 0%, rgba(19, 30, 45, 0) 100%);
      .back-button{
        margin-top: 130px;
        padding-left: 12%;
        button{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 20px;
          width: auto;
          height: 40px;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgb(0 0 0 / 15%);
          border-radius: 100px;
          border: none;
          flex: none;
          order: 0;
          flex-grow: 0;
          font-family: "Ubuntu", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 18.38px;
          letter-spacing: 0.025em;
          color: #FFFFFF;
          img{
            width:20px;
            height: 20px;
            margin-right: 10px;
          }

        }
        button:hover{
          background:  #8B9CBB;
          transition: background 150ms ease-in-out;
        }
      }
      .watermark {
        position: absolute;
        display: flex;
        margin: 0 40.01%;
        width: 19.98vw;
        height: 8.3124vw;
        top: 15.625vh;
        transition: all 0.3s;

        img {
          width: 100%;
          height: 100%;
          transition: all 0.3s;
        }
      }
      .watermark.minimizedHeader{
        width: 16.52vw;
        height: 6.8742vw;
        margin: 0px 41.72%;
        top: 12.2vh;
      }

      .watermark.tablet{
        width:38.37vw;
        height: 15.95vw;
        margin: 0 30.815%;
      }

      .channel-tags-wrapper{
        position: absolute;
        display: flex;
        bottom: 17.6%;
        width: 100vw;
        height: 24%;
        margin: auto;
        z-index: 1;
        top: 64%;
      .channel-tags {
        top: 9%;
        width: 500px;
        position: relative;
        justify-content: center;
        text-align: center;
        display: flex;
        font-family: 'Ubuntu', sans-serif;
        margin: 0 auto;


            .channel-page-channel-tags {
              width: 100%;
              margin: auto;
              padding-inline-start: 0;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              justify-content: center;
              justify-content: center;
              text-align: center;
              visibility: visible;
              opacity: 1;
              transition: all 0.15s;


                        .channel-page-channel-tag-item {
                          display: inline;
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          font-family: 'Ubuntu', sans-serif;
                          line-height: 18px;
                          margin-right: 8px;
                          white-space: nowrap;
                          //max-width: 144px;
                          justify-content: center;
                          div {
                            margin: auto 5px auto 0;
                            width:8px;
                            height: 27px;
                            position: relative;
                            display: inline-block;
                            line-height: 31px;

                            .bullet {
                              position: absolute;
                              top: 0;
                              left: 0;
                              width: 8px;
                              height: 8px;
                              border-radius: 100%;
                              margin: auto 5px auto auto;
                              top: 17px;
                            }

                            .overlay {
                              z-index: 3;
                              background-color: rgba(255, 255, 255, 0.5);
                            }
                          }
                        }
                      }

                      .channel-page-channel-tags.minimizedHeader{
                        visibility:hidden;
                        opacity: 0;
                        transition: opacity 0.15s;
                      }
                    }}

      .channel-tags-wrapper.minimizedHeader{
        transition: z-index 0.2s;
        z-index:-1;
      }


                    button{
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      padding: 4.5px 15px 4.5px 5px;
                      width: 4.5rem;
                      height: 1.5rem;
                      background: #334B6D;
                      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
                      border-radius: 100px;
                      border:none;
                      flex: none;
                      order: 0;
                      flex-grow: 0;
                      font-family: 'Ubuntu', sans-serif;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 1.75rem;
                      line-height: 0.875;
                      letter-spacing: 0.025em;
                      color: #FFFFFF;
                      img{
                        width:1.5rem;
                        height: 1rem ;
                      }

                    }
                    button:hover{
                      background:  #8B9CBB;
                      transition: background 150ms ease-in-out;
                    }
                    .channel-tab-bar{
                      display: flex;
                      position: absolute;
                      bottom:0;
                      padding-top: 0;
                      margin: auto;
                      justify-content: center;
                      .tab{
                        width: auto;
                        height: 40px;
                        margin: auto 7.5px;
                        text-align: center;
                        padding: 10px 20px;
                        font-family: 'Ubuntu', sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 18px;
                        letter-spacing: 0.5px;
                        color: #FFFFFF;
                      }
                    }
                  }
                }
                .tab-content{
                  top:0;
                  padding: 0 11% 90px 11%;
                  text-align: center;
                  scroll-behavior: smooth;
                  .spinner{
                    width:60px;
                    height: 60px;
                    margin:auto;
                  }
                  .channel-content-wrapper{
                    text-align: center;
                    justify-content: center;
                    display: grid;
                    grid-template-columns: 19% 19% 19% 19% 19%;
                    width: 100%;
                    grid-gap: 15px;
                    grid-row-gap: 30px;
                    padding-top: 1rem;
                    .content-wrapper{
                      display: inline-block;
                      opacity: 1;
                      border-radius: 5px;
                      width: 14.583vw;
                      height: 8.3314vw;
                      margin: 0;
                      .content-div{
                        background: radial-gradient(48.81% 149.48% at 64.29% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(0, 0, 0, 0.1);
                        width: 14.583vw;
                        height: 8.3314vw;
                        background-size: 100% 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        border-radius: 5px;
                        box-shadow: 0px 5px 15px -5px rgb(0 0 0 / 25%);
                        text-align: end;
                        position: relative;
                        .content-title{
                          width:100%;
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 18px;
                          position: absolute;
                          bottom:0;
                          height: auto;
                          top:auto;
                          z-index: 2;
                          text-align: left;
                          p{
                            text-align: left;
                            margin:0;
                            padding:0;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 18px;

                          }
                          .content-subtitle{
                            display: flex;
                            align-items: center;

                            p{
                              font-style: normal;
                              font-weight: 400;
                              font-size: 14px;
                              line-height: 16px;
                              margin-bottom: 0;
                              text-align: left;
                              white-space: nowrap;
                              overflow: hidden;
                              display: inline-block;
                              text-overflow: ellipsis;
                            }

                            img{
                              width: 13px;
                              height: 13px;
                              position: relative;
                              right: 0;
                              top: 0;
                              margin-right: 5px;
                            }
                          }

                        }
                        img{
                          width: 3.4721vw;
                          height: 1.3883vw;
                          position: absolute;
                          top:5px;
                          right:5px;
                          left:auto;
                          z-index: 3;
                          margin:0;
                        }
                        .filter{
                          width:100%;
                          height: 100%;
                          background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.3) 64.96%, rgba(0, 0, 0, 0.3) 100%);
                        }
                      }
                      .content-div.desktop{
                        width: 14.3784vw;
                        height: 8.088vw;
                        img{
                          width: 3.4721vw;
                          height: 1.3883vw;
                          border: none;
                          margin: 5px;
                          position: absolute;
                          top: 0;
                          right: 0;
                        }
                      }

                      .content-title{
                        width:95%;
                        height: 12.5%;
                      }

                    }


                    .content-wrapper:hover{
                      transform: scale(1.03);
                    }

                  }

                  .channel-content-wrapper.tablet{
                    width:100%;
                    padding: 1%;
                    text-align: center;
                    position: relative;
                    display: grid;
                    grid-template-columns: auto auto auto;

                    .content-wrapper.tablet{
                      display: inline-block;
                      width:210px;
                      height: 120px;
                      border-radius: 5px;
                      margin: 0.78rem 0.51rem;
                      padding: 0;
                      .content-div{
                        width:210px;
                        height: 120px;
                        border-radius: 5px;
                        text-align: end;
                        background-size: cover;
                        img{
                          width:50px;
                          height: 20px;
                          border:none;
                          margin:0;
                        }
                      }
                      .content-title{
                        width:100%;
                        height: auto;
                        bottom:0;
                        padding:0 5px;
                        p{

                          font-weight: 500;
                          font-size: 16px;
                          line-height: 18px;
                          display: flex;
                          width: 100%;
                          align-items:start;
                          color: #FFFFFF;
                          white-space: nowrap;
                          overflow: hidden;
                          display: inline-block;
                          text-overflow: ellipsis;
                        }
                        .content-subtitle{
                          display: flex;
                          align-items: center;

                          p{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 16px;
                            margin-bottom: 0;
                            text-align: left;
                            white-space: nowrap;
                            overflow: hidden;
                            display: inline-block;
                            text-overflow: ellipsis;
                          }

                          img{
                            width: 13px;
                            height: 13px;
                            position: relative;
                            right: 0;
                            top: 0;
                            margin-right: 5px;
                          }
                        }
                      }
                    }
                  }


                  .back-to-top{
                    padding-left: 15px;
                    button{
                      height: 40px;
                      padding: 10px 20px;
                      display: flex;
                      img{
                        width:20px;
                        height: 20px;
                      }
                    }
                    button:hover{
                      background:  #8B9CBB;
                      transition: background 150ms ease-in-out;
                    }
                  }
                  -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }


  .tab-content::-webkit-scrollbar{
    display: none;
  }

  .detailed-item-wrapper{
    .selected-item-detail-page.desktop{
      width:73.15vh;
      height: 73.15vh;
      margin:11.425vh calc((100vw - 73.5vh)/2);


    }
  }
}
