.accordion-item {
  width: 100%;
  height: auto;
  margin:0 auto 0.9375rem auto;
  color:#FFFFFF;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.125rem;
  transition: height ease-out 150ms;
  border-radius: 5px;

}


.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #334569;
  border-radius: 5px;
}



.accordion-title,
.accordion-content {
  padding:10px 1rem;
}

.accordion-content {
  height: 9.3125rem;
  margin-top: -4px;
  background-color: #334569;
  padding:0 1rem;
  border-radius: 0 0  5px 5px;
  .accordion-wrapper {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    p {
      opacity: 0.75;
      margin-top: 10px;
    }
  }
}
