
:root{
  --didomi-opacity:0;
}

/*noinspection CssInvalidPropertyValue*/
html {
  height: -webkit-fill-available;
}
/*noinspection ALL*/
body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0a2244;
  position: fixed;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  -webkit-touch-callout: none !important;
}

#banner-ad{
  width:1px;
  height: 1px;
}

.root{
  position: fixed;
}

.orientleft {
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: 160px 160px;
}

.orientright {
  -webkit-transform: rotate(90deg);
  -webkit-transform-origin: 230px 230px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#didomi-host a, #didomi-host p, #didomi-host span{
  color: #fff!important;
}

#didomi-host button span {
  color: inherit!important;
}
#didomi-host [dataTooltip]::after {
  color: #333!important;
}

#didomi-host .didomi-consent-popup-preferences-purposes .didomi-consent-popup-data-processing__purpose_actions .didomi-consent-popup-data-processing__purpose_actions_title{
  color: #333!important;
}

#didomi-host .didomi-mobile .didomi-consent-popup-preferences-purposes .didomi-consent-popup-footer {
  margin-bottom: 75px!important;
}

.didomi-notice-popup{
  opacity: var(--didomi-opacity);
  transition: opacity 150ms ease-in-out;
}

.didomi-exterior-border{
  background: #08162B!important;
  color:white!important;
  border-radius:15px!important;
  border:none!important;
  font-family: Ubuntu!important;
}

.didomi-notice-data-processing-title{
  color:white!important;
  font-family: Ubuntu!important;
}

.didomi-popup-notice-text a{
  color:white!important;
  font-family: Ubuntu!important;
  font-weight: bold!important;
}

.didomi-popup-footer{
  background-color: transparent!important;
}

.didomi-popup-container{
  background: #08162B!important;
  color:white!important;
  border-radius:15px!important;
  font-family: Ubuntu!important;
}
.didomi-components-button{
  color: #FFFFFF!important;
  border:none!important;
  background: #334B6D!important;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15) !important;
  border-radius: 100px!important;
}

#didomi-host .didomi-popup-backdrop{
  background-color: rgba(1, 6, 13, 0.85)!important;
  padding: 15px!important;
  backdrop-filter: blur(10px)!important;
}

#didomi-popup{
  justify-content: center!important;
}

span::after{
    background: white;
    color:black;

  }







#didomi-host{
  .didomi-components-radio{
    span{
      color:inherit!important;
    }

  }
}

#didomi-host.didomi-consent-popup-preferences-purposes.didomi-consent-popup-data-processing__description_legal_icon {
 background: transparent!important;
  color:white!important;
}

#didomi-host .didomi-consent-popup-preferences-purposes .didomi-consent-popup-data-processing__description_legal_icon {
color:inherit!important;
}


.didomi-consent-popup-data-processing{
      div{
        span{
          color:white!important;
        }
      }
  .didomi-components-button{
    span{
      color:inherit!important;
    }

  }

    }




.didomi-consent-popup-data-processing__purpose_actions{
  span.didomi-consent-popup-data-processing__purpose_actions_title{
    color:black!important;
  }
}


/*{*/
/*  background-color: rgba(1, 6, 13, 0.85);*/
/*  backdrop-filter: blur(10px)!important;*/
/*}*/



#didomi-host .didomi-popup-container{
  border:none!important;

}

.didomi-components-button{
  transition: all 100ms ease-in-out!important;
  border:none!important;
  outline: none!important;
  -webkit-tap-highlight-color:  #8B9CBB!important;
}

.didomi-components-button:hover{
  background-color:  #8B9CBB!important;
  transition: all 100ms ease-in-out!important;
  border: none!important;
  border-radius: 100px!important;
  outline: none!important;
  -webkit-tap-highlight-color:  #8B9CBB!important;
}


.didomi-components-button:active{
  background-color:  #657BA4!important;
  transition: all 100ms ease-in-out!important;
  border:none!important;
  border-radius: 100px!important;
  outline: none!important;
  -webkit-tap-highlight-color:  #8B9CBB!important;

}

#didomi-host .didomi-popup-backdrop{
  background-color: rgba(1, 6, 13, 0.85)!important;
  backdrop-filter: blur(10px)!important;
}

#didomi-host a:not(.didomi-no-link-style){
  color:white!important;
}

.didomi-consent-popup-body__explanation span a{
  color:white!important;
}

.didomi-arrow-back-popup-preferences-vendors{
  color:white!important;
}

.didomi-consent-popup-body__title{
  color:white!important;
}

.didomi-consent-popup-view-vendors-list-link{

  color: #FFFFFF!important;
  border:none!important;
  background: #334B6D!important;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15) !important;
  border-radius: 100px!important;

}

.didomi-popup-close{
  color:white!important;
}


#didomi-notice-agree-button{
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15)!important;
  border: none!important;
  -webkit-tap-highlight-color: transparent!important;
  orphans: unset!important;
  border-radius: 100px!important;
  outline:none!important;
  -webkit-text-stroke-color:transparent!important;
}

#didomi-notice-learn-more-button, #didomi-notice-disagree-button{
  background: rgba(80, 80, 80, 0.5)!important;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15)!important;
  border: none!important;
  border-radius: 100px!important;
  -webkit-tap-highlight-color: transparent!important;
  orphans: unset!important;
  outline:none!important;
  -webkit-text-stroke-color:transparent!important;


}


#didomi-notice-learn-more-button:active{
  background: #929292!important;
  -webkit-tap-highlight-color: transparent!important;
  orphans: unset!important;
  outline:none!important;
  border-radius: 100px!important;
  -webkit-text-stroke-color:transparent!important;
  border-block-color: transparent!important;


}

#didomi-notice-agree-button:active{
  -webkit-tap-highlight-color: transparent!important;
  orphans: unset!important;
  outline:none!important;
  border-radius: 100px!important;
  -webkit-text-stroke-color:transparent!important;
  border-block-color: transparent!important;
}

#didomi-notice-agree-button:hover{
  background: #8B9CBB!important;
  -webkit-tap-highlight-color:  #8B9CBB!important;
  orphans: unset!important;
  outline:none!important;
  border-block-color: transparent!important;
  border-radius: 100px!important;

}



#didomi-notice-learn-more-button:hover{
  background: #8B9CBB!important;
  -webkit-tap-highlight-color:  #8B9CBB!important;
  orphans: unset!important;
  outline:none!important;
  border-radius: 100px!important;
  border-block-color: transparent!important;

}

#didomi-notice-disagree-button:hover{
  background: #8B9CBB!important;
  -webkit-tap-highlight-color:  #8B9CBB!important;
  orphans: unset!important;
  outline:none!important;
  border-radius: 100px!important;
  border-block-color: transparent!important;

}

#didomi-notice-learn-more-button:focus{
  background: rgba(80, 80, 80, 0.5)!important;
  -webkit-tap-highlight-color: transparent!important;
  orphans: unset!important;
  outline:none!important;
  border-radius: 100px!important;
  border-block-color: transparent!important;
}

#didomi-notice-agree-button:focus{
  //background: rgba(80, 80, 80, 0.5)!important;
  -webkit-tap-highlight-color: transparent!important;
  orphans: unset!important;
  outline:none!important;
  border-block-color: transparent!important;
  border-radius: 100px!important;
}


#didomi-notice-disagree-button::-moz-selection { /* Code for Firefox */
  color: white!important;
  background: transparent!important;
  orphans: unset!important;
  border-radius: 100px!important;
  -webkit-text-stroke-color:transparent!important;
  border-block-color: transparent!important;
  outline:none!important;

}


#didomi-notice-learn-more-button::selection { /* Code for Firefox */
  color: white!important;
  background: transparent!important;
  orphans: unset!important;
  -webkit-text-stroke-color:transparent!important;
  border-block-color: transparent!important;
  outline:none!important;
}

#didomi-notice-agree-button::selection { /* Code for Firefox */
  color: white!important;
  background: transparent!important;
  orphans: unset!important;
  -webkit-text-stroke-color:transparent!important;
  border-block-color: transparent!important;
  outline:none!important;
}



.didomi-components-button.didomi-button.didomi-button-standard.standard-button{
  background: rgba(80, 80, 80, 0.5)!important;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15)!important;
  font-family: Ubuntu!important;
  border-radius: 100px!important;

}

#didomi-host a, #didomi-host p, #didomi-host span {
  color: #fff!important;
}


