.promoted-slider{
  width:100%;
  height: auto;
  position: absolute;
  top:61px;
  .promoted-slider-swiper{
    width: 31.25vh;
    height:17.58vh;
    .promoted-slider-channel-slide{
      width: 31.25vh;
      height:17.58vh;
      background-size:cover;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      .promoted-overlay{
        background: rgb(0,0,0,0.5);
        width: 100%;
        height: 100%;

      }

    }
  }

  .promoted-slider-swiper.swiper-slide-active{
    .promoted-slider-channel-slide{
      .promoted-overlay{
        background: transparent;
      }
    }
  }
  .promoted-slider-swiper.mobile {
    width:160px;
    height: 90px;
    .promoted-slider-channel-slide {
      width:160px;
      height: 90px;
    }
  }


}
