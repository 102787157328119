
.settings-wrapper{
  width:100vw;
  height: 100vh;
  background-color: #0a2244;
  color:#FFFFFF;
  font-family: 'Ubuntu', sans-serif;
  position: absolute;
  z-index: 1500;
  overflow: hidden;
  .header{
    position: relative;
  }

  .version-number{
    position:absolute;
    bottom:50px;
    left:12%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    letter-spacing: 0.025em;

    color: #FFFFFF;
  }

  .version-number.tablet{
    left:30px;
    bottom:120px;
  }

  .settings-view{
    width: 100%;
    height: 100%;
    padding:3% 12%;
    display: flex;

    .settings-nav{
      width:17.7vw;
      height: 30.49vw;
      .settings-title{
        margin-bottom: 1.38vw;
      }
      .settings-tabs{

        width:17.7vw;
        .settings-tab{
          display: flex;
          width:17.7vw;
          .tab-info{
            margin-bottom:1.25rem;
            width:16.7vw ;
            word-break: break-word;
            .tab-title{

              font-family: 'Ubuntu', sans-serif;
              font-style: normal;
              font-weight: bold;
              font-size: 1rem;
              line-height: 1.125rem;
              letter-spacing: 0.025em;
              flex: none;
              order: 0;
              align-self: stretch;
              flex-grow: 0;
              margin: 5px 0px;
            }

            .tab-title.active{
              color:#FB5B77;
            }

            .tab-subtitle{
              font-family: 'Ubuntu', sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 0.75rem;
              line-height: 0.875rem;
              letter-spacing: 0.025em;
              opacity: 0.75;

            }
            .tab-subtitle.active{
              opacity: 1;
            }
          }
          .settings-tab-arrow{
            display: flex;
            img{
              width: 10px;
              height: 10px;
              margin: auto 10px;
            }
          }

        }

      }
    }
    .active-tab{
      height: 82%;
      margin-left:8.68vw;
      padding-bottom: 5%;
      .active-div-title{
        margin-bottom: 20px;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.33rem;
        line-height: 1.5625rem;
        letter-spacing: 0.025em;
      }
      .active-tab-detail-explanation{
        width: 37.5vw;
        height: auto;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.125rem;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        margin-bottom: 20px;
      }
      .active-tab-detail-explanation.title{
        font-size: 1.125rem;
        line-height: 1.125rem;
        margin-bottom: 0;

      }
      //.active-tab-detail-explanation.help-and-support{
      //  margin-bottom: 2.25rem;
      //}
      .active-tab-detail{
        width: 37.5vw;
        max-height: 2.5vw;
        .active-tab-detail-explanation-detail{

          width: 37.5vw;
          max-height: 2.5vw;
          font-family: 'Ubuntu', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.125rem;
          letter-spacing: 0.025em;
          color: #FFFFFF;
          margin-bottom: 20px;
          opacity: 0.75;
        }
        .active-tab-detail-content{
          display: flex;
          flex-wrap:wrap;
          width: 37.5vw;
          max-height: 2.5vw;
          overflow-wrap: normal;
          a{

            text-decoration: none;
            .lang-button:hover{
              background: #8B9CBB;
            }
          }
          .lang-button:hover{
            background: #8B9CBB;
            transition: background-color 100ms ease-in-out;
          }
          .lang-button{

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            border: none;
            height: 2.5rem;
            margin:0 1.25rem 1.25rem 0;
            background: #334B6D;
            box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
            border-radius: 100px;
            font-family: 'Ubuntu', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.125rem;
            letter-spacing: 0.025em;
            color: #FFFFFF;
            transition: background-color 100ms ease-in-out;
            input[type="radio"]{
              width: 12px;
              height: 12px;
              display:block;

              background: transparent;

            }
            label{
              background: transparent;
              font-family: 'Ubuntu', sans-serif;
              font-style: normal;
              font-weight: bold;
              font-size: 1rem;
              line-height: 1.125rem;
              letter-spacing: 0.025em;
              margin: auto;
              color: #FFFFFF;
            }
          }
        }
      }
      .active-tab-detail.privacy{
        width: 50.69vw;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        //padding-bottom: 42vw;
        h2{
          font-weight: normal;
          font-size: 1.78rem;
          line-height: 2.06rem;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin: 1.98rem 0px;
        }
        h3{
          font-size: 1.33rem;
          line-height: 1.5625rem;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin: 0.98rem 0;
        }
        p{
          font-size: 1rem;
          line-height: 1.125rem;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin: 0.625rem 0;
        }
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: 0; /* for Firefox */
      }

      .active-tab-detail.privacy::-webkit-scrollbar{
        display: none;
      }

    }


  }
  .settings-view.tablet{
    padding:3% 30px;
    .settings-nav{
      width:255px;
      .settings-tabs{
        width:255px;
        .tablet-button-wrapper{
          margin-top:25px;
          .target-wrapper{
            margin-bottom:15px;
            button{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 6px 10px;
              //width: 70px;
              height: 30px;
              background: #334B6D;
              box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
              border-radius: 100px;
              border:none;
              flex: none;
              order: 0;
              flex-grow: 0;
              font-family: 'Ubuntu', sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 18.38px;
              letter-spacing: 0.025em;
              color: #FFFFFF;
            }
          }
        }

        .settings-tab{
          width:255px;
          .tab-info{
            width:240px;
          }
          .settings-tab-arrow{
            width:10px;
          }
        }
      }
    }

      .active-tab{
        min-width: 445px;
        width:auto;


        margin-left: 32.5px;

      }
      .active-tab>*{

        min-width: 445px;
       }
      .active-tab-detail>*{

        min-width:445px;

      }

  }

  .settings-view.mobile{
    padding:0;
    .tab-mobile{
      position: absolute;
      width:100vw;
      height: 100vh;
      z-index: 1500;
      .content-header{
        width:100%;
        position: absolute;
        top:0;
        display: flex;
        padding:15px;
        justify-content: space-between;
        z-index:20;

        .target-wrapper{
          display: flex;
          width: fit-content;
          min-width: 40px;
          align-items:center;
          height: 40px;
          margin-top:-7.5px;

        }

        button{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 4.5px 10px 4.5px 5px;
          //width: 70px;
          height: 25px;
          background: #334B6D;
          box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
          border-radius: 100px;
          border:none;
          flex: none;
          order: 0;
          flex-grow: 0;
          font-family: 'Ubuntu', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.025em;
          color: #FFFFFF;
          img{
            width:16px;
            height: 16px ;
            margin-right:5px;

          }
        }

        .logo{
          width:38.77px;
          height:15px;
          marginTop:3px;
        }
      }
      .active-tab{
        width:100%;
        height: 100%;
        margin: 65px auto 90px auto;
        padding: 0 15px 0 15px;
        .active-div-title{
          margin-bottom: 20px;
          h3{
            font-family: 'Ubuntu', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.025em;
          }

        }
        .active-tab-detail{
          width: 100%;
          height: 100%;
          margin: 0;
          .active-tab-detail-content{
            width:100%;
            display: flex;
            flex-wrap: wrap;
            height: auto;
            overflow-wrap: normal;
            .lang-button{
              align-items: center;
              padding: 6px 15px;
              height: 30px;
              background: #334B6D;
              box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
              border-radius: 100px;
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 0px 15px 15px 0;
              font-family: 'Ubuntu', sans-serif;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 18px;
              letter-spacing: 0.025em;
            }
          }
          .accordion-item{
            width: 100%;
            height: auto;
            margin: 15px auto;
            color: #FFFFFF;
            font-family: 'Ubuntu', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 1.125rem;
            transition: height ease-out 150ms;
            border-radius: 5px;
            .accordion-title{
              padding: 10px;
              border-radius: 5px;
            }
            .accordion-content {
              background-color: #334569;
              padding: 0 10px 10px 10px ;
              font-family: 'Ubuntu', sans-serif;
              font-style: normal;
              font-weight: normal;
              margin-top: -4px;
              height: auto;
              border-radius: 0 0 5px 5px;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.025em;
              color: #FFFFFF;
            }
          }
        }
        .active-tab-detail.privacy{
          padding-bottom: 250px;
          max-height: 100%;
          width: 100%;
          h1{
            font-size: 1.5rem;
          }
          h2{
            font-size: 1.25rem;
          }
          h3{
            font-size: 1rem;
          }
        }
        .active-tab-detail-explanation{
          width:100%;
          font-size: 14px;
          line-height: 18px;
          height: auto;
          margin-bottom:10px;
        }
        .active-tab-detail-explanation.help-and-support{
          margin-bottom: 10px;
        }
        .active-tab-detail-content.help-and-support{
          margin-top: 0;
        }
        .active-tab-detail-explanation-detail{
          width: 100%;
          max-height: fit-content;
          font-family: 'Ubuntu', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.025em;
          margin-bottom: 10px;

        }
      }
    }
p{margin-bottom: 10px;}
  }


  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 1.25rem;
    display: inline-block;
    color: #666666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.25rem;
    height: 1.25rem;
    border: 0.125rem solid #FFFFFF;
    border-radius: 100%;
    background: transparent;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    background: #FFFFFF;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

}
