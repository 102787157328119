


.home-wrapper {
  position: fixed;
  width: 100vw;
  //min-height: 100vh;
  //min-height: -webkit-fill-available;
  height: 100%;
  z-index: 105;
  //overflow: hidden;
  top:0;
  bottom:0;
  left:0;
  right:0;


  .top-ad-blur {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    height: 10.5%;
    background: linear-gradient(180deg, rgba(10, 34, 68, 0) 0%, rgba(10, 34, 68, 0.15) 23.7%, rgba(10, 34, 68, 0.375) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  .bottom-ad-blur {
    position: absolute;
    z-index: 100;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 10.5%;
    background: linear-gradient(180deg, rgba(10, 34, 68, 0) 0%, rgba(10, 34, 68, 0.15) 23.7%, rgba(10, 34, 68, 0.375) 100%);
  }

  .loading-view{
    position: relative;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: rgba(10, 34, 68, 0.5);


  }
  .loading-view.mobile{
    width:100vw;
    height: 56.25vw;
  }

  .loading-view.mobile.landscape{
    width:100vw;
    height:100vh;
    div{
      margin:auto;
      width:60px;
      height: 60px;
    }
  };


  .hide-ui {
    display: none;
    transition: visibility 5s, opacity 5s linear;
  }

  .show-ui {
    position: fixed;
    display: block!important;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    transition: visibility 1s, opacity 1s linear;

    .home-header {
      position: fixed;
      width: 100%;

      .spinner {
        margin-left: 0;
        padding: 15px;
      }
    }

    .blurs {
      position: absolute;
      bottom: 0;
      height: auto;
      width: 100%;
      padding: 0;
      margin: 0;

      .blur-up {
        width: 100%;
        height: 15px;
        background:linear-gradient(0deg, #0a2244 0%, rgba(19, 30, 45, 0) 100%);
        z-index: 0;
      }



    }


  }

  #home.mobile {
    height: 56.25vw;
    width:100%;
    position: fixed;
    top:0;
    overflow:hidden;

  }



  .blur-down {
    position: fixed;
    width: 100%;
    height: 10px;
    background: linear-gradient(0deg, #0a2244 0%, rgba(19, 30, 45, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    top:56.25vw;
    z-index:1;
  }




  .home-transition-enter {
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 0.001;
    -webkit-transition: background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition: background 250ms ease-in-out, opacity 250ms ease-in-out;

    .channel-bar{
      transform: translateY(50px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;

    }
    .header.desktop{
      transform: translateY(-50px);
        -webkit-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
    }


    //#home-info.mobile{
    //  transform: translateY(50px);
    //  -webkit-transition: all 250ms ease-in-out;
    //  transition: all 250ms ease-in-out;
    //}
  }
  .home-transition-enter-active {
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 1;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .channel-bar{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .header.desktop{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }

    //#home-info.mobile{
    //  transform: translateY(0);
    //  -webkit-transition: all 250ms ease-in-out;
    //  transition: all 250ms ease-in-out;
    //}

  }
  .home-transition-enter-done{
    position: fixed;
    background: rgba(10, 34, 68, 0.5);
    width: 100%;
    height: 100%;
    z-index: 104;
    opacity: 1;
    .channel-bar{
      transform: translateY(0);
    }
    .header.desktop{
      transform: translateY(0);
    }
    //#home-info.mobile{
    //  transform: translateY(0);
    //}
  }


  .home-transition-exit{
    width:100%;
    height: 100%;
    opacity: 1;
    background: transparent;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .channel-bar{
      transform: translateY(50px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .header.desktop{
      transform: translateY(0);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms  ease-in-out;
    }
    //#home-info.mobile{
    //  transform: translateY(0);
    //  -webkit-transition: all 250ms ease-in-out;
    //  transition: all 250ms ease-in-out;
    //}
  }
  .home-transition-exit-active {
    width:100%;
    height: 100%;
    opacity: 0;
    background: transparent;
    -webkit-transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    transition:background 250ms ease-in-out, opacity 250ms ease-in-out;
    .channel-bar{
      transform: translateY(50px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    .header.desktop{
      transform: translateY(-50px);
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    //#home-info.mobile{
    //  transform: translateY(50px);
    //  -webkit-transition: all 250ms ease-in-out;
    //  transition: all 250ms ease-in-out;
    //}
  }
  .home-transition-exit-done {
    width:100%;
    height: 100%;
    opacity: 0;
    background: transparent;
    .channel-bar{
      transform: translateY(50px);
    }
    .header.desktop{
      transform: translateY(-50px);
    }

    //#home-info.mobile{
    //  transform: translateY(50px);
    //}
  }

  #home.mobile{
    height: 56.25vw;
    width:100%;
    position: fixed;
    top:0;
    overflow:hidden;
    .home-header{
      position: fixed;
      width: 100%;
    }
    .blurs {
      position: absolute;
      bottom: 0;
      height: 15px;
      width: 100%;
      padding: 0;
      margin: 0;

      .blur-up {
        width: 100%;
        height: 15px;
        background: linear-gradient(0deg, #0a2244 0%, rgba(19, 30, 45, 0) 100%);
        z-index: 0;

      }
      .blur-down {
        position: fixed;
        width: 100%;
        height: 10px;
        background: linear-gradient(0deg, #0a2244 0%, rgba(19, 30, 45, 0) 100%);
        transform: matrix(1, 0, 0, -1, 0, 0);
        top:56.25vw;
        z-index:1;
      }
    }
  }

  ::selection {
    color: none;
    background: none;
  }
  /* For Mozilla Firefox */
  ::-moz-selection {
    color: none;
    background: none;
  }


}

.home-wrapper.mobile{
  top:unset;
  bottom:unset;
  width:100%;
  height: 100%;
  left:unset;
  right:unset;
  overflow: hidden;
  padding-bottom: 90px;
  z-index: 900;

  #home{
    .home-header{
      height: 42px;
    }
  }
}



@media screen and (orientation: portrait){


    .ads-countdown.tablet{
      width:90px;
      height: 20px!important;
      font-size: 12px!important;
      line-height: 14px!important;
      left:25px;
      .ads-countdown-ring {
        vertical-align: middle;
        width: 20px!important;
        height: 20px!important;
      }
      .ads-countdown-text{margin: auto auto auto 25px!important;}
    }


}
