
.header{
  font-family: 'Ubuntu', sans-serif;
  padding-top: 15px;
  color:white!important;
  z-index:130;
  position: fixed;
  .header-button{
    font-family: 'Ubuntu', sans-serif;
  }


.header-menu{
  justify-content: space-between;
  display:flex;
  height: 100%;
  padding:0px 10% 0 12.4%;
}
  .header-menu-item{
    border-radius: 100px;
    background-color:#334B6D;
    width:27.5rem;

  }


    .header-button:hover,  .header-button.active{
      border:none;
      background-color: #8B9CBB;
      color:#FFFFFF;
      transition: background-color 100ms ease-in-out;
    }

  #responsive-navbar-nav{
    opacity: 0!important;

  }

  #responsive-navbar-nav.show{
    z-index: 99;
    opacity: 1!important;
    //width:100%;
      height: 100%;
    //margin-bottom:env(safe-area-inset-bottom);
    //padding-bottom:120px;
    max-height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none; /* for Firefox */
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

  }
  #responsive-navbar-nav.show::-webkit-scrollbar{
    width:0;
  }

  //.navbar-collapse.collapse.collapsing{
  //  height: auto!important;
  //}
  ////
  //#navbar{
  //  position: fixed;
  //  overflow: hidden;
  //  height: 100%;
  //}

  #toggle{
   margin-left:auto
  }

  .corner-logo{
    display:flex;
    padding: 0;
    text-align: left;
    justify-content:flex-end;
    position: absolute;
    right:15px;
    img{
      vertical-align: middle;
    }

    #iconLogo{
      width:38.77px;
      height: 15px;
      margin:0 0 auto auto;
    }

    #iconLogo.loading{
      width:30px;
      height: 30px;
      margin-top:-8px;

    }

    #iconLogo.collapsed-logo{
      width:75.6px;
      height: auto;
    }

    .desktopLogo{
      width:126px;
      height:40px;
      margin: auto auto auto 0;
    }
    .desktopLogo.loading{
      width:60px;
      height:60px;
    }


  }

  .corner-logo.buffering{
    margin-top:-10px;
  }

  #toggler-button{
    border:none;
    padding:0;
    background-color: transparent!important;
    margin-bottom: 10px;
    outline: none!important;
    -moz-user-select: none!important;
    -webkit-user-select: none!important;
    -ms-user-select: none!important;
    user-select: none!important;
  }

  #toggler-button:hover{
    border:none;
    background-color: transparent!important;
    outline: none!important;

  }
  .header-button{
    min-width: 60px;
    height: 40px;
    background-color:#334B6D;
    border:none;
    margin-right:25px;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    flex: none;
    order: 0;
    align-self: flex-start;
    flex-grow: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.025em;
    color:#FFFFFF;
    img{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    transition: background-color 150ms ease-in-out;
  }


  .mobile-nav{
    min-height:-webkit-fill-available;
    margin-top:20px;
    overflow-y: scroll;
    height: 100%;
    color: white;
    padding-bottom: 90px;
    z-index: 100000000000;
    .mobile-menu-item{
      margin-bottom:50px;
      button{
        color:#FFFFFF;
      }
      h3{
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;
        margin: 0 0 5px 0;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
      }


      .target-wrapper{
        display: flex;
        height:40px;
        justify-content: center;
        align-items: center;
        width: fit-content;
      }
      .header-button-watch{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 15px;
        border:none;
        position: static;
        height: 30px;
        right: 32px;
        top: 33px;
        background: #334B6D;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.25);
        border-radius: 100px;

        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: auto;

        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.025em;

        .burger-menu-button-icon{
          width:18px;
          height: 18px;
          margin-right:10px;
        }
      }

      .header-button-settings{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5.5px 15px;

        position: static;
        height: 25px;
        left: 0px;
        top: 33px;
        border:none;
        background: rgba(146, 146, 146, 0.5);
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.25);
        border-radius: 100px;

        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 15px 0px;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */

        letter-spacing: 0.025em;
      }

      .header-button-more{
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5.5px 15px;
        height: 25px;
        position: static;;
        left: 0px;
        top: 0px;

        background: #334B6D;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        margin-bottom:15px;
        border:none;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */

        letter-spacing: 0.025em;
      }
    }

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: 0; /* for Firefox */

    .version-number{
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      letter-spacing: 0.025em;

      color: #FFFFFF;

    }

  }

  .mobile-nav::-webkit-scrollbar{
    display: none;
  }

  }

.header.mobile{
  font-family: 'Ubuntu', sans-serif;
  padding:15px ;
  //position: relative;
  height: auto;
  //overflow: hidden;

  //animation: fadeinout .15s;
  //-webkit-animation: fadeinout .15s;
  //-moz-animation: fadeinout .15s;
  //-o-animation: fadeinout .15s;
  //-ms-animation: fadeinout .15s;
  //-webkit-transition:  background ease-in-out  0.2s;
  //-moz-transition:  background ease-in-out  0.2s;
  //-ms-transition:  background ease-in-out  0.2s;
  //-o-transition:  background ease-in-out  0.2s;
  //transition: background ease-in-out  0.2s;
}

//@keyframes fadeinout {
//  0% { opacity: 0; }
//  25% { opacity: 0.25;}
//  50% { opacity: 0.5; }
//  75% { opacity: 0.75;}
//  100% {opacity:1}
//}
//
//@-moz-keyframes fadeinout {
//  0% { opacity: 0; }
//  25% { opacity: 0.25;}
//  50% { opacity: 0.5; }
//  75% { opacity: 0.75;}
//  100% {opacity:1}
//}
//
//@-webkit-keyframes fadeinout {
//  0% { opacity: 0; }
//  25% { opacity: 0.25;}
//  50% { opacity: 0.5; }
//  75% { opacity: 0.75;}
//  100% {opacity:1}
//}
//
//@-o-keyframes fadeinout {
//  0% { opacity: 0; }
//  25% { opacity: 0.25;}
//  50% { opacity: 0.5; }
//  75% { opacity: 0.75;}
//  100% {opacity:1}
//}
//
//@-ms-keyframes fadeinout {
//  0% { opacity: 0; }
//  25% { opacity: 0.25;}
//  50% { opacity: 0.5; }
//  75% { opacity: 0.75;}
//  100% {opacity:1}
//}



.header.desktop{
  padding: 25px 12%;
  height: 90px;
  .header-menu{
    justify-content: space-between;
    display:flex;
    padding:0;
    margin:auto 0 auto 0;
    height: 40px;

    .corner-logo{
      position: relative;
      right:auto;
    }
    .corner-logo.buffering{
      margin-top:auto;
      height:40px;
      div{
        width:60px;
        height: 60px;
        margin:-10px auto auto 0!important;
      }
    }

    .header-button.settings{
      margin: auto 0 auto auto;
      .img{margin:auto!important;}
    }

    .justify-content{
      justify-content: center;
      height: 100%;
      justify-content: center;
      height: 100%;
      align-items: center;
      vertical-align: middle;
      .header-button-group{
        display:flex;
        padding:0;
        background-color:rgba(51, 75, 109, 1);
        border-radius:100px;
        .header-button.on-demand{
          margin-right:0;
        }
      }
    }
    .desktop-settings{
      display:flex;
      justify-content:flex-end;
      padding-right:0;
      .header-button.settings{
        .header-button-settings-icon{
          width:18.22px;
          height:19.93px;
          //margin-right: auto!important;
          margin:auto!important;
        }
      }
    }
  }

}

.header.mobile{
  .header-menu{
   padding: 0 15px;
  }

}


