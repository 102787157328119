
.wrapper.tablet{
  margin-bottom: 0;
  .progress-real{
    height:5px;
  }
  .progressbar-home{
    height: 5px;
  }
}
.wrapper.mobile{
  margin:7.5px 0 9px 0;

  .indicator{
    margin-top:-48px;
    position: absolute;
    background-color: #FFFFFF;
    color:black;
    opacity: 0;
    width:32px;
    height:19px;
    border-radius: 2.5px;
    padding:2.5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 13.79px;
    text-align: center;
    transition: opacity 150ms ease-in-out;
    -webkit-transition:opacity 150ms ease-in-out;
  }

}
.wrapper{
  width: 100%;
  display: flex;
  height: 4px;
  margin-bottom:16px;
  background: rgba(255, 255, 255, 0.25);
  position: relative;
  border-radius: 100px;

  .indicator{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top:-37px;
    position: absolute;
    background-color: #FFFFFF;
    color:black;
    opacity: 0;
    width:46px;
    height:28px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    transition: opacity 150ms ease-in-out;
    -webkit-transition:opacity 150ms ease-in-out;
  }

  .indicator.active{
    opacity: 1;
    transition: opacity 150ms ease-in-out;
    -webkit-transition:opacity 150ms ease-in-out;
  }

  .progress-real{
    background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)) ;
    height: 4px;
    position: absolute;
    left:auto;
    right: auto;
    max-width: 100%;
    z-index: 1;
    border-radius: 100px;


  }

  input[type="range"] {
    -webkit-appearance: none;
    margin:0;
    width: 100%;
    height: 4px;
    cursor:pointer;
    position: absolute;
    background: transparent;
    z-index: 2;
    border-radius: 100px;
    background-image: linear-gradient(rgba(255,255,255), rgba(255,255,255)) ;
    background-size: 0 100%;
    background-repeat: no-repeat;
  }

  input[type="range"].active::-webkit-slider-thumb{
    background-image:radial-gradient(rgba(255, 255, 255, 1) 5px, rgba(255, 255, 255, 0.3) 6px, transparent)
  }



  /* Input Thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: none;
    cursor: grabbing;
    box-shadow: none;
    background: none; /* necessary for iOS Safari */
    background-image:radial-gradient(#ffff 5px, transparent 6px, transparent);
    //transition: background .3s ease-in-out;
  }

  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height:12px;
    width:12px;
    border-radius: 40px;
    border: none;
    box-shadow: none;
    cursor: grabbing;
    background: none; /* necessary for iOS Safari */
    background-image:radial-gradient(#ffff 5px, transparent 6px, transparent);
    //transition: background .3s ease-in-out;
  }

  input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: none;
    cursor: grabbing;
    box-shadow: none;
    background: none; /* necessary for iOS Safari */
    background-image:radial-gradient(#ffff 5px, transparent 6px, transparent);
    //transition: background .3s ease-in-out;
  }


  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
    box-shadow: none;
    margin-left:-20px;
    margin-right:-20px;
  }

  input[type=range]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    margin-left:-20px;
    box-shadow: none;
    margin-right:-20px;
    background: transparent;
  }

  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    box-shadow:none;
    margin-left:-20px;
    margin-right:-20px;
    background: transparent;
  }




}

.wrapper.inactive{
  /* Input Thumb */
  input[type="range"]::-webkit-slider-thumb {
  display: none;
  }

  input[type="range"]::-moz-range-thumb {
    display: none;
  }

  input[type="range"]::-ms-thumb {
    display: none;
  }
}

