@import '../../assets/styles/globals';


.spinner {
  margin:auto;
  height: rescaleVertical(64px);
  width: rescaleHorizontal(64px);
  border-radius: 50%;
  z-index: 10000000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
