

.on-demand-wrapper{
  margin-top:40px;
  .section-title{
    display: flex;
    margin:0 0 10px 15px;
    p{


      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      display: flex;
      align-items: flex-end;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin:0;
      color: #FFFFFF;

    }
    a{

      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      color: #FB5B77 !important;
      order: 1;
      display: flex;
      height:14px;
      flex-grow: 0;
      margin: auto 15px;
      cursor: pointer;
      //width: 40px;
      justify-content: center;
      align-items: center;
      text-align: center;


    }

  }
  .items {
    position: relative;
    width: 100%;
    height: auto;
    padding-left: 15px;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    will-change: transform;
    user-select: none;
    cursor: pointer;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: 0; /* for Firefox */

  }

  .items::-webkit-scrollbar{
    display: none;
  }


  .swiper-slide{
    padding: 2px;
  }

  .item-wrapper {
    display: inline-block;

    opacity: 1;
    border-radius: 5px;
    width: 165px;
    height: 93px;
    margin-right: 10px;

    .js-tilt-glare{
      border-radius: 5px;
    }
    .item-div{
      background-color:#1E3960;
      width:100%;
      height: 100%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 5px;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.25);
      text-align: end;
      img{margin-right:5px;}

      .item-div-filter {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 5px;
        margin: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.3) 64.96%, rgba(0, 0, 0, 0.3) 100%);

        .item-desc {
          width:100%;
          height:auto;
          position:absolute;
          bottom:2.5px;
          .item-subtitle{
            display: flex;
            padding:0 5px;
            align-items: center;
            img{
              width:13px;
              height: 13px;
            }
            p{
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              margin-bottom: 0;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              display: inline-block;
              text-overflow: ellipsis;
            }
          }
          .item-title {
            width: 100%;
            padding: 0 5px;
            bottom: 3px;
            top: auto;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: left;

            p {
              width: 100%;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              text-align: left;
              margin: 0;
            }
          }
        }
        .item-desc.continue-to-watch{
          bottom:11px;
        }
      }
      .continue-watching-item{
        position:absolute;
        bottom:5px;
        width:100%;
        height:3px;
        padding:0 5px;
        .duration{
          height:100%;
          border-radius:100px;
          background-color:rgba(255,255,255,0.5);
          div{
            height:100%;
            border-radius:100px;
            background-color:white;
          }
        }
      }


      .item-div-filter.faded{
        background: rgba(0, 0, 0, 0.4);
      }
      .item-div-filter.normal{
        background: rgba(0, 0, 0, 0.25);
      }

      .info-icon{
        margin-left:5px;
      }

      .watermark{
        position: absolute;
        top:0;
        right:0;
        margin:5px;
        width:35px;
        height:14px;
        border:none;
      }

    }



    .item-div.collection-item{
      text-align: left;
      display: flex;

      .info-icon{
        position: absolute;
        width:15px;
        height:15px;
        display: flex;
        top:5px;
        left:0;
        img{
          width:15px;
          height:15px;
        }
      }
      .play-icon{
        position: relative;
        width: 2.5rem;
        height:2.5rem;
        margin:auto;
        display: flex;
        padding: 0;
        vertical-align: middle;
        img{
          width:2.5rem;
          height: 2.5rem;
          margin: auto;
        }
      }
    }
    .item-title{
      height: 18px;
      top: 16px;
      text-align: center;
      width:100%;
      p{
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        width:100%;
        align-items: center;
        color: #FFFFFF;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        margin:5px 5px;
        //text-transform: capitalize;
      }
      p.collection{
        opacity: 0.75;


      }

    }


  }
  .item-wrapper.collection-item.active-content{
   .item-div{
     border:1px solid white;
   }
    .p{
      opacity: 1;
      text-align: center;
    }
  }

}



.on-demand-wrapper.desktop {
  margin-top: 50px;

  .section-title {
    display:flex;
    margin:0 0 10px 15px;
    p {
      font-size: 21.33px;
      line-height: 25px;
      letter-spacing: 1px;
    }
    a{
      font-size: 16px;
      letter-spacing: 1px;
      margin: auto 15px;
    }

  }

  .slider-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    //margin-left: -16%;
    display: flex;

    .swiper-button-next::after,  .swiper-button-prev::after  {
      display: none;
      //opacity: 0;
    }

    //.swiper-button-prev.withTitle, .swiper-button-next.withTitle{
    //  margin-top: calc(0px - (var(--swiper-navigation-size) / 2) - 9px);
    //}

    .swiper-button-next{
      color:white;
      opacity: 0;
      width: 30px;
      height: 30px;
      margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
      right:calc( 10.5% + 8.5px);
      transition: opacity 250ms  ease-in-out, right 200ms ease-in-out;
      -webkit-transition:opacity 250ms  ease-in-out,right 200ms ease-in-out;
      img{

        width: 30px;
        height: 30px;
        opacity: 0;
        transition: opacity 250ms  ease-in-out;
      }
    }

    .swiper-button-prev{
      color:white;
      opacity: 0;
      width: 30px;
      height: 30px;
      left:calc( 10.5% + 5px);
      margin-top: calc(0px - (var(--swiper-navigation-size) / 2 ) );
      transition:opacity 250ms  ease-in-out,left 200ms ease-in-out;
      -webkit-transition:opacity 250ms  ease-in-out, left 200ms ease-in-out;
      img{

        width: 30px;
        height: 30px;
        opacity: 0;
        transition: opacity 250ms  ease-in-out;
      }
    }

    .swiper-button-prev.active{
      opacity: 1;
      //animation: prev-slide;
      //animation-duration: .2s;
      left:calc( 10.5% - 10px);
      transition:opacity 250ms  ease-in-out,left 200ms ease-in-out;
      -webkit-transition:opacity 250ms  ease-in-out, left 200ms ease-in-out;
      img{
        opacity: 0.75;
        transition: opacity 250ms  ease-in-out;
      }


    }

    .swiper-button-next.active{
      opacity: 1;
      right:calc( 10.5% - 5px);
      transition:opacity 250ms  ease-in-out,  right 200ms ease-in-out;
      -webkit-transition:opacity 250ms  ease-in-out, right 200ms ease-in-out;
      img{
        opacity: 0.75;
        transition: opacity 250ms  ease-in-out;
      }

    }

    .swiper-button-prev.disabled{
      opacity:0;
    }


  //  .swiper{
  //    width: 100%;
  //    margin:auto;
  //
  //    .swiper-slide{
  //      opacity: 0.5;
  //      transition: opacity 250ms ease-in-out,transform 250ms ease-in-out;
  //      -webkit-transition: opacity 250ms ease-in-out,transform 250ms ease-in-out;
  //      pointer-events: none;
  //      transform: scale(0.95);
  //
  //    }
  //    .swiper-slide-visible{
  //      opacity: 1;
  //      transition: opacity 250ms ease-in-out,transform 250ms ease-in-out;
  //      -webkit-transition: opacity 250ms ease-in-out,transform 250ms ease-in-out;
  //      pointer-events: auto;
  //      transform: scale(1);
  //    }
  //
  //  }
  }
    .swiper-slide{
      padding:0 2px;
    }

    .items{
      height:fit-content;
      padding: 0 12%;
      .item-wrapper {
        margin-right: 25px;

        .item-div {
          background-color: #1E3960;
          width: 100%;
          height: 100%;
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .watermark {
            width: 3.4721vw;
            height: 1.3883vw;
            border: none;
            margin: 5px;
            position: absolute;
            top: 0;
            right: 0;
          }

          .item-desc {
            width: 100%;
            height: auto;
            position: absolute;
            bottom: 5px;

            .item-subtitle {
              display: flex;
              padding: 0 5px;
              align-items: center;

              img {
                width: 13px;
                height: 13px;
              }

              p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 0;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
              }
            }

            .item-title {
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
              text-align: left;
              opacity: 1;
              justify-content: unset;
              bottom: 5px;

              p {
                font-size: 16px;
                line-height: 18px;
                font-weight: 500;
                text-align: left;
                opacity: 1;
              }
            }

          }
          .item-desc.continue-to-watch{
            bottom:15px;
          }
          .continue-watching-item{
            height: 5px;
          }
        }
        .item-div.collection-item{
          text-align: left;
          display: flex;
          .info-icon{
            position: absolute;
            width:20px;
            height: 20px;
            top:5px;
            left:5px;
            display:flex;
            margin:auto;
            img{
              width:20px;
              height: 20px;
              margin:auto;
              padding: 0;
            }
          }
          .play-icon{
            position: relative;
            width: 3.1rem;
            height:3.1rem;
            margin:auto;
            padding: 0;
            display: flex;
            vertical-align: middle;
            img{
              width:3.1rem;
              height: 3.1rem;
              margin:auto;
            }
          }
        }

        .item-title{
          display: flex;
          height: auto;
          justify-content: center;
          width: 100%;
          p {

            font-family: 'Ubuntu', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            color: #FFFFFF;
            mix-blend-mode: normal;
            opacity: 0.75;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;


          }
        }
        .item-title{
          .collection{
            opacity: 0.9;
            text-transform: capitalize;
            margin:5px 5px 0;
          }
        }
      }

      .item-wrapper.desktop{
        width:19.5078vh;
        height:11.5356vh;
        margin-right: 15px;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      .item-wrapper.on-demand.desktop{
        width:14.583vw;
        height:8.33vw;
      }

      .item-wrapper.tablet{
        width:210px;
        height:120px;
        .item-div{
          .item-div-filter{
            img{
              width: 50px;
              height: 50px;
              z-index: 2;
              position: absolute;
              top: 5px;
              //right: 5px;
              margin: auto;
            }
            .watermark{
              width:50px;
              height: 20px;
              right:5px;
            }
            .info-icon{
              img{
                width:20px;
                height: 20px;
                top:auto;
              }
            }
          }
        }
      }

      .item-wrapper:hover{

        .item-div{
          background-color: radial-gradient(48.81% 149.48% at 64.29% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(0, 0, 0, 0.1);
        }
      }

      .item-wrapper.collection-item.active-content{
        transform: none;
        margin-left: 1px;
        p{
          opacity: 1;
        }
        .play-icon{
          img{
            margin-left: 0;
          }
        }
      }
    }

}






