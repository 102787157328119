@import 'measurement';

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-ms-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }

  @include optional-at-root('::placeholder') {
    @content;
  }
}

/**
Apply content to the .focus class for such element:

@include focusable {
  border: 2px solid yellow;
}
 */
@mixin focusable($class: &) {
  @at-root {
    #{selector-append($class, ":hover")} {
      cursor: pointer;

      @content;
    }

    #{selector-append($class, ".focus")} {
      @content;
    }
  }
}

@mixin activable($class: &) {
  @at-root {
    #{selector-append($class, ":hover")} {
      cursor: pointer;

      @content;
    }

    #{selector-append($class, ".active")} {
      @content;
    }
  }
}

@mixin fullscreen($position: relative) {
  position: $position;
  width: $app-width;
  height: $app-height;
  overflow: hidden;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin isAnimated() {
  @if $animated == true {
    @content;
  }
}

@mixin animation($str) {
  @if $animated == true {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
  }
}

/**
Add a transition property if "animations" are enabled during build time

@include transition(all 200ms ease-in-out);
 */
@mixin transition($args...) {
  @if $animated == true {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
  }
}

/**
Place the element on a new spot.

The element should have a position: absolute property.
Make sure that it's correctly positioned at first.

$direction = top, bottom, left, right
$position = new relative position

You can also use $direction = 'both' and use two $position parameters,
the element will be placed relatively from top and left respectively

@include animatedPosition('top', 50px);
@include animatedPosition('both', -10px, -30px)

Note: this mixin does NOT apply rescales itself
Rmmbr: you cannot animate from undef to 200px!
 */
@mixin animatedPosition($direction, $position...) {
  @if $animation-type == 'transform' {
    @if $direction == 'top' {
      transform: translateY($position);
    } @else if $direction == 'bottom' {
      transform: translateY(-$position);
    } @else if $direction == 'left' {
      transform: translateX($position);
    } @else if $direction == 'right' {
      transform: translateX(-$position);
    } @else if $direction == 'both' and length($position) == 2 {
      transform: translate(nth($position, 2), nth($position, 1));
    }
  } @else {
    @if $direction != 'both' {
      #{$direction}: $position;
    } @else {
      top: nth($position, 1);
      left: nth($position, 2);
    }
  }
}
