

.on-demand-channels{

  position: absolute;
  z-index:1500;
  width:100%;
  background: #0a2244;
  height:100%;
  overflow-y: scroll;
  padding:10px 0 90px 0;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  .detailed-item-wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    z-index: 3500;
    backdrop-filter: blur(10px);
    background: rgba(3, 12, 26, 0.85);
    .selected-item-detail-page{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 4500;
      overflow-y: scroll;
      background: #0a2244;

    }
    .selected-item-detail-page.desktop{
      border-radius: 15px;
      box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.5);
      width:73.15vh;
      height: 73.15vh;
      margin:11.425vh calc((100vw - 73.5vh)/2);
      scrollbar-width: none;
      .content-detail-wrapper.desktop{
        height: 100%;
      }
    }
    .selected-item-detail-page.tablet{
      bottom:60px;
    }

    .selected-item-detail-page::-webkit-scrollbar{
      display: none;
    }
  }

  .on-demand-mobile-header{
    display: flex;
    position:fixed;
    width: 100%;
    z-index: 3500;
    top:0;
    justify-content: space-between;
    padding:10px 15px 7.5px 15px;
    background-color: #0a2244;
    img{
      width:38.77px;
      height: 15px;

    }

    .target-wrapper{
      width:fit-content;
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin:-7.5px 0;
    }
    button{
      flex-direction: row;
      padding: 4.5px 10px;
      height: 25px;
      background: #334b6d;
      transition: background-color 0.15s ease-in-out;
      box-shadow: 0 5px 15px -5px rgb(0 0 0 / 15%);
      border-radius: 100px;
      border: none;
      flex: none;
      order: 0;
      flex-grow: 0;
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.5px;
      color: #fff;
      display: flex;
      img{
        margin-right: 5px;
        width:16px;
        height: 16px;
      }

    }
  }

  .on-demand-channels-all{
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: center;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    scroll-behavior: smooth;
    /* display: block; */
    padding-bottom: 90px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    .channel-group{
      margin-bottom:30px;
      overflow-y: scroll;
      text-align: center;
      .content-title{
        text-align: left;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 60px 15px 0 15px;
        color: #FFFFFF;
        p{
          margin:0;
        }
      }

      .content-title.tablet{
        padding:95px 15px 0 25px;
        font-size: 21.33px;
        line-height: 24.51px;
      }

      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
    }

    .content-wrapper.withTitle{
      .channel-wrapper{
        .js-tilt-glare{
          background:  linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.3) 64.96%, rgba(0, 0, 0, 0.3) 100%);;
        }
      }
    }

    .content-wrapper{
      display: grid;
      grid-template-columns: 165px 165px;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      padding:15px;
      justify-content: center;
    }
    .content-wrapper.tablet{
      display: grid;
      grid-template-columns: auto auto auto;
      width: 100%;
      padding: 25px 30px;
      grid-column-gap: 72px;
      grid-row-gap: 25px;
      justify-content: center;


      .channel-wrapper.tablet{
        width:210px;
        height: 120px;
        .channel-div{
          .item-title.tablet{
            width:210px;
            position: absolute;
            bottom:5px;
            padding:0 5px;
            top:auto;
            height: 18px;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            text-align: left;
            z-index: 2;
            p{
              width:100%;
              text-align: left;
              margin-bottom: 0;
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
            }
          }
          .asset-watermark{
            width: 50px;
            height: 20px;
            z-index: 2;
            position: absolute;
            top: 5px;
            right: 5px;
            margin: 0;
          }
        }

        //.js-tilt-glare{
        //  background:  linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.3) 64.96%, rgba(0, 0, 0, 0.3) 100%);
        //}
      }
    }

    .channel-group::-webkit-scrollbar{
      display: none;
    }

    .channel-wrapper {
      opacity: 1;
      border-radius: 5px;
      width: 165px;
      height: 93px;
      margin:0;
      .js-tilt-glare{
        //background:linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.3) 64.96%, rgba(0, 0, 0, 0.3) 100%);
      }
      .channel-div {
        background: radial-gradient(48.81% 149.48% at 64.29% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 5px;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.25);
        text-align: end;


        .asset-watermark{
          width:35px;
          height:14px;
          border: none;
          margin:5px;
          z-index: 2;
        }
      }

      .item-desc {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 2.5px;
        z-index:3;

        .item-subtitle {
          display: flex;
          padding: 0 5px;
          align-items: center;

          img {
            margin-right: 5px;
            width: 13px;
            height: 13px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 0;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
          }
        }

        .item-title {
          width: 100%;
          //position: absolute;
          padding: 0 5px;
          bottom: 3px;
          z-index: 2;
          justify-content: left;
          opacity: 1;
          margin: 0;
          height: 14px;


          p {
            font-family: 'Ubuntu', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            /* identical to box height */
            width: 100%;
            opacity: 1;
            text-align: left;
            //text-transform: capitalize;
            color: #FFFFFF;
            margin: 0;
            mix-blend-mode: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0;
          }
        }
      }
      .item-desc.continue-to-watch{
        bottom:11px;
      }
      .continue-watching-item{
        position:absolute;
        bottom:5px;
        width:100%;
        height:3px;
        padding:0 5px;
        .duration{
          height:100%;
          border-radius:100px;
          background-color:rgba(255,255,255,0.5);
          div{
            height:100%;
            border-radius:100px;
            background-color:white;
            position: absolute;
            z-index: 2;
          }
        }
      }
    }
    //.channel-wrapper{
    //  width:210px;
    //  height: 120px;
    //  .item-title{
    //    width:210px;
    //  }
    //}


  }
  .back-to-top{
    padding: 0 15px;
    margin-bottom: 30px;
    display: flex;
    width: fit-content;
    min-width: 40px;
    align-items: center;
    button{
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 6px 15px;
      color: white;
      position: static;
      height: 30px;
      background: #334B6D;
      box-shadow: 0px 5px 15px -5px rgb(0 0 0 / 15%);
      border: none;
      border-radius: 100px;
      flex: none;
      order: 1;
      flex-grow: 0;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.025em;
      display: flex;
      img {
        width: 18px;
        height: 18px;
        margin-right:10px;

      }

    }
  }
  .on-demand-channels-all::-webkit-scrollbar{
    display: none;
  }

}




.on-demand-channels.desktop{
  padding: 0;
  .on-demand-channels-header{
    position: fixed;
    width: 100%;
    z-index:1600;
    background-color: transparent;
  }
  .on-demand-channels-all{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index:1598;
    justify-content: center;
    padding-bottom:0;

    .content-title{
      padding: 0;
      margin-bottom:40px;
      button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        width: auto;
        height:40px;
        background: #334B6D;
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        border:none;
        flex: none;
        order: 0;
        flex-grow: 0;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18.38px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        img{
          width:20px;
          height: 20px;
          margin-right:10px;
        }

      }
      button:hover{
        background-color: rgb(101, 123, 164)
      }
      p{
        margin:auto 0 auto 15px;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21.33px;
        line-height: 25px;
        letter-spacing: 1px;
      }
    }
    .channel-group{
      .content-wrapper{
        text-align: center;
        display: grid;
        grid-template-columns: 19% 19% 19% 19% 19%;
        width: 100%;
        grid-column-gap: 1.04vw;
        grid-row-gap: 1.04vw;
        padding: 0;
      }



      .content-wrapper.withTitle{
        .channel-wrapper{
          .js-tilt-glare{
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.3) 64.96%, rgba(0, 0, 0, 0.3) 100%);
          }
        }
      }

      padding: 130px 12% 0 12%;
      text-align: center;
      overflow-y: scroll;
      .content-title{
        text-align: left;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.5vw;
        line-height: 1.8vw;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.2vw;
        text-transform: uppercase;

      }
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
    }
    .channel-group::-webkit-scrollbar{
      display: none;
    }

    .channel-wrapper {
      display: inline-block;
      opacity: 1;
      border-radius: 5px;
      width:14.583vw;
      height:8.33vw;
      .js-tilt-glare{
      //background:linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.3) 64.96%, rgba(0, 0, 0, 0.3) 100%);
      }
      .channel-div{
        background: radial-gradient(48.81% 149.48% at 64.29% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;
        box-shadow: 0px 5px 15px -5px rgb(0 0 0 / 25%);
        text-align: end;
        .asset-watermark{
          width:3.4721vw;
          height:1.3883vw;
          margin:5px;
        }
      }

      .item-desc {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 5px;

        .item-subtitle {
          display: flex;
          padding: 0 5px;
          align-items: center;

          img {
            margin-right: 5px;
            width: 13px;
            height: 13px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 0;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
          }
        }

        .item-title {
          display: flex;
          height: auto;
          justify-content: left;
          width: 100%;
          opacity: 1;
          z-index: 2;


          p {

            font-family: 'Ubuntu', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            text-align: left;
            color: #FFFFFF;
            mix-blend-mode: normal;
            opacity: 1;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            margin: 0;


          }
        }
      }
      .item-desc.continue-to-watch{
        bottom:15px;
      }
      .continue-watching-item{
        height: 5px;
      }
    }

    .channel-wrapper:hover{
      transform: scale(1.05);
      transition: all ease-in-out 0.2s;
    }


    .back-to-top{
      padding:0 12% 0 12%;
      margin:60px 0 45px -10px;
      button{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;
        padding: 10px 20px;
        color: #fff;
        position: static;
        height: 40px;
        background: #334b6d;
        box-shadow: 0 5px 15px -5px rgb(0 0 0 / 15%);
        border: none;
        border-radius: 100px;
        -webkit-flex: none;
        flex: none;
        -webkit-order: 1;
        order: 1;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        font-family: "Ubuntu",sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: .025em;
        display: flex;
        img{
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }
    }
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

  }
  .on-demand-channels-all::-webkit-scrollbar{
    display: none;
  }

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

}
.on-demand-channels::-webkit-scrollbar{
  display: none;
}
