

.wrapper-player {
  .info {
    position: absolute;
    left: 0;
    top: 0;
    font-size: toRem(24);
  }
  .player {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;


    background-color: #000000;

    &--hidden {
      background-color: transparent;
    }

    .spinner {
      margin: rescaleVertical(150px) auto 0;
    }

    .error-msg {
      position: absolute;
      display: flex;
      align-items: center;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      padding: 0 20%;

      font-size: toRem(28);
      font-weight: 300;
      color: #ffffff;

      text-align: center;

      opacity: 0.8;
      z-index: 999;
    }

    .player-title {
      position: absolute;
      left: rescaleHorizontal(30px);
      top: rescaleVertical(20px);

      font-size: toRem(30);
      z-index: 99;
    }

    .player-video {
      margin:auto;

    }
  }

  :export {
    horizontalScale: rescaleHorizontal(1px) / 1px;
    verticalScale: rescaleVertical(1px) / 1px;

  }
}
