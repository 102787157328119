

.ad-label{
  display: flex;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 21.33px;
  line-height: 25px;
  /* identical to box height */
padding-bottom: 1rem;

  color: #FFFFFF;



}

.ad-label.inActive{

  position: absolute;
  bottom:4.88vh;
  left:12%;
  z-index: 15000;
  justify-content: left;


}

.ad-label.mobile{
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
height: 14px;

}
