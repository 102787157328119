

.on-demand-page{
  width:100%;
  height:100%;
  padding-bottom: 90px;
  z-index:900;
  background-color: #0a2244;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  .back-to-top{
    padding: 0 15px;
    //margin-bottom: 30px;
    height: 40px;
    display: flex;
    width: fit-content;
    min-width: 40px;
    align-items: center;
    button{
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 6px 15px;
      color: white;
      position: static;
      height: 30px;
      background: #535668;
      box-shadow: 0px 5px 15px -5px rgb(0 0 0 / 15%);
      border: none;
      border-radius: 100px;
      flex: none;
      order: 1;
      flex-grow: 0;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.025em;
      display: flex;
      img {
        width: 18px;
        height: 18px;
        margin-right:10px;

      }

    }
  }


  .on-demand-header{
    position: fixed;
    background-color: transparent;
    width:100%;
    padding-bottom:10px;
    margin:0;
    height: 42px;
    z-index: 1500;
  }
  .on-demand-sections{
    padding: 20px 0 35px 0;
    z-index: 1;
    width: 100%;
    //overflow-y: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    .on-demand-wrapper{
      .section-title{
        margin-left:15px;
      }
      .items{
        height: 96px;
      }
    }
  }

  .on-demand-sections.tablet{
    padding: 35px 0 35px 0;
    .on-demand-wrapper.desktop{
      margin-top:40px;
      items{
        height: 120px;
      }
      .section-title{
        margin-left:45px;
      }
      .slider-wrapper{
        //margin-left: 45px;
        .swiper{
          padding: 0 0 0 45px;
          height: auto;
        }
      }
    }
  }
  //.on-demand-sections.tablet.portrait{
  //  padding: 35px 0 35px 25px;
  //}
  //.on-demand-sections.tablet.landscape{
  //  padding: 35px 0 35px 45px;
  //}

  .on-demand-sections::-webkit-scrollbar{
    display: none;
  }
}
.detailed-item-wrapper{
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  z-index: 1501;
  backdrop-filter: blur(10px);
  background: rgba(3, 12, 26, 0.85);
  .selected-item-detail-page{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    overflow-y: hidden;
    overflow-x:hidden;
    scrollbar-width: none;
  }
  .selected-item-detail-page.desktop{
    border-radius: 15px;
    box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.5);
    width:73.15vh;
    height: 73.15vh;
    margin:11.425vh calc((100vw - 73.5vh)/2);

    .content-detail-wrapper.desktop{
      height: 100%;
    }
  }
  .selected-item-detail-page.tablet{
    bottom:60px;
  }


  .selected-item-detail-page::-webkit-scrollbar{
    display: none;
  }
}


.on-demand-page::-webkit-scrollbar{
  display: none;
}


.on-demand-page.desktop{
  overflow-y: scroll;
  margin:0;
  padding: 0;
  position: absolute;
  .back-to-top.desktop{
    padding: 0 12%;
    margin: 60px 0 45px -10px;
    button{
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      color: white;
      position: static;
      height: 40px;
      background: #334B6D;
      box-shadow: 0px 5px 15px -5px rgb(0 0 0 / 15%);
      border: none;
      border-radius: 100px;
      flex: none;
      order: 1;
      flex-grow: 0;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      display: flex;
      letter-spacing: 0.025em;
      img {
        width: 20px;
        height: 20px;
        margin-right:10px;

      }

    }
    button:hover{
      background-color: rgb(101, 123, 164)
    }
  }

  .on-demand-header{
      height: 90px;
    //box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.5);
    padding-bottom:0;
  }

  .on-demand-sections{
    width: 100%;
    margin:0;
    padding: 80px 12% 0 12%;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    .loading{
      height:100vh;
      display:flex;
      justify-content: center;
      align-items: center;
    }
    .on-demand-wrapper.desktop{
      .items{
        height: auto;
      }
      .section-title{
        margin-left: 0;
      }

      .slider-wrapper{
        width: 100vw;
        margin-left: -16%;
        margin-right: -16%;

        .swiper{
          width: 100%;
          height: auto;
          margin:auto;
          padding:5px 12%;
          //.swiper-wrapper{
          // padding:3px;
          //}
          .swiper-slide{
            opacity: 0;
            transition: opacity 300ms ease-in-out,transform 150ms ease-in-out;
            -webkit-transition: opacity 300ms ease-in-out,transform 150ms ease-in-out;
            pointer-events: none;
            transform: scale(0.95);

          }
          .swiper-slide-visible{
            opacity: 1;
            transition: opacity 300ms ease-in-out,transform 150ms ease-in-out;
            -webkit-transition: opacity 300ms ease-in-out,transform 150ms ease-in-out;
            pointer-events: auto;
            transform: scale(1);
          }

        }

      }
    }

    .swiper-container{
      padding: 0 10%;
    }
  }
  .on-demand-sections::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

}
.on-demand-page.desktop::-webkit-scrollbar{
  display: none;
}
